import * as R from "ramda";
import React, { Component } from "react";
import PropTypes from "prop-types";

const withErrorPage = ErrorComponent => BaseComponent => {
  class WithErrorPage extends Component {
    constructor(props) {
      super(props);

      this.state = {
        error: props.error
      };
    }

    componentDidCatch(error) {
      this.setState({
        error
      });
    }

    componentWillReceiveProps(nextProps) {
      if (this.state.error !== nextProps.error) {
        this.setState({
          error: nextProps.error
        });
      }
    }

    render() {
      return R.isEmpty(this.state.error) ? (
        <BaseComponent />
      ) : (
        <ErrorComponent error={this.state.error} />
      );
    }
  }

  WithErrorPage.propTypes = {
    error: PropTypes.instanceOf(Error)
  };

  return WithErrorPage;
};

export default withErrorPage;
