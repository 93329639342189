import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'
import roundCorners from '../rounding'

const Page = ({
  className,
  __x,
  __y,
  __width,
  __height,
  withLines,
  filled,
  id
}) => {
  const subBase = BASE * Page.WIDTH_RATIO
  const quarterSubBase = subBase * 0.25
  const viewBox = `0 0 ${subBase} ${BASE}`
  const thickness = BASE * 0.08
  const rx = thickness / 2
  const outerPath = roundCorners(
    `M0 0 L${subBase -
      quarterSubBase} 0 L${subBase} ${quarterSubBase} L${subBase} ${BASE} L0 ${BASE} Z`,
    rx
  )
  const innerPath = `M${thickness} ${thickness} L${subBase -
    quarterSubBase -
    rx} ${thickness} L${subBase - thickness} ${quarterSubBase + rx} L${subBase -
    thickness} ${BASE - thickness} L${thickness} ${BASE - thickness} Z`

  return e(
    SVG,
    { className, viewBox, width: __width, height: __height, x: __x, y: __y },
    [
      e(
        'defs',
        { key: 'defs' },
        e('mask', { id: `PageMask${id}` }, [
          e('path', { key: 'outer path', d: outerPath, fill: 'white' }),
          !filled &&
            e('path', { key: 'inner path', d: innerPath, fill: 'black' }),
          e('rect', {
            key: 'curl left',
            x: subBase - quarterSubBase - thickness - rx,
            y: filled ? 100 * -1 : 0,
            width: thickness,
            height: quarterSubBase + thickness + rx + (filled ? 100 : 0),
            fill: filled ? 'black' : 'white',
            rx
          }),
          e('rect', {
            key: 'curl right',
            x: subBase - quarterSubBase - thickness - rx,
            y: quarterSubBase + rx,
            width: quarterSubBase + thickness + (filled ? 100 : 0),
            height: thickness,
            fill: filled ? 'black' : 'white',
            rx
          }),
          withLines &&
            !filled &&
            e('rect', {
              key: 'line #1',
              x: quarterSubBase,
              y: 225,
              width: subBase / 2,
              height: thickness,
              rx,
              fill: 'white'
            }),
          withLines &&
            !filled &&
            e('rect', {
              key: 'line #2',
              x: quarterSubBase,
              y: 300,
              width: subBase / 2,
              height: thickness,
              rx,
              fill: 'white'
            }),
          withLines &&
            !filled &&
            e('rect', {
              key: 'line #3',
              x: quarterSubBase,
              y: 375,
              width: subBase / 2,
              height: thickness,
              rx,
              fill: 'white'
            })
        ])
      ),
      e('rect', {
        key: 'fill',
        x: 0,
        y: 0,
        width: BASE,
        height: BASE,
        mask: `url(#PageMask${id})`
      })
    ]
  )
}

Page.propTypes = {
  className: PropTypes.string,
  __x: PropTypes.number,
  __y: PropTypes.number,
  __width: PropTypes.number,
  __height: PropTypes.number,
  withLines: PropTypes.bool.isRequired,
  filled: PropTypes.bool.isRequired
}

Page.defaultProps = {
  __x: 0,
  __y: 0,
  __width: null,
  __height: null,
  withLines: true,
  filled: false
}

Page.WIDTH_RATIO = 0.84

export default Page
