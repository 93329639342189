import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const MenuOptions = props => (
  <ul className="menu-options" {...props.containerProps}>
    {props.options.map((opt, i) => {
      const onClick = () => {
        opt.onClick();
        props.close();
      };

      return (
        <li
          key={i}
          className={classnames("menu-option", {
            "menu-option--active": props.selectedIndex === i
          })}
          {...props.itemProps}
          data-testid="selectionDropdownItem"
        >
          {props.optionRenderer({ ...opt, onClick })}
        </li>
      );
    })}
  </ul>
);

MenuOptions.displayName = "MenuOptions";

MenuOptions.propTypes = {
  selectedIndex: PropTypes.number.isRequired,
  optionRenderer: PropTypes.any,
  itemProps: PropTypes.any,
  close: PropTypes.any,
  containerProps: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      label: PropTypes.string
    })
  )
};

export default MenuOptions;
