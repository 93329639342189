import React from "react";
import PropTypes from "prop-types";
import styled from "react-emotion";
import FakeCheckbox from "./FakeCheckbox";

const Input = styled("input")`
  display: none;
`;
export default class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked
    };

    this.inputRef = node => (this.input = node);

    this.handleClick = event => {
      event.preventDefault();

      this.input.click();
    };

    this.onChange = event => {
      this.setState({ checked: !this.state.checked });
      this.props.onChange(event);
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ checked: nextProps.checked });
  }

  render() {
    const { handleClick, inputRef, onChange } = this;
    const { id, name, value, disabled } = this.props;
    const { checked } = this.state;

    return (
      <React.Fragment>
        <Input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          name={name}
          value={value}
          innerRef={inputRef}
          onChange={onChange}
          tabIndex={-1}
        />
        <FakeCheckbox
          onClick={handleClick}
          checked={checked}
          disabled={disabled}
          id={id}
        />
      </React.Fragment>
    );
  }
}

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  value: "",
  name: "",
  onChange: () => {}
};
