import React from "react";
import { Spinner } from "../../../../components";

export default Object.assign(
  ({ timeout }) => (
    <div className="loading">
      <div className="loading__spinner">
        <Spinner />
      </div>

      {timeout === "SHORT" && <p className="loading__message">Hang tight...</p>}

      {timeout === "LONG" && (
        <p className="loading__message">
          Hmm, this request seems to be taking longer than usual...
        </p>
      )}
    </div>
  ),
  { displayName: "Refunds.Loading" }
);
