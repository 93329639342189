import React from "react";
import * as R from "ramda";
import PropTypes from "prop-types";

const Input = props => {
  const inputProps = R.pick(["onChange", "value", "placeholder", "id"]);

  return (
    <div className="input">
      <input
        type={props.type}
        className="input--field"
        {...inputProps(props)}
      />
    </div>
  );
};

Input.displayName = "Input";
Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string
};

Input.defaultProps = {
  type: "text"
};

export default Input;
