import PropTypes from 'prop-types'
import { e, SVG } from '../utils'
import Rotate from './Rotate'

const RotateReverse = ({ className }) =>
  e(SVG, { className, transform: `scale(-1 1)` }, e(Rotate))

RotateReverse.propTypes = {
  className: PropTypes.string
}

export default RotateReverse
