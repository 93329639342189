import { actions } from "../../actions/errors";

export class ReducerError extends Error {}

export const getErrorActionCreator = err => {
  if (err instanceof ReducerError) {
    return actions.reducer;
  }

  return actions.global;
};

export const errorsMiddleware = global => {
  return store => {
    const { dispatch } = store;

    global.addEventListener("error", err => {
      const action = getErrorActionCreator(err);
      dispatch(
        action({
          error: err.message,
          stack: err.stack,
          previousState: store.getState()
        })
      );

      err.preventDefault();
      err.stopPropagation();
    });

    return next => action => next(action);
  };
};
