import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'
import round from '../rounding'

// http://jxnblk.com/react-icons/
const drawTeeth = (n, opts) => {
  const rad = a => Math.PI * a / 180
  const rx = (r, a) => opts.c + r * Math.cos(rad(a))
  const ry = (r, a) => opts.c + r * Math.sin(rad(a))
  const num = n => (n < 0.0000001 ? 0 : n)
  const tx = (a, w) => Math.sin(rad(a)) * w
  const ty = (a, w) => Math.cos(rad(a)) * w
  const ta = opts.angle / 4
  const splay = opts.splay * ta
  const tw = Math.tan(rad(ta)) * opts.r1

  var d = []
  for (var i = 0; i < n; i++) {
    var a = opts.angle * i - opts.offset
    var a1 = a + ta + splay
    var a2 = a + opts.angle - ta - splay
    var line = [
      i === 0 ? 'M' : 'L',
      num(rx(opts.r1, a) + tx(a, tw)),
      num(ry(opts.r1, a) - ty(a, tw)),
      'L',
      num(rx(opts.r1, a) - tx(a, tw)),
      num(ry(opts.r1, a) + ty(a, tw)),
      'L',
      num(rx(opts.r2, a1)),
      num(ry(opts.r2, a1)),
      'A',
      opts.r2,
      opts.r2,
      '0 0 1',
      num(rx(opts.r2, a2)),
      num(ry(opts.r2, a2))
    ].join(' ')

    d.push(line)
  }

  return d.join(' ')
}

const Gear = ({ className, id = '' }) => {
  const halfBase = BASE / 2
  const innerR = BASE * 0.4 / 2
  const teethOutline = drawTeeth(8, {
    c: halfBase,
    r1: halfBase,
    r2: 0.75 * halfBase,
    r3: 0.35 * halfBase,
    angle: 45, // 360 / 8
    offset: 90,
    splay: 0.6
  })

  return e(SVG, { className }, [
    e(
      'defs',
      { key: 'defs' },
      e(
        'mask',
        { id: `quarter${id}` },
        e('path', {
          d: `M${halfBase} 0H${BASE}V${halfBase}H${halfBase +
            innerR}A${innerR} ${innerR} 0 0 0 ${halfBase} ${halfBase -
            innerR}Z`,
          fill: 'white'
        })
      )
    ),
    e('path', {
      key: 'quarter #1',
      d: round(teethOutline, 10),
      mask: `url(#quarter${id})`
    }),
    e('path', {
      key: 'quarter #2',
      d: round(teethOutline, 10),
      mask: `url(#quarter${id})`,
      transform: `rotate(90 ${halfBase} ${halfBase})`
    }),
    e('path', {
      key: 'quarter #3',
      d: round(teethOutline, 10),
      mask: `url(#quarter${id})`,
      transform: `rotate(180 ${halfBase} ${halfBase})`
    }),
    e('path', {
      key: 'quarter #4',
      d: round(teethOutline, 10),
      mask: `url(#quarter${id})`,
      transform: `rotate(270 ${halfBase} ${halfBase})`
    })
  ])
}

Gear.propTypes = {
  className: PropTypes.string
}

export default Gear
