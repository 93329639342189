import { createAction } from "redux-actions";
import { makeActionTypes } from "../../utils";
/**
 * TYPES
 */
export const types = makeActionTypes("SOCKET", [
  "ERROR",
  "CLOSED",
  "OPENED",
  "RETRYING"
]);

/**
 * ACTIONS
 */
export const actions = {
  error: createAction(types.ERROR),
  closed: createAction(types.CLOSED),
  opened: createAction(types.OPENED),
  retrying: createAction(types.RETRYING)
};

export default actions;
