import * as R from "ramda";
import lens from "../lenses/socket";

export default {
  root: R.view(lens),
  isOpened: R.view(lens.isOpened),
  isClosed: R.view(lens.isClosed),
  isRetrying: R.view(lens.isRetrying),
  error: R.view(lens.error)
};
