import React from "react";

const NoResults = () => (
  <div className="no-results">
    <h2 className="no-results--header">No Results Found</h2>
    <div className="no-results--text">
      <p>Your search returned no results</p>
      <p>Try broadening your search with fewer character</p>
    </div>
  </div>
);

NoResults.displayName = "NoResults";

export default NoResults;
