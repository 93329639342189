import React from "react";
import Form from "./Form";
import Search from "./Search";
import styled from "react-emotion";
import PropTypes from "prop-types";
import * as Components from "../../../../components";

const Action = props => {
  if (!props.isSearching) {
    return null;
  }

  return <Components.Button onClick={props.new}>Add User</Components.Button>;
};

Action.propTypes = {
  isSearching: PropTypes.bool,
  new: PropTypes.any
};

const Success = styled("div")`
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  justify-content: space-between;
  padding: 20px;
`;

const Button = styled("button")`
  color: #006dd5;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid rgba(4, 29, 68, 0.15);
  border-radius: 4px;
  padding: 12px;
`;

const SuccessView = ({ search, isSuccessfulUpdate }) => {
  const actionLabel = isSuccessfulUpdate ? "Updated" : "Created";

  return (
    <Success>
      <div>Succesfully {actionLabel} User!</div>
      <Button onClick={search}>Back To Search</Button>
    </Success>
  );
};

SuccessView.propTypes = {
  search: PropTypes.any,
  isSuccessfulUpdate: PropTypes.any
};

const FormView = props =>
  (props.isEditing || props.isCreating) && (
    <Form
      userId={props.userId}
      onSubmit={props.onSubmit}
      onCancel={props.search}
      isEditing={props.isEditing}
    />
  );

const WidgetContent = props =>
  props.isSuccessfulUpdate || props.isSuccessfulCreate ? (
    <SuccessView {...props} />
  ) : (
    <FormView {...props} />
  );

WidgetContent.propTypes = {
  isSuccessfulUpdate: PropTypes.any,
  isSuccessfulCreate: PropTypes.any
};

export default Object.assign(
  props => (
    <Components.Widget
      className={props.className}
      title={props.title}
      action={<Action {...props} />}
    >
      {props.isSearching && <Search edit={props.edit} />}

      <WidgetContent {...props} />
    </Components.Widget>
  ),
  {
    displayName: "WidgetWrapper"
  }
);
