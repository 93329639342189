import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const County = ({ id, name, sealUrl }) => (
  <div className="county">
    <img src={sealUrl} alt={`${name} icon`} className="county--seal" />
    <Link to={`/${id}/dashboard`} className="county--name" data-testid="county">
      {name} County
    </Link>
  </div>
);

County.displayName = "County";

County.propTypes = {
  sealUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default County;
