import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'

const Search = ({ className, id = '' }) => {
  const d = BASE * (3 / 4)
  const r = d / 2
  const thickness = BASE * 0.1
  const innerR = r - thickness
  const handleThickness = thickness * 1.25
  const handleWidth = Math.sqrt(2) * (BASE - d) + handleThickness

  return e(SVG, { className }, [
    e(
      'defs',
      { key: 'defs' },
      e('mask', { id: `SearchMask${id}` }, [
        e('circle', { key: 'outer circle', fill: 'white', cx: r, cy: r, r }),
        e('circle', {
          key: 'inner circle',
          fill: 'black',
          cx: r,
          cy: r,
          r: innerR
        }),
        e('g', { key: 'handle', transform: `rotate(45 ${r} ${r})` }, [
          e('rect', {
            key: 'rod',
            fill: 'white',
            x: innerR * 2 + thickness,
            y: r - thickness / 2,
            width: handleWidth,
            height: handleThickness
          }),
          e('circle', {
            key: 'handle cap',
            fill: 'white',
            cx: innerR * 2 + thickness + handleWidth,
            cy: r - thickness / 2 + handleThickness / 2,
            r: handleThickness / 2
          })
        ])
      ])
    ),
    e('rect', {
      x: 0,
      y: 0,
      width: BASE,
      height: BASE,
      mask: `url(#SearchMask${id})`,
      key: 'fill'
    })
  ])
}

Search.displayName = 'Search'

Search.propTypes = {
  className: PropTypes.string
}

export default Search
