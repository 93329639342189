import React from "react";
import PropTypes from "prop-types";
import qs from "qs";
import * as R from "ramda";
import * as rc from "recompose";
import { connect } from "react-redux";
import { actions, getters } from "../store";
import { Redirect } from "react-router-dom";
import validate from "./validate";
import View from "./View";

const lens = {
  form: {
    isValid: R.lensPath(["form", "isValid"]),
    isLoading: R.lensPath(["form", "isLoading"])
  },
  email: R.lensPath(["form", "values", "email"]),
  password: R.lensPath(["form", "values", "password"]),
  rememberMe: R.lensPath(["form", "values", "rememberMe"])
};

const update = {
  email: { to: R.set(lens.email) },
  password: { to: R.set(lens.password) },
  rememberMe: { to: R.set(lens.rememberMe) },
  form: {
    isValid: { to: R.set(lens.form.isValid) },
    isLoading: { to: R.set(lens.form.isLoading) }
  }
};

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        isLoading: props.isLoading,
        values: {
          email: "",
          password: "",
          rememberMe: props.remembered
          // showPassword: false // <-- TODO
        }
      }
    };

    this.form = {
      validate: validateForm.bind(this),
      submit: handleSubmit.bind(this),
      update: {
        email: ({ target: { value } }) => this.setState(update.email.to(value)),
        password: ({ target: { value } }) =>
          this.setState(update.password.to(value)),
        rememberMe: ({ target: { checked } }) =>
          this.setState(update.rememberMe.to(checked))
      }
    };
  }

  componentWillUnmount() {
    this.props.clearError();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.props.isLoading) {
      this.setState(update.form.isLoading.to(nextProps.isLoading));
    }
  }

  render() {
    const returnPath = getReturnPath(this.props.history);

    if (this.props.isLoggedIn) {
      return <Redirect to={returnPath || "/"} />;
    }

    const form = {
      values: this.state.form.values,
      update: {
        email: this.form.update.email,
        password: this.form.update.password,
        rememberMe: this.form.update.rememberMe
      },
      submit: this.form.submit,
      isLoading: this.state.form.isLoading
    };

    const errorBanner = {
      error: this.props.error
    };

    return <View form={form} errorBanner={errorBanner} />;
  }
}

SignIn.propTypes = {
  login: PropTypes.func.isRequired,
  remembered: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  error: PropTypes.string,
  clearError: PropTypes.func.isRequired,
  history: PropTypes.object
};

SignIn.defaultProps = {
  remembered: false,
  isLoading: false,
  isLoggedIn: false
};

const mapStateToProps = state => ({
  isLoading: getters.user.loading(state),
  isLoggedIn: getters.user.isLoggedIn(state),
  error: getters.user.error(state)
  // remembered: // <-- TODO
});

const mapDispatchToProps = dispatch => ({
  login: credentials => dispatch(actions.user.login(credentials)),
  clearError: () => dispatch(actions.user.clearError())
});

export default rc.compose(
  rc.onlyUpdateForKeys(["isLoading"]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SignIn);

function handleSubmit(event) {
  event.preventDefault();

  this.form.validate(() => {
    this.setState(update.form.isLoading.to(true));
    this.props.login(this.state.form.values);
  });
}

function validateForm(callback) {
  const { values: formValues } = this.state.form;

  this.setState(update.form.isValid.to(validate(formValues)), callback);
}

function getReturnPath(history) {
  const { search } = history.location;

  if (typeof search === "string" && search.length > 1) {
    return qs.parse(search.slice(1)).returnPath;
  }

  return null;
}
