import PropTypes from 'prop-types'
import { e, SVG } from '../utils'

const Trash = ({ className, id = '' }) => {
  return e(SVG, { className, viewBox: '0 0 18 20' }, [
    e('path', {
      key: '1',
      d:
        'M7.39261745,13.7331007 C7.39261745,12.7828993 7.39557047,11.832698 7.39114094,10.8824966 C7.38939597,10.5234362 7.26738255,10.4035705 6.90724832,10.3913557 C6.68389262,10.3837047 6.46,10.3845101 6.2366443,10.3908188 C5.87785235,10.4008859 5.72899329,10.5446443 5.72832215,10.9073289 C5.72456376,12.7853154 5.72818792,14.6634362 5.7252349,16.541557 C5.72483221,16.8438389 5.87959732,17.0045101 6.16255034,17.0314899 C6.40630872,17.0548456 6.65422819,17.0553826 6.89919463,17.0424966 C7.27114094,17.0230336 7.39060403,16.8908188 7.39167785,16.5167248 C7.39463087,15.5888054 7.39261745,14.6610201 7.39261745,13.7331007 M11.5449664,13.7210201 L11.5449664,11.3424966 C11.5449664,10.4024966 11.5300671,10.3875973 10.5902013,10.3888054 C9.93315436,10.389745 9.83838926,10.4831678 9.83785235,11.1439732 C9.83691275,12.7520268 9.84496644,14.3600805 9.83489933,15.968 C9.82832215,17.0453154 9.86711409,17.0823624 11.0159732,17.0407517 C11.4174497,17.026255 11.5422819,16.8772617 11.5437584,16.468 C11.5469799,15.5522953 11.544698,14.6365906 11.5449664,13.7210201 M8.65355705,7.54008054 C10.7104698,7.54008054 12.7673826,7.53981208 14.8242953,7.54048322 C15.3187919,7.54061745 15.4759732,7.67296644 15.5610738,8.16316779 C15.58,8.27242953 15.5892617,8.38477852 15.5892617,8.49565101 C15.5907383,11.5363221 15.5927517,14.5769933 15.5892617,17.6176644 C15.5880537,18.7600805 15.098255,19.5124295 14.1606711,19.7857181 C13.886443,19.8657181 13.5887248,19.8953826 13.3016107,19.8960537 C10.1826846,19.9035705 7.06375839,19.9051812 3.94483221,19.8984698 C2.80818792,19.8960537 2.08067114,19.4093423 1.80228188,18.4835705 C1.74885906,18.3059866 1.73610738,18.1105503 1.73583893,17.923302 C1.73208054,14.7038389 1.73275168,11.4842416 1.73355705,8.26477852 C1.73369128,7.71779866 1.90926174,7.54061745 2.44926174,7.54048322 C4.51731544,7.53967785 6.58550336,7.54008054 8.65355705,7.54008054'
    }),
    e('path', {
      key: '2',
      d:
        'M8.64036242,3.43114094 C8.96398658,3.43114094 9.28761074,3.43006711 9.6112349,3.43154362 C9.8302953,3.43261745 9.94063087,3.33691275 9.93418792,3.10711409 C9.92640268,2.82845638 9.93687248,2.54912752 9.93136913,2.27033557 C9.92479195,1.93060403 9.76277852,1.74375839 9.42613423,1.73395973 C8.90210738,1.71879195 8.37700671,1.71946309 7.85284564,1.73355705 C7.51794631,1.74255034 7.34291275,1.92939597 7.3302953,2.26147651 C7.31969128,2.54013423 7.33593289,2.81973154 7.32452349,3.09825503 C7.31418792,3.35006711 7.43875168,3.43597315 7.66948993,3.43288591 C7.99311409,3.42872483 8.31673826,3.43181208 8.64036242,3.43114094 M8.63875168,5.83194631 C6.05539597,5.83194631 3.47204027,5.83194631 0.888818792,5.83181208 C0.137006711,5.83181208 9.39597315e-05,5.69208054 9.39597315e-05,4.92751678 C0.000228187919,4.68147651 -0.000174496644,4.43543624 0.000228187919,4.18939597 C0.00116778523,3.61395973 0.17714094,3.43557047 0.763852349,3.43436242 C2.19526174,3.43167785 3.62680537,3.43342282 5.05821477,3.43328859 C5.17002685,3.43328859 5.2826443,3.42456376 5.39351678,3.43463087 C5.70868456,3.4633557 5.82975839,3.33610738 5.82841611,3.0104698 C5.82626846,2.4538255 5.81848322,1.88926174 5.90371812,1.34214765 C6.01177181,0.649798658 6.58358389,0.171812081 7.28157047,0.150201342 C8.16385235,0.122684564 9.04801342,0.157449664 9.93056376,0.134630872 C10.676604,0.115302013 11.3676107,0.81557047 11.4297584,1.57973154 C11.4669396,2.03543624 11.453651,2.49530201 11.4611678,2.95342282 C11.4685503,3.40832215 11.4888188,3.43248322 11.9323087,3.43275168 C13.4085503,3.43395973 14.8846577,3.43288591 16.3608993,3.43342282 C17.1454631,3.4338255 17.2590201,3.5461745 17.2590201,4.31597315 C17.2590201,4.59557047 17.2610336,4.87516779 17.2588859,5.1547651 C17.2552617,5.63691275 17.071906,5.82872483 16.5898926,5.83006711 C15.1920403,5.83422819 13.7941879,5.83181208 12.3962013,5.83181208 C11.1437181,5.83194631 9.8912349,5.83181208 8.63875168,5.83194631'
    })
  ])
}

Trash.displayName = 'Trash'

Trash.propTypes = {
  className: PropTypes.string
}

export default Trash
