import * as R from "ramda";
import { set } from "@kofile/redux-lenses";
import { types as syncTypes } from "@kofile/ko-search-action-types";
import * as lenses from "../lenses";

const { counties } = lenses;

export default {
  [syncTypes.FETCH_SUPPORT_TENANTS_FULFILLED_0]: [
    set(counties).with((action, state) => {
      const rootState = R.view(counties, state);

      const data = R.toPairs(action.payload).reduce(
        (acc, [tenantId, config]) =>
          R.merge(acc, {
            [tenantId]: {
              name: config.name,
              sealUrl: config.tenantSealUrl,
              clerkName: `${config.clerkFirstName} ${config.clerkLastName}`,
              addressLine1: config.addressLine1,
              addressLine2: config.addressLine2
            }
          }),
        {}
      );

      return {
        ...rootState,
        data: R.omit(["global"], data)
      };
    })
  ]
};
