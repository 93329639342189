import React from "react";
import { setDisplayName } from "recompose";
import styled, { css } from "react-emotion";
import * as Icons from "@kofile/icons";

const checkmarkStyle = css`
  height: 7px;
  user-select: none;
  width: 10px;
  fill: #ffffff;
`;

const Button = styled("button")`
  background: ${({ checked }) =>
    checked ? "rgba(0, 109, 213, 1)" : "transparent"};
  border: 0;
  padding: 0;
  margin: 0;
  display: inline-block;
  border: 1px solid rgba(4, 29, 68, 0.2);
  border-radius: 2px;
  width: 17px;
  height: 17px;
  line-height: 0;
`;

export default setDisplayName("FakeCheckbox")(props => {
  return (
    <Button
      type="button"
      role="checkbox"
      checked={props.checked}
      aria-checked={props.checked}
      tabIndex={0}
      onClick={props.onClick}
      id={props.id}
    >
      <Icons.CheckMark className={checkmarkStyle} />
    </Button>
  );
});
