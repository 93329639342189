export const defaultState = {
  ids: null,
  data: null,
  isLoading: true,
  total: null
};

export const orderState = {
  id: "",
  amount: 0,
  currency: "",
  createDate: "",
  items: [],
  selectedItems: [],
  loading: false,
  zipLink: {
    orderId: null,
    link: "",
    isLoading: true,
    error: ""
  },
  pdfLinks: {},
  refundSuccess: false,
  refund: {}
};

export const ordersState = {
  ids: [],
  data: [],
  loading: false
};

export const userState = {
  userName: "",
  isLoggedIn: false,
  id: null,
  createdUser: "",
  jwt: null,
  error: ""
};

export const socketState = {
  isOpened: false,
  isRetrying: false,
  isClosed: false,
  error: ""
};

export const counties = {
  data: {},
  loading: false,
  total: 4
};
