import { OrderReceipt } from "@kofile/react-order-receipt";
import React, { useContext } from "react";
import { ReactReduxContext } from "react-redux";
import { getters } from "../store";

const Receipt = ({ style, ...props }) => {
  const { storeState: state } = useContext(ReactReduxContext);

  const officials = getters.configuration.tenantOfficials(state);

  return <OrderReceipt className={style} officials={officials} {...props} />;
};

export default Receipt;
