import React, { Component } from "react";
import * as R from "ramda";
import PropTypes from "prop-types";
import { noop } from "../../utils";
import { Date } from "../";

class DateRange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      from: this.props.from || "",
      to: this.props.to || ""
    };

    this.handlePickFirst = date => {
      this.setState({
        from: date
      });
    };

    this.handlePickSecond = date => {
      this.setState({
        to: date
      });
    };
  }

  componentWillUpdate(nextProps, nextState) {
    const { from, to } = nextState;
    const dateRangeIsSet = R.and(R.not(R.isEmpty(from)), R.not(R.isEmpty(to)));

    if (dateRangeIsSet) {
      const dateRange = { from, to };

      this.props.handleRangePicked(dateRange);
    }
  }

  render() {
    const state = this.state;

    return (
      <div className="date-range">
        <Date handleDatePick={this.handlePickFirst} selected={state.from} />
        <div className="date-range--to">to</div>
        <Date
          calendarClassName="date-range__right"
          handleDatePick={this.handlePickSecond}
          selected={state.to}
          popperPlacement="bottom-end"
        />
      </div>
    );
  }
}

DateRange.propTypes = {
  handleRangePicked: PropTypes.func,
  to: PropTypes.object,
  from: PropTypes.object
};

DateRange.defaultProps = {
  handleRangePicked: noop
};

export default DateRange;
