export const LOADING = "loading";
export const NO_RESULTS = "noResults";
export const RESULTS = "results";
export const ERROR = "error";

const getScreenState = (isLoading, total, error) => {
  if (error) {
    return ERROR;
  } else if (isLoading) {
    return LOADING;
  } else if (!isLoading && total === 0) {
    return NO_RESULTS;
  }

  return RESULTS;
};

const getTitle = screenState => {
  switch (screenState) {
    case LOADING:
      return "Loading Refunds...";
    case NO_RESULTS:
    case ERROR:
      return "Refunds";
    case RESULTS:
      return `Refunds`;
  }
};

export const getScreen = items => {
  const state = getScreenState(items.isLoading, items.total, items.error);
  const title = getTitle(state);

  return { title, state };
};

export const screenFor = screen => {
  return {
    state: screen,
    title: getTitle(screen)
  };
};
