import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'
import Page from './Page'

const Thumbnails = ({ className }) => {
  const padding = 20
  const halfBase = (BASE - padding) / 2
  const pageY = halfBase + padding
  const viewBox = `0 0 ${BASE * Page.WIDTH_RATIO} ${BASE}`

  return e(SVG, { className, viewBox }, [
    e(Page, {
      key: 'upper left',
      __width: halfBase,
      __height: halfBase * Page.WIDTH_RATIO,
      __x: padding * -1.5,
      withLines: false
    }),
    e(Page, {
      key: 'upper right',
      __width: halfBase,
      __height: halfBase * Page.WIDTH_RATIO,
      __x: padding * -1.5 + halfBase,
      withLines: false
    }),
    e(Page, {
      key: 'lower left',
      __width: halfBase,
      __height: halfBase * Page.WIDTH_RATIO,
      __x: padding * -1.5,
      __y: pageY,
      withLines: false
    }),
    e(Page, {
      key: 'lower right',
      __width: halfBase,
      __height: halfBase * Page.WIDTH_RATIO,
      __x: padding * -1.5 + halfBase,
      __y: pageY,
      withLines: false
    })
  ])
}

Thumbnails.propTypes = {
  className: PropTypes.string
}

export default Thumbnails
