import { createAction } from "redux-actions";
import { types as syncTypes } from "@kofile/ko-search-action-types";

/**
 * ACTIONS
 */
export const actions = {
  fetch: createAction(syncTypes.FETCH_HISTORY_BY_DAY_0),
  reject: createAction(syncTypes.FETCH_HISTORY_BY_DAY_REJECTED_0),
  fulfilled: createAction(syncTypes.FETCH_HISTORY_BY_DAY_FULFILLED_0),
  cancel: createAction(syncTypes.FETCH_HISTORY_BY_DAY_CANCELLED_0)
};

export default actions;
