import React from "react";
import PropTypes from "prop-types";
import styled from "react-emotion";

const LIGHT_GRAY = "rgba(4,29,68,0.15)";
const DARK_GRAY = "rgba(4,29,68,.6)";
const BLUE = "#006DD5";
const WHITE = "#ffffff";
const LEFT = "left";
const RIGHT = "right";

const StyledButton = styled("button")(props => ({
  color: btnColor(props),
  backgroundColor: complement(btnColor)(props),
  border: `1px solid ${borderColor(props)}`,
  borderRadius: getBorderRadius(props),
  padding: 12,
  cursor: props.disabled ? "not-allowed" : "pointer"
}));

export default Object.assign(props => <StyledButton {...props} />, {
  joins: { LEFT, RIGHT },
  displayName: "Button",
  propTypes: {
    onClick: PropTypes.func,
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    join: PropTypes.oneOf([LEFT, RIGHT])
  },
  defaultProps: {
    onClick: () => {},
    disabled: false,
    emphatic: false,
    join: null,
    type: "button"
  }
});

function btnColor({ emphatic, disabled }) {
  if (disabled && !emphatic) return LIGHT_GRAY;
  if (disabled && emphatic) return DARK_GRAY;
  if (!disabled && emphatic) return WHITE;
  if (!disabled && !emphatic) return BLUE;
}

function borderColor({ emphatic, disabled }) {
  if (!disabled && emphatic) return BLUE;

  return LIGHT_GRAY;
}

function complement(fn) {
  return color =>
    ({
      [LIGHT_GRAY]: DARK_GRAY,
      [DARK_GRAY]: LIGHT_GRAY,
      [WHITE]: BLUE,
      [BLUE]: WHITE
    }[fn(color)]);
}

function getBorderRadius({ join }) {
  if (join && join === LEFT) return "0 4px 4px 0";
  if (join && join === RIGHT) return "4px 0 0 4px";

  return 4;
}
