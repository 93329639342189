import React from "react";
import { Widget, ReturnButton } from "../../../components";
import Results from "./Results";
import Loading from "../../Dashboard/widgets/Refunds/Loading";
import Pagination from "@kofile/react-components-pagination";
import SearchByUser from "../../Dashboard/Search/SearchByUser";
import SearchByOrder from "../../Dashboard/Search/SearchByOrder";
import PropTypes from "prop-types";

const Action = props => (
  <div className="flex-container">
    <SearchByUser handleSelect={props.onFindByUser} userName={props.userName} />
    <span className="divider">OR</span>
    <SearchByOrder onFindByOrder={props.onFindByOrder} />
  </div>
);

Action.propTypes = {
  onFindByUser: PropTypes.any,
  userName: PropTypes.any,
  onFindByOrder: PropTypes.any
};

const View = props => (
  <div className="refunds">
    <ReturnButton className="refunds__return-button" />
    <Widget
      className="refunds__widget"
      title={props.title}
      status={getStatus(props)}
      action={<Action {...props} />}
    >
      {props.hasResults && (
        <Results records={props.records} goToRefund={props.goToRefund} />
      )}
      {props.isLoading && <Loading timeout={props.timeout} />}
      {props.noResults && <Widget.Empty />}
      {props.hasError && <Widget.Error message={props.errorMessage} />}
    </Widget>
    {props.hasResults && (
      <Pagination
        currentPage={props.pagination.page}
        totalPages={props.pagination.totalPages}
        handlePageChange={props.goToPage}
      />
    )}
  </div>
);

View.propTypes = {
  goToPage: PropTypes.any,
  pagination: PropTypes.any,
  hasResults: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.any,
  isLoading: PropTypes.bool,
  noResults: PropTypes.any,
  records: PropTypes.any,
  timeout: PropTypes.any,
  goToRefund: PropTypes.any,
  title: PropTypes.any
};

export default View;

function getStatus(props) {
  if (props.hasError) return Widget.status.ERROR;
  if (props.noResults) return Widget.status.EMPTY;

  return Widget.status.NORMAL;
}
