import React from "react";
import PropTypes from "prop-types";
import { Report, GroupedTable } from "../../../components";
import connect, { tableConfig } from "./connect";

const reportType = "Search Metrics by User/Date";
const searchTitle = "Search Metrics by User/Date";
const searchDescription =
  "Look up past search activity by entering a start date and end date.";
const radioOptions = [
  {
    label: "Group By User",
    value: "user",
    id: "radio1"
  },
  {
    label: "Group By Date",
    value: "day",
    id: "radio2"
  }
];

export const UsersActivity = props => {
  return (
    <Report
      widgetTitle={searchTitle}
      searchTitle={searchTitle}
      searchDescription={searchDescription}
      Table={<GroupedTable data={props.data} config={tableConfig} />}
      reportType={reportType}
      radioOptions={radioOptions}
      {...props}
    />
  );
};

UsersActivity.displayName = "UsersActivity";
UsersActivity.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array)
};

export default connect(UsersActivity);
