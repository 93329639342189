import * as R from "ramda";
import * as actions from "../../actions";
import { types as syncTypes } from "@kofile/ko-search-action-types";
import { LOCATION_CHANGE } from "connected-react-router";
import * as getters from "../../getters";

const isFetchRefundsError = R.compose(
  R.equals(syncTypes.FETCH_REFUNDS_1),
  R.pathOr(false, ["payload", "lastAction", "type"])
);

// TODO actually notify QA of failure ;)
const handleRefundsRejected = actionStream =>
  actionStream
    .ofType(syncTypes.API_ERROR_0)
    .filter(isFetchRefundsError)
    .mapTo(
      actions.refunds.rejected({
        reason:
          "Sorry, we encountered an error. Our Quality Assurance department has been notified of this problem. Please try again later."
      })
    );

const requeryWhenPaginating = (actionStream, store) =>
  actionStream
    .ofType(LOCATION_CHANGE)
    .filter(({ payload }) => {
      const state = store.getState();
      const nextPage = getters.router.page(state);
      const pagination = getters.refunds.pagination(state);
      const regexp = new RegExp("^/[0-9]+/refunds$");
      const isRefundsView = regexp.test(payload.location.pathname);

      if (!pagination) return isRefundsView;

      const prevPage = pagination.page;

      return isRefundsView && nextPage !== prevPage;
    })
    .map(() => {
      const state = store.getState();
      const pagination = getters.refunds.pagination(state);
      const page = getters.router.page(state);
      const userName = getters.refunds.userNameQuery(state);
      const orderNumber = getters.refunds.orderNumberQuery(state);

      if (!pagination) {
        return actions.refunds.fetch({
          pagination: {
            offset: 0,
            limit: 20
          }
        });
      }

      return actions.refunds.fetch({
        pagination: {
          offset: (page - 1) * pagination.size,
          limit: pagination.size
        },
        userName,
        orderNumber
      });
    });

export default [handleRefundsRejected, requeryWhenPaginating];
