import user from "./user";
import refunds from "./refunds";
import county from "./county";
import orders from "./orders";
import order from "./order";
import configuration from "./configuration";

export default {
  order,
  user,
  orders,
  refunds,
  county,
  configuration
};
