import * as R from "ramda";

const root = R.lensProp("counties");
const ids = R.compose(
  root,
  R.lensProp("ids")
);
const data = R.compose(
  root,
  R.lensProp("data")
);
const error = R.compose(
  root,
  R.lensProp("error")
);
const loading = R.compose(
  root,
  R.lensProp("loading")
);
const total = R.compose(
  root,
  R.lensProp("total")
);

export default Object.assign(root, {
  ids,
  data,
  error,
  loading,
  total
});
