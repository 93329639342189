import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import Radio from "./Radio";

const makeRadio = (props, id) => <Radio key={id} {...props} />;

makeRadio.displayName = "Radio";
const mapIndexed = R.addIndex(R.map);

const RadioSet = ({ options = [], value = "", onChange = () => {} }) => {
  const mapPropsToOptions = option => {
    const checked = option.value === value;

    return {
      ...option,
      checked,
      onChange
    };
  };

  const optionsWithProps = R.map(mapPropsToOptions, options);
  const mappedRadios = mapIndexed(makeRadio, optionsWithProps);

  return <React.Fragment>{mappedRadios}</React.Fragment>;
};

RadioSet.displayName = "RadioSet";

RadioSet.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default RadioSet;
