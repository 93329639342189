import { types as syncTypes } from "@kofile/ko-search-action-types";
import { set } from "@kofile/redux-lenses";
import * as R from "ramda";
import { types, newApiTypes } from "../actions/order";
import * as lenses from "../lenses";
import { linkStates } from "../lenses/order";
import { default as userLens } from "../lenses/user";
import { default as countyLens } from "../lenses/county";
import * as utils from "../../utils";

const root = lenses.order;

const makeRefundQuote = ({ loading = false, amount = null, error = null }) => ({
  loading,
  amount,
  error,
});

export default {
  [newApiTypes.fetchOrder]: [set(root.loading).as(true)],
  [newApiTypes.order]: [
    set(root.order).with(R.path(["payload", "order"])),
    set(root.items).with(R.path(["payload", "order", "lineItems"])),
    set(root.loading).as(false),
  ],
  [newApiTypes.errorFetchingOrder]: [
    set(root.error).with(utils.getReason),
    set(root.loading).as(false),
  ],
  [newApiTypes.zipPath]: [
    set(root.zipLink).with(({ payload }, state) => {
      const jwt = R.view(userLens.jwt, state);
      const tenantId = R.view(countyLens, state);

      return makeLink({
        jwt,
        tenantId,
        link: payload.link,
      });
    }),
  ],
  [newApiTypes.zipPathError]: [set(root.zipLink).as(linkStates.zipError)],
  [newApiTypes.pdfPath]: [
    set(root.pdfLinks).with(({ payload }, state) => {
      const pdfLinks = R.pathOr({}, ["order", "pdfLinks"], state);
      const jwt = R.view(userLens.jwt, state);
      const tenantId = R.view(countyLens, state);
      const linkObj = makeLink({ jwt, tenantId, link: payload.link });

      return R.set(R.lensProp(payload.itemId), linkObj, pdfLinks);
    }),
  ],
  [newApiTypes.pdfPathError]: [
    set(root.pdfLinks).with(({ payload }, state) => {
      const pdfLinks = R.pathOr({}, ["order", "pdfLinks"], state);

      return R.set(R.lensProp(payload.itemId), linkStates.pdfError, pdfLinks);
    }),
  ],
  [syncTypes.REQUEST_REFUND_FULFILLED_1]: [set(root.refundSuccess).as(true)],
  [syncTypes.FETCH_REFUND_QUOTE_0]: [
    set(root.refundQuote).with(() => makeRefundQuote({ loading: true })),
  ],
  [syncTypes.FETCH_REFUND_QUOTE_FULFILLED_0]: [
    set(root.refundQuote).with(({ payload }) =>
      makeRefundQuote({ amount: payload.refundAmount })
    ),
  ],
  [syncTypes.FETCH_REFUND_QUOTE_REJECTED_0]: [
    set(root.refundQuote).with(() =>
      makeRefundQuote({
        error:
          "We are unable to calculate the refund amount at this time. You may still submit the refund request.",
      })
    ),
  ],
  [types.TOGGLE_ITEM_SELECTED]: [
    set(root.selectedItems).with(({ payload: id }, state) => {
      const selectedItems = R.view(root.selectedItems, state);

      return R.contains(id, selectedItems)
        ? R.without([id], selectedItems)
        : R.concat([id], selectedItems);
    }),
  ],
  [types.CLEAR_SELECTED_ITEMS]: [set(root.selectedItems).as([])],
  [types.RESET]: [set(root.refundSuccess).as(false)],
};

function makeLink({ jwt, tenantId, link }) {
  const [url, params = ""] = link.split("?");
  const paramsToAppend = `auth=${jwt}&tenantId=${tenantId}`;

  const linkWithParams = params
    ? `${url}?${params}&${paramsToAppend}`
    : `${url}?${paramsToAppend}`;

  return {
    link: linkWithParams,
    error: "",
    isLoading: false,
  };
}
