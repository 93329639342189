import * as R from "ramda";
import lens from "../lenses/historyByDay";

export default {
  root: R.view(lens),
  data: R.view(lens.data),
  loading: R.view(lens.loading),
  tableView: state => {
    const data = R.defaultTo([], R.view(lens.data, state));
    const noData = R.isEmpty(data);

    if (noData) {
      return [];
    }

    const columnOrder = [
      "date",
      "department",
      "searches",
      "downloads",
      "documentsDownloaded"
    ];

    const mappedData = data.map(({ data: groupData }) => {
      const getDataAsArray = R.compose(
        R.values,
        R.pick(columnOrder)
      );

      return R.map(getDataAsArray, groupData);
    });

    return mappedData;
  }
};
