import * as Rx from "rxjs/Rx";
import { propOr } from "ramda";
import { types } from "../../actions/county";
import * as actions from "../../actions";
import userGetters from "../../getters/user";

export const fetchTenantConfiguration = (actionStream, store) =>
  actionStream.ofType(types.SET).mergeMap(({ payload }) => {
    const isLoggedIn = userGetters.isLoggedIn(store.getState());

    const curConfiguration = propOr(
      null,
      payload,
      store.getState().configuration
    );

    if (isLoggedIn && payload && !curConfiguration) {
      return Rx.Observable.of(actions.configuration.fetch({ tenant: payload }));
    }

    return Rx.Observable.empty();
  });

export default [fetchTenantConfiguration];
