import * as actions from "../../actions";
import * as getters from "../../getters";
import { LOCATION_CHANGE } from "connected-react-router";

export const requeryWhenPaginatingOnDashboard = (actionStream, store) =>
  actionStream
    .ofType(LOCATION_CHANGE)
    .filter(({ payload }) => {
      const state = store.getState();
      const nextPage = getters.router.page(state);
      const pagination = getters.orders.pagination(state);

      if (!pagination) return false;

      const prevPage = pagination.page;
      const regexp = new RegExp("^/[0-9]+/dashboard$");
      const isDashboard = regexp.test(payload.location.pathname);

      return isDashboard && nextPage !== prevPage;
    })
    .map(() => {
      const state = store.getState();
      const userTerm = getters.orders.userTerm(state);
      const orderTerm = getters.orders.orderTerm(state);
      const { size } = getters.orders.pagination(state);
      const page = getters.router.page(state);

      return actions.orders.fetch({
        userName: userTerm,
        orderNumber: orderTerm,
        offset: (page - 1) * size,
        limit: size
      });
    });

export default [requeryWhenPaginatingOnDashboard];
