import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../../components";
import { connect } from "react-redux";
import { getters, actions } from "../../../../store";
import { css } from "emotion";
import iconMail from "../../../../../public/assets/img/icon_mail.svg";

const style = getStyle();

const SuccessModal = props => (
  <Modal close={props.reset} show={props.refundSuccess}>
    <img className={style.icon} alt="email" src={iconMail} />
    <h2 className={style.title} data-testid="alert">
      A Refund Request has been Sent
    </h2>
    <p className={style.text}>
      We&apos;ve sent an email to the appropriate department for approval.
      We&apos;ll email you an update once it has been reviewed.
    </p>
  </Modal>
);

SuccessModal.displayName = "SuccessModal";

SuccessModal.propTypes = {
  reset: PropTypes.any,
  modal: PropTypes.shape({
    close: PropTypes.func.isRequired
  }).isRequired,
  refundSuccess: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  refundSuccess: getters.order.refundSuccess(state)
});

const mapDispatchToProps = dispatch => ({
  reset: () => {
    dispatch(actions.order.reset());

    window.location.reload();
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuccessModal);

function getStyle() {
  return {
    icon: css`
      display: block;
      margin: 30px auto;
      width: 90px;
    `,
    title: css`
      color: #041d44;
      font-size: 28px;
      font-weight: 400;
      text-align: center;
    `,
    text: css`
      color: rgba(4, 29, 68, 0.6);
      font-size: 18px;
      font-weight: 400;
      margin: 10px 0;
      text-align: center;
    `
  };
}
