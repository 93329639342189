import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { actions, getters } from "../../../../store";
import { connect } from "react-redux";
import View from "./View";

const SEARCH = "search";
const NEW = "new";
const EDIT = "edit";

const lens = {
  screen: R.lensProp("screen"),
  userId: R.lensProp("userId")
};

const update = {
  screen: { to: R.set(lens.screen) },
  userId: { to: R.set(lens.userId) }
};

export class Users extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: SEARCH,
      userId: null
    };

    this.edit = userId => {
      this.setState(
        R.compose(
          update.screen.to(EDIT),
          update.userId.to(userId)
        )
      );
    };

    this.new = event => {
      event.preventDefault();

      this.setState(update.screen.to(NEW));
    };

    this.search = event => {
      event.preventDefault();

      this.setState(update.screen.to(SEARCH), () => {
        this.props.clearUpdateStatus();
        this.props.clearCreateStatus();
        this.props.clearError();
      });
    };

    this.onSubmit = values => {
      const getCheckboxValue = value => R.equals(R.head(value), "true");

      const userAction =
        this.state.screen === "new"
          ? this.props.createUser
          : this.props.updateUser;

      const info = {
        id: this.state.userId,
        accountType: values.accountType.value,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        email: values.email,
        isActive: getCheckboxValue(values.isActive),
        isVerified: getCheckboxValue(values.isVerified)
      };

      userAction(info);
    };
  }

  render() {
    const props = {
      title: getTitle(this.state.screen),
      className: this.props.className,
      isSearching: this.state.screen === SEARCH,
      isEditing: this.state.screen === EDIT,
      isCreating: this.state.screen === NEW,
      isSuccessfulUpdate: this.props.successfulUpdate,
      isSuccessfulCreate: this.props.successfulCreate,
      edit: this.edit,
      new: this.new,
      search: this.search,
      onSubmit: this.onSubmit,
      userId: this.state.userId
    };

    return <View {...props} />;
  }
}

Users.propTypes = {
  clearUpdateStatus: PropTypes.func,
  clearCreateStatus: PropTypes.func,
  clearError: PropTypes.func,
  createUser: PropTypes.func,
  updateUser: PropTypes.func,
  className: PropTypes.string,
  successfulUpdate: PropTypes.bool,
  successfulCreate: PropTypes.bool
};

const isSuccessful = R.equals("success");

const mapStateToProps = state => ({
  successfulUpdate: isSuccessful(getters.user.updatedUser(state)),
  successfulCreate: isSuccessful(getters.user.createdUser(state))
});

const mapDispatchToProps = dispatch => ({
  updateUser: info => dispatch(actions.user.update(info)),
  createUser: info => dispatch(actions.user.create(info)),
  clearUpdateStatus: () => dispatch(actions.user.update.clean()),
  clearCreateStatus: () => dispatch(actions.user.create.clean()),
  clearError: () => dispatch(actions.user.clearError())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);

function getTitle(screen) {
  return {
    [SEARCH]: "Find User",
    [EDIT]: "Edit User",
    [NEW]: "Create User"
  }[screen];
}
