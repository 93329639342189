import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'
import collapseTransforms from '../collapseTransforms'

const Rotate = ({ className, id = '' }) => {
  const thickness = BASE * 0.175
  const a = 130 + thickness
  const offset = 25
  const viewBox = `0 0 ${BASE + offset} ${BASE}`

  return e(SVG, { className, viewBox }, [
    e(
      'defs',
      { key: 'defs' },
      e('mask', { id: `RotateMask${id}` }, [
        e('circle', {
          key: 'outer',
          cx: BASE / 2 + offset,
          cy: BASE / 2,
          r: BASE / 2,
          fill: 'white'
        }),
        e('circle', {
          key: 'inner',
          cx: BASE / 2 + offset,
          cy: BASE / 2,
          r: BASE / 2 - thickness,
          fill: 'black'
        }),
        e('rect', {
          key: 'cutout',
          x: offset,
          y: 0,
          width: BASE / 2,
          height: BASE / 2,
          fill: 'black',
          transform: `rotate(-45 ${BASE / 2 + offset} ${BASE / 2 + offset})`
        }),
        e('path', {
          key: 'arrow head',
          d: `
            M0 ${a / -2 + thickness / 2}
            V${a / 2 + thickness / 2}
            L${Math.sin(50 * Math.PI / 180) * a * -1} ${thickness / 2}
            Z
          `,
          fill: 'white',
          transform: collapseTransforms([
            `rotate(-45 ${BASE / 2 + offset} ${BASE / 2})`,
            `translate(${BASE / 2 + offset} 0)`
          ])
        })
      ])
    ),
    e('rect', {
      key: 'fill',
      x: 0,
      y: 0,
      width: BASE + offset,
      height: BASE,
      mask: `url(#RotateMask${id})`
    })
  ])
}

Rotate.propTypes = {
  className: PropTypes.string
}

export default Rotate
