import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { actions, getters } from "../../../store";

export const tableConfig = {
  cellOptions: {
    on: {},
  },
  headerOptions: {
    on: {},
  },
  rowOptions: {
    on: {},
  },
  data: [
    {
      order: 0,
      name: "orderId",
      cellOptions: {
        on: {},
      },
      headerOptions: {
        valueTransform: Object.assign(() => <h2>ORDER ID</h2>, {
          displayName: "Order Id",
        }),
      },
    },
    {
      order: 1,
      name: "subtotal",
      cellOptions: {
        on: {},
      },
      headerOptions: {
        valueTransform: Object.assign(() => <h2>DOCUMENT CHARGE</h2>, {
          displayName: "Document Charge",
        }),
      },
    },
    {
      order: 2,
      name: "convenienceFee",
      cellOptions: {
        on: {},
      },
      headerOptions: {
        valueTransform: Object.assign(() => <h2>CONVENIENCE FEE</h2>, {
          displayName: "Convenience Fee",
        }),
      },
    },
    {
      order: 3,
      name: "certificationFee",
      cellOptions: {
        on: {},
      },
      headerOptions: {
        valueTransform: Object.assign(() => <h2>CERTIFICATION FEE</h2>, {
          displayName: "Certification Fee",
        }),
      },
    },
    {
      order: 4,
      name: "totalCharged",
      cellOptions: {
        on: {},
      },
      headerOptions: {
        valueTransform: Object.assign(() => <h2>TOTAL CHARGED</h2>, {
          displayName: "Total Charged",
        }),
      },
    },
    {
      order: 5,
      name: "orderDate",
      cellOptions: {
        on: {},
      },
      headerOptions: {
        valueTransform: Object.assign(() => <h2>DATE</h2>, {
          displayName: "Date",
        }),
      },
    },
    {
      order: 6,
      name: "userName",
      cellOptions: {
        on: {},
      },
      headerOptions: {
        valueTransform: Object.assign(() => <h2>NAME</h2>, {
          displayName: "Name",
        }),
      },
    },
    {
      order: 7,
      name: "userEmail",
      cellOptions: {
        on: {},
      },
      headerOptions: {
        valueTransform: Object.assign(() => <h2>EMAIL</h2>, {
          displayName: "Email",
        }),
      },
    },
    {
      order: 8,
      name: "numberOfItems",
      cellOptions: {
        on: {},
      },
      headerOptions: {
        valueTransform: Object.assign(() => <h2>NUMBER OF DOCUMENTS</h2>, {
          displayName: "Number Of Documents",
        }),
      },
    },
  ],
};

const mapDispatchToProps = (dispatch) => ({
  fetchData: (searchData) => dispatch(actions.billingReport.fetch(searchData)),
});

const mapStateToProps = (state) => ({
  data: getters.billingReport.tableView(state),
  isLoading: getters.billingReport.loading(state),
  county: getters.county(state),
});

export default R.compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);
