import * as R from "ramda";
import lens from "../lenses/billing-report";

export default {
  root: R.view(lens),
  data: R.view(lens.data),
  loading: R.view(lens.loading),
  tableView: (state) => {
    const data = R.defaultTo({ rows: [] }, R.view(lens.data, state));
    const noData = R.isEmpty(data.rows);

    if (noData) {
      return [];
    }

    const columnOrder = [
      "orderId",
      "subtotal",
      "convenienceFee",
      "certificationFee",
      "totalCharged",
      "date",
      "userName",
      "userEmail",
      "numberOfItems",
    ];

    return data.rows.map((item) => columnOrder.map((key) => item[key]));
  },
};
