import { types } from "../../actions/order";
import { actions, getters } from "../..";
import { types as syncTypes } from "@kofile/ko-search-action-types";
import { newApiTypes } from "../../actions/order";
import { propSatisfies, equals, path, pathOr } from "ramda";

export const requestRefund = (actionStream, store) =>
  actionStream.ofType(types.REQUEST_REFUND).map(action => {
    const state = store.getState();
    const { payload } = action;
    const orderRefund = getters.order.orderRefund(state, payload);

    return {
      type: syncTypes.REQUEST_REFUND_1,
      payload: orderRefund
    };
  });

export const fetchOrderOnRefundComplete = (actionStream, store) => {
  const types = {
    completed: syncTypes.PROCEED_REFUND_COMPLETE_FULFILLED_0,
    rejected: syncTypes.PROCEED_REFUND_REJECT_FULFILLED_0
  };

  return actionStream
    .ofType(types.completed, types.rejected)
    .map(({ payload }) => {
      const state = store.getState();
      const { county, refund } = state;
      const order = getters.order.order(state);
      const refundOrderId = path(["data", "orderId"], refund);
      const refundId = payload.id || pathOr(null, ["data", "id"], refund);

      const useFetchARefund = propSatisfies(
        equals(true),
        "useFetchARefund",
        getters.configuration.flips(county, state)
      );

      if (useFetchARefund) {
        return actions.refund.fetch({
          id: parseInt(refundId),
          orderId: parseInt(refundOrderId)
        });
      }

      return {
        type: newApiTypes.fetchOrder,
        payload: { id: order.orderId }
      };
    });
};

export default [requestRefund, fetchOrderOnRefundComplete];
