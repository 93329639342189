import React from "react";
import PropTypes from "prop-types";
import * as rc from "recompose";
import { Link } from "react-router-dom";
import { Widget } from "../../../../components";
import Results from "./Results";
import Loading from "./Loading";

const Title = props => {
  const { countyId, hasResults, title } = props;
  const link = hasResults && (
    <Link className="widget__title" to={`/${countyId}/refunds`}>
      See All
    </Link>
  );

  return (
    <div>
      {title}
      {link}
    </div>
  );
};

Title.propTypes = {
  countyId: PropTypes.string,
  hasResults: PropTypes.bool,
  title: PropTypes.string
};

export default rc.setDisplayName("Dashboard.widgets.Refunds.View")(props => (
  <Widget
    className="refunds__widget"
    title={<Title {...props} />}
    status={getStatus(props)}
  >
    {props.hasResults && <Results records={props.records} />}
    {props.isLoading && <Loading timeout={props.timeout} />}
    {props.noResults && <Widget.Empty />}
    {props.hasError && <Widget.Error message={props.errorMessage} />}
  </Widget>
));

function getStatus(props) {
  if (props.hasError) return Widget.status.ERROR;
  if (props.noResults) return Widget.status.EMPTY;

  return Widget.status.NORMAL;
}
