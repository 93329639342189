import React from "react";
import { Route, Switch } from "react-router-dom";
import * as components from "./components";
import { setDisplayName } from "recompose";
import { ThemeProvider } from "emotion-theming";
import SignIn from "./SignIn";
import Authenticated from "./Authenticated";
import { hot } from "react-hot-loader";

const theme = {
  button: {
    color: "#006dd5"
  }
};

const App = setDisplayName("App")(() => (
  <ThemeProvider theme={theme}>
    <div id="app" className="site-app">
      <components.Header />
      <div className="site-app__view">
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route path="/" component={Authenticated} />
        </Switch>
      </div>
      <components.Footer />
      <components.OnlineStatus />
    </div>
  </ThemeProvider>
));

const HotApp = hot(module)(App);

export { HotApp as App };
