import PropTypes from 'prop-types'
import { SVG, e } from '../utils'

const Notepad = ({ className }) => {
  const viewBox = `0 0 20 16`

  return e(SVG, { viewBox, className }, [
    e('path', {
      key: 'outline',
      d:
        'M7.881 0h4.547c.456 0 .896.075 1.315.262.278.125.337.39.127.61-.168.174-.343.343-.514.515-.107.106-.225.135-.379.106a3.398 3.398 0 0 0-.632-.067c-3.032-.003-6.063-.003-9.094-.002-.806 0-1.49.475-1.733 1.22-.058.18-.087.378-.088.567-.005 3.081-.004 6.162-.003 9.243 0 1.034.782 1.812 1.816 1.813h9.198c1.052 0 1.826-.778 1.827-1.832 0-.43.003-.862-.002-1.292a.447.447 0 0 1 .138-.347c.219-.215.436-.432.651-.65.12-.122.253-.168.417-.1.165.068.223.196.222.366-.003.737.012 1.476-.01 2.213-.041 1.43-1.107 2.697-2.507 2.99a3.71 3.71 0 0 1-.751.072c-3.056.005-6.113.004-9.169.003-1.43 0-2.634-.841-3.085-2.191a3.394 3.394 0 0 1-.164-1.049C-.004 9.384 0 6.318.002 3.252c0-1.427.84-2.614 2.177-3.086C2.53.043 2.891 0 3.26 0h4.622'
    }),
    e('path', {
      key: 'pencil',
      d:
        'M10.693 8.15c.11-.085.207-.144.285-.221 1.24-1.238 2.48-2.477 3.718-3.717.042-.042.083-.085.12-.132.108-.14.089-.263-.053-.368-.123-.091-.214-.07-.371.087l-3.79 3.79c-.04.039-.092.075-.107.121-.024.074-.053.171-.023.23.04.079.133.13.221.21m-2.49 2.556h1.076v1.064c.102 0 .188-.01.27.002.252.038.432-.055.605-.244.33-.36.688-.697 1.015-1.022L9.473 8.81c-.398.397-.812.81-1.224 1.224a.146.146 0 0 0-.044.088c-.004.187-.003.374-.003.584m-1.067 2.122v-.154c0-.966.001-1.932-.003-2.898 0-.103.032-.174.104-.245 2.429-2.426 4.856-4.854 7.284-7.281.03-.03.064-.059.084-.077l3.22 3.221c-.037.028-.097.064-.145.112l-5.15 5.148c-.7.7-1.403 1.399-2.107 2.095a.284.284 0 0 1-.175.085c-1.015.004-2.03.003-3.046.002-.014 0-.029-.003-.066-.008M18.522 4.596l-3.17-3.17c.027-.029.054-.063.085-.093.3-.302.6-.603.903-.903.435-.432 1.042-.471 1.492-.06.467.427.904.887 1.353 1.335.147.146.297.291.432.448.357.416.37.992-.002 1.392-.343.368-.725.7-1.093 1.051'
    })
  ])
}

Notepad.propTypes = {
  className: PropTypes.string
}

export default Notepad
