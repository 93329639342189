import PropTypes from 'prop-types'
import { e, SVG, BASE, RX } from '../utils'
import CheckMark from './CheckMark'

const CheckMarkBox = ({ className, id = '' }) =>
  e(SVG, { className }, [
    e(
      'defs',
      { key: 'defs' },
      e('mask', { id: `CheckMarkBoxMask${id}` }, [
        e('rect', {
          key: 'bg',
          x: 0,
          y: 0,
          width: BASE,
          height: BASE,
          rx: RX,
          fill: 'white'
        }),
        e(CheckMark, {
          key: 'check',
          __fill: 'black',
          __width: BASE * 0.75,
          __x: BASE / 2 - BASE * 0.375
        })
      ])
    ),
    e('path', {
      key: 'fill',
      d: `M0 0H${BASE}V${BASE}H0Z`,
      mask: `url(#CheckMarkBoxMask${id})`
    })
  ])

CheckMarkBox.displayName = 'CheckMarkBox'

CheckMarkBox.propTypes = {
  className: PropTypes.string
}

export default CheckMarkBox
