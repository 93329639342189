import { createAction } from "redux-actions";
import { types as syncTypes } from "@kofile/ko-search-action-types";
import { makeActionTypes } from "../../utils";

/**
 * TYPES
 */
export const types = makeActionTypes("ORDERS", ["RESET", "SET_SORT"]);

/**
 * ACTIONS
 */
export const actions = {
  fetch: createAction(syncTypes.FETCH_ORDERS_0),
  cancel: createAction(syncTypes.FETCH_ORDERS_CANCELLED_0),
  fulfilled: createAction(syncTypes.FETCH_ORDERS_FULFILLED_1),
  rejected: createAction(syncTypes.FETCH_ORDERS_REJECTED_0),
  reset: createAction(types.RESET),
  setSort: createAction(types.SET_SORT),
};

export default actions;
