import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Spinner } from "../";
import iconSearchOrder from "../../../public/assets/img/icon_search_order.svg";

const SearchIcon = ({ src }) => (
  <img className="search-label--icon" src={src} alt="Search Icon" />
);

SearchIcon.propTypes = {
  src: PropTypes.string.isRequired
};

const SearchReportsLabel = ({ title, description, searchIcon, isLoading }) => {
  const Icon = isLoading ? (
    <Spinner className="search-label--loading" />
  ) : (
    <SearchIcon src={searchIcon} />
  );

  const titleText = isLoading ? "Loading Results..." : title;

  const titleClass = classnames("search-label--title", {
    "search-label__title--loading": isLoading
  });

  return (
    <div className="search-label">
      {Icon}
      <h2 className={titleClass}>{titleText}</h2>
      <p className="search-label--description">{description}</p>
    </div>
  );
};

SearchReportsLabel.displayName = "SearchReportsLabel";
SearchReportsLabel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  searchIcon: PropTypes.string,
  isLoading: PropTypes.bool.isRequired
};

SearchReportsLabel.defaultProps = {
  title: "Search",
  description: "Some very interesting description",
  searchIcon: iconSearchOrder,
  isLoading: false
};

export default SearchReportsLabel;
