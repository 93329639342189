import React from "react";
import PropTypes from "prop-types";
import { HeaderCell } from "../";

const HeaderRow = ({ columns }) => {
  const headerCells = columns.map(({ label }, i) => (
    <HeaderCell key={i} value={label} />
  ));

  return <tr className="grouped-table__header-row">{headerCells}</tr>;
};

HeaderRow.displayName = "HeaderRow";

HeaderRow.propTypes = {
  columns: PropTypes.any
};

export default HeaderRow;
