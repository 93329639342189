import React from "react";
import styled from "react-emotion";
import iconSearchOrder from "../../../../public/assets/img/icon_search_order.svg";

const Initial = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 32px;
`;

const Title = styled("h1")`
  margin-top: 32px;
  color: rgba(4, 29, 68, 0.6);
  font-size: 32px;
  font-weight: normal;
`;

const Message = styled("p")`
  color: #041d44;
  margin-top: 10px;
`;

export default Object.assign(
  props => (
    <Initial className={props.className}>
      <img src={iconSearchOrder} alt="Search" />
      <Title>Search Order History</Title>
      <Message>
        Look up past orders to edit them, process refunds or print receipts.
      </Message>
    </Initial>
  ),
  { displayName: "Initial" }
);
