import { css, cx } from "emotion";
import PropTypes from "prop-types";
import { includes } from "ramda";
import React from "react";
import { Link } from "react-router-dom";
import RefundModal from "./RefundModal";
import SuccessModal from "./SuccessModal";
import {
  Loading,
  DropdownMenu,
  Receipt as OrderReceipt,
} from "../../../components";

const style = getStyle();
const print = () => window.print();

const optionRenderer = ({ label, onClick }) => (
  <button onClick={onClick}>{label}</button>
);

optionRenderer.propTypes = {
  label: PropTypes.any,
  onClick: PropTypes.any,
};

const DropdownLabel = ({ selectedItems, totalItems }) => {
  const totalSelectedItems = selectedItems.length;

  if (totalSelectedItems > 0 && totalSelectedItems === totalItems) {
    return <div>Full Refund ({selectedItems.length})</div>;
  }

  if (totalSelectedItems) {
    return <div>Partial Refund ({selectedItems.length})</div>;
  }

  return <div>Print Receipt</div>;
};

DropdownLabel.propTypes = {
  selectedItems: PropTypes.any,
  totalItems: PropTypes.any,
};

const View = (props) => {
  const { selectedItems = [], itemIds = [] } = props;

  return (
    <div className={cx(props.className, style.main, style.print)}>
      <RefundModal {...props.refund} selectedItems={selectedItems} />
      <SuccessModal {...props.refund} />
      <div className="order-receipt-header">
        <Link
          to={`/${props.countyId}/dashboard`}
          className="order-receipt-header--title"
        >
          ← Back to Dashboard
        </Link>
        <DropdownMenu
          className="receipt-dropdown"
          label={
            <DropdownLabel
              selectedItems={selectedItems}
              totalItems={itemIds.length}
            />
          }
          options={getOptions(props)}
          optionRenderer={optionRenderer}
          data-testid="selectionDropdown"
        />
      </div>
      {props.isLoading && <Loading />}
      {props.hasOrder && (
        <OrderReceipt
          className="order-receipt-table"
          {...props.order}
          nonInteractive={false}
        />
      )}
    </div>
  );
};

View.displayName = "Order View";

View.propTypes = {
  order: PropTypes.any,
  hasOrder: PropTypes.bool,
  isLoading: PropTypes.bool,
  countyId: PropTypes.any,
  className: PropTypes.any,
  selectedItems: PropTypes.array.isRequired,
  itemIds: PropTypes.array.isRequired,
  refund: PropTypes.shape({
    modal: PropTypes.shape({
      open: PropTypes.func.isRequired,
      close: PropTypes.func.isRequired,
      show: PropTypes.bool.isRequired,
    }).isRequired,
    order: PropTypes.object,
  }),
};

View.defaultProps = {
  selectedItems: [],
  itemIds: [],
};

export default View;

const RefundType = {
  partial: "PARTIAL_REFUND",
  full: "FULL_REFUND",
};

function getOptions(props) {
  if (!props.order) return [];

  const { selectedItems = [], itemIds = [], order, refundQuoteItems } = props;
  const numSelectedItems = selectedItems.length;
  const totalItems = itemIds.length;
  const canPartialRefund = numSelectedItems && numSelectedItems !== totalItems;

  const determineStatus = ({ refundStatus }) =>
    refundStatus === "none" || "completed-rejected";

  const canFullRefund =
    order.lineItems.every(determineStatus) && !canPartialRefund;

  const fetchRefundTotal = (type) => () => {
    const refundItems =
      type === RefundType.full
        ? refundQuoteItems
        : refundQuoteItems.filter((item) => includes(item.id, selectedItems));

    props.fetchRefundQuote({ orderId: order.orderId, refundItems });
    props.refund.modal.open();
  };

  const options = [
    {
      label: "Print Receipt",
      onClick: print,
      disabled: false,
    },
    {
      label: "Email Receipt",
      onClick: () => {},
      disabled: true,
    },
    {
      label: "Refund Order",
      onClick: fetchRefundTotal(RefundType.full),
      disabled: !canFullRefund,
    },
    {
      label: `Partial Refund (${numSelectedItems})`,
      onClick: fetchRefundTotal(RefundType.partial),
      disabled: !canPartialRefund,
    },
    {
      label: canPartialRefund
        ? `Download Documents (${numSelectedItems})`
        : "Download Document",
      onClick: () => {},
      disabled: true,
    },
  ];

  return options.filter(({ disabled }) => disabled === false);
}

function getStyle() {
  return {
    main: css`
      margin: 32px auto;
      max-width: 960px;
      padding: 0 16px;
      font-size: 14px;
    `,
    print: css`
      @media print {
        @page {
          margin: 0;
        }

        margin: 0;
        max-width: none;
        padding: 10px;
        width: 100%;
      }
    `,
  };
}
