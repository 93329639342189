import * as R from "ramda";

export const linkStates = {
  loading: {
    link: "",
    error: "",
    isLoading: true
  },
  pdfError: {
    link: "",
    error: "No PDF Found.",
    isLoading: false
  },
  zipError: {
    link: "",
    error: "No Zip Found.",
    isLoading: false
  }
};

const root = R.lensProp("order");

const order = R.compose(
  root,
  R.lensProp("order")
);

const items = R.compose(
  root,
  R.lensProp("items")
);

const loading = R.compose(
  root,
  R.lensProp("loading")
);

const zipLink = R.compose(
  root,
  R.lensProp("zipLink")
);

const pdfLinks = R.compose(
  root,
  R.lensProp("pdfLinks")
);

const error = R.compose(
  root,
  R.lensProp("error")
);

const selectedItems = R.compose(
  root,
  R.lensProp("selectedItems")
);

const refundSuccess = R.compose(
  root,
  R.lensProp("refundSuccess")
);

const refundQuote = R.compose(
  root,
  R.lensProp("refundQuote")
);

const byItemId = R.compose(
  order,
  R.lensPath(["refunds", "byItemId"])
);

const byRefundId = R.compose(
  order,
  R.lensPath(["refunds", "byRefundId"])
);

export default Object.assign(root, {
  order,
  items,
  loading,
  pdfLinks,
  error,
  zipLink,
  selectedItems,
  refundSuccess,
  refundQuote,
  byRefundId,
  byItemId
});
