import * as R from "ramda";

const root = R.lensProp("user");

export default Object.assign(root, {
  id: R.compose(
    root,
    R.lensProp("id")
  ),
  isLoggedIn: R.compose(
    root,
    R.lensProp("isLoggedIn")
  ),
  userName: R.compose(
    root,
    R.lensProp("userName")
  ),
  createdUser: R.compose(
    root,
    R.lensProp("createdUser")
  ), // <-- move to users
  updatedUser: R.compose(
    root,
    R.lensProp("updatedUser")
  ), // <-- move to users
  loading: R.compose(
    root,
    R.lensProp("loading")
  ),
  jwt: R.compose(
    root,
    R.lensProp("jwt")
  ),
  error: R.compose(
    root,
    R.lensProp("error")
  )
});
