import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'
import collapseTransforms from '../collapseTransforms'

const Bar = props => e('rect', props)

const barMaker = preloadProps => props =>
  e(Bar, Object.assign({}, preloadProps, props))

const Plus = ({ className, __width, __height, __x, __y, __τ }) => {
  const thickness = __τ || BASE * 0.1
  const rx = thickness / 2
  const makeBar = barMaker({ x: 0, y: 0, rx })

  const bar1Transform = collapseTransforms([
    `translate(0 ${BASE / 2 - thickness / 2})`
  ])

  const bar2Transform = collapseTransforms([
    `translate(${BASE / 2 - thickness / 2} 0)`
  ])

  const bar1 = makeBar({
    key: 'bar1',
    transform: bar1Transform,
    width: BASE,
    height: thickness
  })
  const bar2 = makeBar({
    key: 'bar2',
    transform: bar2Transform,
    width: thickness,
    height: BASE
  })

  return e(
    SVG,
    { className, x: __x, y: __y, width: __width, height: __height },
    [bar1, bar2]
  )
}

Plus.displayName = 'Plus'

Plus.defaultProps = {
  __x: 0,
  __y: 0,
  __width: null,
  __height: null,
  __τ: null
}

Plus.propTypes = {
  className: PropTypes.string,
  __x: PropTypes.number,
  __y: PropTypes.number,
  __τ: PropTypes.number,
  __width: PropTypes.number,
  __height: PropTypes.number
}

export default Plus
