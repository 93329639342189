import PropTypes from 'prop-types'
import { SVG, e, BASE } from '../utils'

const Line = ({ size, smallScalar, bigW, x, y }) => {
  const small = size * smallScalar

  return e(SVG, { width: size, height: size, x, y }, [
    e('rect', {
      key: 'small',
      x: 0,
      y: 0,
      width: small,
      height: small,
      rx: 10
    }),
    e('rect', {
      key: 'big',
      x: small * 2,
      y: 0,
      width: bigW,
      height: small,
      rx: 10
    })
  ])
}

const List = ({ className }) => {
  const smallScalar = 0.1
  const yOffset = BASE / 4
  const lineOffset = BASE * smallScalar / 2

  return e(SVG, { className }, [
    e(Line, {
      key: 'one',
      smallScalar,
      size: BASE,
      bigW: BASE - smallScalar * BASE * 2,
      y: yOffset - lineOffset
    }),
    e(Line, {
      key: 'two',
      smallScalar,
      size: BASE,
      bigW: BASE - smallScalar * BASE * 3,
      y: yOffset * 2 - lineOffset
    }),
    e(Line, {
      key: 'three',
      smallScalar,
      size: BASE,
      bigW: BASE - smallScalar * BASE * 2,
      y: yOffset * 3 - lineOffset
    })
  ])
}

List.propTypes = {
  className: PropTypes.string
}

export default List
