import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'
import collapseTransforms from '../collapseTransforms'

const Bar = props => e('rect', props)

const Angle = ({ base, id, thicknessRatio }) => {
  const rx = base * thicknessRatio / 2
  const thickness = base * thicknessRatio
  const x = 0
  const y = 0

  return e('g', { id }, [
    e(Bar, { key: '1', x, y, width: base, height: thickness, rx }),
    e(Bar, { key: '2', x, y, width: thickness, height: base, rx })
  ])
}

const Crop = ({ className }) => {
  const childRatio = 0.8
  const thicknessRatio = 0.12
  const subBase = BASE * childRatio
  const thickness = subBase * thicknessRatio

  const angle1Transform = collapseTransforms([
    `translate(${BASE * (1 - childRatio)} ${BASE * childRatio})`,
    `rotate(-90)`
  ])

  const angle2Transform = collapseTransforms([
    `translate(${BASE * childRatio} ${BASE * (1 - childRatio)})`,
    `rotate(90)`
  ])

  const lonerTransform = collapseTransforms([
    `translate(${BASE * (1 - childRatio)} ${BASE * childRatio - thickness})`,
    `rotate(-45 ${thickness / 2} ${thickness / 2})`
  ])

  return e(SVG, { className }, [
    e(
      'g',
      { key: 'angle1', transform: angle1Transform },
      e(Angle, { base: subBase, thicknessRatio })
    ),
    e(
      'g',
      { key: 'angle2', transform: angle2Transform },
      e(Angle, { base: subBase, thicknessRatio })
    ),
    e(Bar, {
      key: 'loner',
      transform: lonerTransform,
      x: 0,
      y: 0,
      width: Math.sqrt(2) * subBase - thickness / 2,
      height: subBase * thicknessRatio,
      rx: subBase * thicknessRatio / 2
    })
  ])
}

Crop.displayName = 'Crop'

Crop.propTypes = {
  className: PropTypes.string
}

export default Crop
