import * as R from "ramda";
import lens from "../lenses/county";
import { default as countiesGetters } from "./counties";

export const currentCountyId = R.view(lens);

export default state => {
  const counties = countiesGetters.data(state);
  const countyId = currentCountyId(state);
  const countyConfig = {
    id: countyId,
    ...counties[countyId]
  };

  return counties[countyId] ? countyConfig : null;
};
