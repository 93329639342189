import React from "react";
import styled from "react-emotion";
import { connect } from "react-redux";
import { actions, getters } from "../../../../store";
import { Autocomplete } from "../../../../components";

const SearchComponent = styled("div")`
  padding: 20px;
`;

const mapStateToProps = state => ({
  matches: getters.users.namesAsOptions(state),
  isLoading: getters.users.loading(state)
});

const mapDispatchToProps = dispatch => ({
  search: term => dispatch(actions.users.autocomplete(term)),
  cancel: () => dispatch(actions.users.cancel()),
  load: () => dispatch(actions.users.loading())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(props => (
  <SearchComponent>
    <Autocomplete
      select={props.edit}
      load={props.load}
      cancel={props.cancel}
      search={props.search}
      isLoading={props.isLoading}
      noOptionsMessage={{ none: "No users found." }}
      options={props.matches}
      placeholder="Search for Users"
    />
  </SearchComponent>
));
