import React, { Component } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { Arrow as ArrowIcon } from "@kofile/icons";

const Arrow = ({ direction, hide }) => {
  if (hide) {
    return null;
  }

  const arrowUp = (
    <ArrowIcon
      id="arrow-icon-1"
      className="table__header-arrow "
      rotation={0}
    />
  );

  const arrowDown = (
    <ArrowIcon
      id="arrow-icon-2"
      className="table__header-arrow "
      rotation={180}
    />
  );

  return direction === "asc" ? arrowUp : arrowDown;
};

class Column extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortOrder: "asc"
    };

    this.isAscOrder = R.equals("asc");

    this.flipSortOrder = () =>
      this.setState({
        sortOrder: this.isAscOrder(this.state.sortOrder) ? "desc" : "asc"
      });

    this.handleSort = () => {
      if (this.props.sort) {
        this.props.sort({
          key: this.props.sortKey,
          order: this.state.sortOrder
        });
        this.flipSortOrder();
      }
    };
  }

  render() {
    const isBeingSorted = this.props.sortKey === this.props.currentSortKey;

    return (
      <th onClick={this.handleSort} className="table--header-cell">
        {this.props.children}
        <Arrow
          direction={this.state.sortOrder}
          hide={!isBeingSorted || !this.props.sortKey}
        />
      </th>
    );
  }
}

Column.propTypes = {
  sort: PropTypes.func,
  sortKey: PropTypes.string,
  currentSortKey: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Column;
