import * as R from "ramda";
import lens from "../lenses/users-activity";

export default {
  root: R.view(lens),
  data: R.view(lens.data),
  loading: R.view(lens.loading),
  tableView: state => {
    const data = R.defaultTo([], R.view(lens.data, state));

    const noData = R.isEmpty(data);

    if (noData) {
      return [];
    }

    const makeUserName = data => {
      data.map(i => {
        return (i.userName = i.firstName + " " + i.lastName);
      });
      return data;
    };

    const formattedResult = R.compose(
      R.map(R.omit(["firstName", "lastName"])),
      makeUserName
    )(data);

    const columnOrder = ["userName", "userId", "date", "searches", "orders"];

    const mappedData = formattedResult.map(i => {
      const getDataAsArray = R.compose(
        R.values,
        R.pickAll(columnOrder)
      );
      return R.concat([], getDataAsArray(i));
    });

    const returnData = R.groupWith(
      (a, b) => R.head(a) === R.head(b),
      mappedData
    );

    return returnData;
  }
};
