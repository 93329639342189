import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { actions, getters } from "../../../store";

export const tableConfig = (props) => ({
  cellOptions: {
    on: {},
  },
  headerOptions: {
    on: {},
  },
  rowOptions: {
    on: {},
  },
  data: [
    {
      order: 0,
      name: "orderId",
      cellOptions: {
        on: {},
        valueTransform: (orderId) => {
          const isTotalCell = String(orderId).toUpperCase().includes("TOTAL");

          if (!isTotalCell) {
            return (
              <Link to={`/${props.match.params.county}/receipts/${orderId}`}>
                {orderId}
              </Link>
            );
          }

          return orderId;
        },
      },
      headerOptions: {
        valueTransform: Object.assign(() => <h2>ORDER ID</h2>, {
          displayName: "Order Id",
        }),
      },
    },
    {
      order: 1,
      name: "amount",
      headerOptions: {
        valueTransform: Object.assign(() => <h2>REFUND</h2>, {
          displayName: "Amount",
        }),
      },
    },
    {
      order: 2,
      name: "date",
      headerOptions: {
        valueTransform: Object.assign(() => <h2>DATE</h2>, {
          displayName: "Date",
        }),
      },
    },
    {
      order: 3,
      name: "userName",
      headerOptions: {
        valueTransform: Object.assign(() => <h2>NAME</h2>, {
          displayName: "User Name",
        }),
      },
    },
    {
      order: 4,
      name: "processedBy",
      headerOptions: {
        valueTransform: Object.assign(() => <h2>PROCESSED BY</h2>, {
          displayName: "Processed By",
        }),
      },
    },
    {
      order: 5,
      name: "status",
      headerOptions: {
        valueTransform: Object.assign(() => <h2>STATUS</h2>, {
          displayName: "Status",
        }),
      },
    },
    {
      order: 6,
      name: "reason",
      headerOptions: {
        valueTransform: Object.assign(() => <h2>REASON</h2>, {
          displayName: "Reason",
        }),
      },
    },
  ],
});

const stripDashes = (date) => date.replace(/-/g, "");

const transformSearchData = ({ from, to }) => ({
  from: stripDashes(from),
  to: stripDashes(to),
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: (searchData) =>
    dispatch(actions.refundsReport.fetch(transformSearchData(searchData))),
});

const mapStateToProps = (state) => ({
  data: getters.refundsReport.tableView(state),
  isLoading: getters.refundsReport.loading(state),
  county: getters.county(state),
});

export default R.compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);
