import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Arrow } from "@kofile/icons";
import { Link, withRouter } from "react-router-dom";

export const ReturnButton = ({ match, className }) => (
  <Link
    to={`/${match.params.county}/dashboard`}
    className={classnames("return-button", className)}
  >
    <Arrow className="return-button__arrow" /> Return to Dashboard
  </Link>
);

ReturnButton.displayName = "ReturnButton";

ReturnButton.propTypes = {
  className: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      county: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default withRouter(ReturnButton);
