import PropTypes from 'prop-types'
import { e, SVG } from '../utils'
import Triangle from './Triangle'

const TriangleReverse = ({ className }) =>
  e(SVG, { className }, e(Triangle, { rotation: 180 }))

TriangleReverse.displayName = 'TriangleReverse'

TriangleReverse.propTypes = {
  className: PropTypes.string
}

export default TriangleReverse
