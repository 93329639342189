import * as R from "ramda";
import { set } from "@kofile/redux-lenses";
import { types } from "../actions/errors";
import { errors as lenses } from "../lenses";
import { types as syncTypes } from "@kofile/ko-search-action-types";

export default {
  [types.REDUCER_ERROR]: [
    set(lenses).with(R.path(["payload", "previousState"]))
  ],
  [syncTypes.API_ERROR_0]: [set(lenses).with(R.prop("payload"))]
};
