import PropTypes from 'prop-types'
import { SVG, e } from '../utils'
import Resize from './Resize'

const ResizeVertical = ({ className }) =>
  e(SVG, { className }, e(Resize, { rotation: 90 }))

ResizeVertical.propTypes = {
  className: PropTypes.string
}

export default ResizeVertical
