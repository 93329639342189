import { push } from "connected-react-router";
import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "../../../store";
import { getCountyId } from "../../../utils";
import View from "./View";

const INITIAL = "initial";
const LOADING = "loading";
const NO_RESULTS = "noResults";
const RESULTS = "results";

const lens = {
  screen: {
    state: R.lensPath(["screen", "state"]),
  },
};

const update = {
  screen: {
    state: { to: R.set(lens.screen.state) },
  },
};

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: { state: INITIAL },
      userName: "",
    };

    this.countyId = getCountyId(this.props);

    this.onFindByUser = (userName) => {
      this.props.fetch({ userName, limit: 20 });
      this.setState(update.screen.state.to(LOADING));
    };

    this.onFindByOrder = ({ orderId }) => {
      this.props.reset();
      this.setState(update.screen.state.to(LOADING));
      this.props.goToReceipt(this.countyId)(orderId)();
    };
  }

  componentWillReceiveProps(nextProps) {
    if (getCountyId(this.props) !== getCountyId(nextProps)) {
      this.props.reset();
      this.setState(update.screen.state.to(INITIAL));
    } else {
      const orders = nextProps.orders;
      const noError = R.isNil(orders.error);
      const noResults =
        (orders.pagination && orders.pagination.total === 0) || !noError;

      if (orders.records.length && noError) {
        this.setState(update.screen.state.to(RESULTS));
      } else if (noResults) {
        this.setState(update.screen.state.to(NO_RESULTS));
      }
    }
  }

  render() {
    const props = {
      screen: {
        loading: this.state.screen.state === LOADING,
        results: this.state.screen.state === RESULTS,
        noResults: this.state.screen.state === NO_RESULTS,
        initial: this.state.screen.state === INITIAL,
      },
      className: this.props.className,
      onFindByUser: this.onFindByUser,
      onFindByOrder: this.onFindByOrder,
      records: this.props.orders.sorted,
      sort: this.props.setSort,
      goToReceipt: this.props.goToReceipt(this.countyId),
      goToPage: this.props.goToPage,
      pagination: this.props.orders.pagination,
      userName: this.state.userName,
      currentSortKey: this.props.orders.sortKey,
    };

    return <View {...props} />;
  }
}

Search.propTypes = {
  className: PropTypes.string,
  goToPage: PropTypes.func,
  goToReceipt: PropTypes.func,
  fetch: PropTypes.func,
  fetchById: PropTypes.func,
  reset: PropTypes.func,
  setSort: PropTypes.func,
  orders: PropTypes.shape({
    records: PropTypes.arrayOf(PropTypes.object.isRequired),
    sorted: PropTypes.arrayOf(PropTypes.object),
    sortKey: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    pagination: PropTypes.shape({
      page: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      size: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  county: getters.county(state),
  orders: {
    records: getters.orders.data(state),
    loading: getters.orders.loading(state),
    pagination: getters.orders.pagination(state),
    sorted: getters.orders.sorted(state),
    sortKey: getters.orders.sortKey(state),
    error: getters.orders.error(state),
  },
});

const mapDispatchToProps = (dispatch) => ({
  goToPage: (pageNo) => dispatch(push(`?page=${pageNo}`)),
  goToReceipt: (countyId) => (receiptId) => () =>
    dispatch(push(`/${countyId}/receipts/${receiptId}`)),
  fetch: (data) => dispatch(actions.orders.fetch(data)),
  fetchById: (data) => dispatch(actions.orders.fetchById(data)),
  reset: () => dispatch(actions.orders.reset()),
  setSort: (sortInfo) => dispatch(actions.orders.setSort(sortInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
