import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'
import Triangle from './Triangle'

const thickness = 60

const JumpTriangle = ({ className, rotation }) =>
  e(
    SVG,
    { className },
    e('g', { transform: `rotate(${rotation} ${BASE / 2} ${BASE / 2})` }, [
      e(Triangle, { key: 'Triangle', BASE }),
      e('rect', {
        key: 'rect',
        x: BASE * 0.84 - thickness,
        y: 0,
        width: thickness,
        height: BASE,
        rx: thickness / 2
      })
    ])
  )

JumpTriangle.displayName = 'JumpTriangle'

JumpTriangle.propTypes = {
  className: PropTypes.string,
  rotation: PropTypes.oneOf([0, 180])
}

JumpTriangle.defaultProps = {
  rotation: 0
}

export default JumpTriangle
