import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { propSatisfies, equals } from "ramda";
import { Switch, Route } from "react-router-dom";
import { Loading } from "../../../components";
import { ReactReduxContext } from "react-redux";
import { actions, getters } from "../../../store";
import { Refund } from "./refund";
import { Reject } from "./reject";

const mkGetParam = match => name => match.params[name];

const RefundData = ({ match, history }) => {
  const getParam = mkGetParam(match);

  const {
    storeState: state,
    store: { dispatch }
  } = useContext(ReactReduxContext);

  const { county } = state;

  const useFetchARefund = propSatisfies(
    equals(true),
    "useFetchARefund",
    getters.configuration.flips(county, state)
  );

  const orderId = parseInt(getParam("orderId"));
  const refundId = getParam("refundId");
  const refundLoading = getters.refund.loading(state);
  const refundError = getters.refund.error(state);
  const completedBy = getters.user.id(state);
  const error = getters.refund.error(state);
  const orderIsLoading = getters.order.loading(state);
  const refundIsLoading = getters.refund.loading(state);

  const status = useFetchARefund
    ? getters.refund.refundStatus(state)
    : getters.order.refundStatus(state, refundId);

  const refund = useFetchARefund
    ? getters.refund.refundReceipt(state)
    : getters.order.refundReceipt(state, refundId);

  useEffect(() => {
    dispatch(
      useFetchARefund
        ? actions.refund.fetch({id: parseInt(refundId), orderId })
        : actions.order.fetch({ id: orderId })
    );
  }, [orderId, useFetchARefund]);

  useEffect(() => {
    if (refundLoading === false && refundError === undefined) {
      history.push(match.url.replace(/\/reject/, ""));
    }
  }, [refundLoading, refundError]);

  const approve = () =>
    dispatch(
      actions.refund.completeRefund({
        id: Number(refundId),
        completedBy
      })
    );

  const reject = reason =>
    dispatch(
      actions.refund.rejectRefund({
        id: refundId,
        reason,
        completedBy
      })
    );

  const complete = () => {
    if (status === "approved") {
      approve();
    }

    if (status === "rejected") {
      reject(refund.reason);
    }
  };

  if (orderIsLoading || refundIsLoading) {
    return <Loading />;
  }

  return (
    <Switch>
      <Route
        path={`${match.path}/reject`}
        render={() => (
          <Reject submit={reject} match={match} error={refundError} />
        )}
      />
      <Route
        render={() => (
          <Refund
            county={county}
            error={error}
            complete={complete}
            status={status}
            match={match}
            refund={refund}
          />
        )}
      />
    </Switch>
  );
};

RefundData.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any
};

export default RefundData;
