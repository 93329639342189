import React from "react";
import PropTypes from "prop-types";
import styled from "react-emotion";
import ErrorComponent from "./Error";
import Empty from "./Empty";

const EMPTY = "empty";
const ERROR = "error";
const NORMAL = "normal";

const Widget = styled("section")`
  border: 1px solid rgba(4, 29, 68, 0.15);
  background-color: #ffffff;
  border-radius: 4px;
  font-size: 15px;
`;

const Header = styled("div")`
  display: flex;
  background-color: rgba(4, 29, 68, 0.03);
  border-bottom: 2px solid #006dd5;
  padding: 10px;
  justify-content: space-between;
`;

const Title = styled("h1")`
  color: #041d44;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
`;

export default Object.assign(
  props => (
    <Widget className={props.className}>
      <Header>
        <Title>{props.title}</Title>
        {props.action}
      </Header>
      {props.children}
    </Widget>
  ),
  {
    Error: ErrorComponent,
    Empty,
    status: {
      ERROR: ERROR,
      EMPTY: EMPTY,
      NORMAL: NORMAL
    },
    displayName: "Widget",
    propTypes: {
      className: PropTypes.string,
      title: PropTypes.node.isRequired,
      action: PropTypes.node,
      status: PropTypes.oneOf([NORMAL, ERROR, EMPTY])
    },
    defaultProps: {
      status: NORMAL,
      action: null
    }
  }
);
