import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Radio = ({ label = "", id, checked, value, onChange }) => {
  const outerCircleClass = classnames("radio__outer-circle", {
    "radio__outer-circle--checked": checked
  });

  const innerCircleClass = classnames("radio__inner-circle", {
    "radio__inner-circle--checked": checked
  });

  return (
    <div className="radio__wrapper">
      <input
        className="radio__input"
        type="radio"
        id={id}
        checked={checked}
        onChange={onChange}
        value={value}
      />
      <label className="radio__label" htmlFor={id}>
        <div className={outerCircleClass} checked={checked}>
          <div className={innerCircleClass} checked={checked} />
        </div>
        <div className="radio__label-text">{label}</div>
      </label>
    </div>
  );
};

Radio.displayName = "Radio";

Radio.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default Radio;
