import { createAction } from "redux-actions";
import { makeActionTypes } from "../../utils";
/**
 * TYPES
 */
export const types = makeActionTypes("RECEIPTS", [
  "FETCH_RECEIPTS_FULFILLED",
  "FETCH_RECEIPTS_CANCELLED",
  "FETCH_RECEIPTS_REJECTED",
  "FETCH_RECEIPTS"
]);

/**
 * ACTIONS
 */
export const actions = {
  fetch: createAction(types.FETCH_RECEIPTS),
  cancel: createAction(types.FETCH_RECEIPTS_CANCELLED),
  fulfilled: createAction(types.FETCH_RECEIPTS_FULFILLED)
};

export default actions;
