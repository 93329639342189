import React from "react";
import { ReturnButton } from "../../../components";
import { Search } from "./components";

const UsersReport = () => (
  <div className="users-report">
    <ReturnButton />
    <Search />
  </div>
);

UsersReport.displayName = "UsersReport";

export default UsersReport;
