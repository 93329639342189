import * as R from "ramda";

const root = R.lensProp("orders");
const ids = R.compose(
  root,
  R.lensProp("ids")
);
const data = R.compose(
  root,
  R.lensProp("data")
);
const error = R.compose(
  root,
  R.lensProp("error")
);
const loading = R.compose(
  root,
  R.lensProp("loading")
);
const pagination = R.compose(
  root,
  R.lensProp("pagination")
);
const userTerm = R.compose(
  root,
  R.lensProp("userTerm")
);
const orderTerm = R.compose(
  root,
  R.lensProp("orderTerm")
);
const sortBy = R.compose(
  root,
  R.lensProp("sortBy")
);

export default Object.assign(root, {
  ids,
  data,
  error,
  loading,
  pagination,
  userTerm,
  orderTerm,
  sortBy
});
