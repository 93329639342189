import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions, getters } from "../../store";

const mapStateToProps = (state) => ({
  county: getters.county(state),
  counties: getters.counties.asArray(state),
  user: {
    name: getters.user.userName(state),
    isLoggedIn: getters.user.isLoggedIn(state),
  },
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(actions.user.logout()),
  goToCounty: (id) => dispatch(push(`/${id}/dashboard`)),
});

export default connect(mapStateToProps, mapDispatchToProps);
