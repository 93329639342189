import * as R from "ramda";
import { set } from "@kofile/redux-lenses";
import { types as syncTypes } from "@kofile/ko-search-action-types";
import { types } from "../actions/user";
import * as lenses from "../lenses";
import * as initialState from "../lenses/initialState";

const { user } = lenses;

export default {
  [syncTypes.SUPPORT_SIGN_IN_0]: [
    set(user.isLoggedIn).as(false),
    set(user.loading).as(true)
  ],

  [syncTypes.SUPPORT_SIGN_IN_FULFILLED_0]: [
    set(user.id).with(R.path(["payload", "userDetails", "id"])),
    set(user.userName).with(({ payload: { userDetails } }) => {
      const { firstName, lastName } = userDetails;

      return `${firstName} ${lastName}`;
    }),
    set(user.isLoggedIn).as(true),
    set(user.loading).as(false),
    set(user.jwt).with(
      ({
        payload: {
          userDetails: { jwt }
        }
      }) => jwt
    )
  ],

  [syncTypes.NON_INTERACTIVE_SIGN_IN_FULFILLED_2]: [
    set(user.id).with(({ payload }) => {
      if (payload.isAnonymous) {
        return initialState.userState.id;
      }

      return R.path(["userDetails", "id"], payload);
    }),
    set(user.userName).with(({ payload }) => {
      if (payload.isAnonymous) {
        return initialState.userState.userName;
      }

      const { firstName, lastName } = payload.userDetails;

      return `${firstName} ${lastName}`;
    }),
    set(user.isLoggedIn).with(
      R.compose(
        R.not,
        R.path(["payload", "isAnonymous"])
      )
    ),
    set(user.loading).as(false)
  ],

  [syncTypes.SUPPORT_SIGN_IN_REJECTED_0]: [
    set(user.isLoggedIn).as(false),
    set(user.loading).as(false),
    set(user.error).with(({ payload }) => R.head(payload.errors))
  ],

  [types.LOGOUT]: [
    set(user.id).as(""),
    set(user.userName).as(""),
    set(user.isLoggedIn).as(false),
    set(user.loading).as(false),
    set(user.jwt).as(null)
  ],

  [syncTypes.ADD_NEW_USER_0]: [set(user.loading).as(true)],

  [syncTypes.ADD_NEW_USER_FULFILLED_0]: [
    set(user.createdUser).as("success"),
    set(user.loading).as(false)
  ],

  [syncTypes.ADD_NEW_USER_REJECTED_0]: [
    set(user.createdUser).as("failure"),
    set(user.loading).as(false)
  ],

  [syncTypes.SUPPORT_UPDATE_USER_INFO_0]: [set(user.loading).as(true)],

  [syncTypes.SUPPORT_UPDATE_USER_INFO_FULFILLED_0]: [
    set(user.updatedUser).as("success"),
    set(user.loading).as(false)
  ],

  [syncTypes.SUPPORT_UPDATE_USER_INFO_REJECTED_0]: [
    set(user.error).with(({ payload }) => R.head(payload.errors)),
    set(user.updatedUser).as("failure")
  ],

  [types.CLEAN_CREATE]: [set(user.createdUser).as("")],

  [types.CLEAN_UPDATE]: [set(user.updatedUser).as("")],

  [types.CLEAR_ERROR]: [set(user.error).as("")],

  setJwt: [set(user.jwt).with(({ payload: jwt }) => jwt)]
};
