import PropTypes from 'prop-types'
import { e, SVG, toRadians } from '../utils'
import round from '../rounding'

const θ = 45
const α = toRadians(90 - θ)
const β = toRadians(θ)
const τ = 60 // thickness
const RX = τ / 2
const WIDTH = 500
const HEIGHT = WIDTH / (Math.tan(β) * 2) + Math.cos(α) * τ

const Chevron = ({ rotation, className }) => {
  const width = { 0: WIDTH, 90: HEIGHT, 180: WIDTH, 270: HEIGHT }[rotation]
  const height = { 0: HEIGHT, 90: WIDTH, 180: HEIGHT, 270: WIDTH }[rotation]
  const x = { 0: 0, 90: HEIGHT * -1, 180: WIDTH * -1, 270: 0 }[rotation]
  const y = { 0: 0, 90: 0, 180: HEIGHT * -1, 270: WIDTH * -1 }[rotation]
  const viewBox = `${x} ${y} ${width} ${height}`

  const d = [
    `M0 ${Math.cos(α) * τ}`,
    `L${Math.sin(α) * τ} 0`,
    `L${WIDTH / 2} ${HEIGHT - Math.sqrt(2) * τ}`,
    `L${WIDTH - Math.sin(α) * τ} 0`,
    `L${WIDTH} ${Math.cos(α) * τ}`,
    `L${WIDTH / 2} ${HEIGHT}`,
    'Z'
  ].join(' ')

  return e(
    SVG,
    { className, viewBox },
    e('path', { d: round(d, RX), transform: `rotate(${rotation})` })
  )
}

Chevron.displayName = 'Chevron'

Chevron.propTypes = {
  rotation: PropTypes.oneOf([0, 90, 180, 270]),
  className: PropTypes.string
}

Chevron.defaultProps = {
  rotation: 0
}

export default Chevron
