import React from "react";
import PropTypes from "prop-types";
import { Select } from "..";
import * as Rx from "rxjs/Rx";
import Option from "./Option";
import iconSearch from "../../../public/assets/img/icon_search.svg";

const SearchIcon = () => (
  <img src={iconSearch} alt="Search Icon" style={{ paddingRight: "5px" }} />
);

class Autocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: false,
      loading: false
    };

    const cancel = () => {
      this.setState({ valid: false, loading: false });
      props.cancel();
    };

    const changeStream = new Rx.Observable(observer => {
      this.onChange = ({ value }) => observer.next(value);
    }).share();

    const inputStream = new Rx.Observable(observer => {
      this.onInputChange = term => observer.next(term);

      return () => (this.onInputChange = null);
    })
      .do(() => this.setState({ loading: true }))
      .share();

    inputStream
      .debounceTime(500)
      .delay(250)
      .filter(term => term.length >= props.searchThreshold)
      .subscribe(term => {
        this.setState({ valid: true });
        props.search(term);
      });

    inputStream
      .filter(term => term.length < props.searchThreshold)
      .subscribe(cancel);

    changeStream.subscribe(props.select);

    this.onBlur = () => {
      if (this.state.loading) cancel();
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading === false) {
      this.setState({ loading: false });
    }
  }

  render() {
    const noMessageOptions = getNoOptionsMessage({
      ...Autocomplete.defaultProps.noOptionsMessage,
      ...this.props.noOptionsMessage
    });

    const options = this.props.options.map(opt => ({
      ...opt,
      label: `${opt.label} ${opt.infoLabel}`
    }));

    return (
      <Select
        onBlur={this.onBlur}
        onChange={this.onChange}
        onInputChange={this.onInputChange}
        isLoading={this.state.loading}
        noOptionsMessage={noMessageOptions}
        options={this.state.valid ? options : []}
        placeholder={this.props.placeholder}
        backspaceRemovesValue={false}
        value={this.props.selectedOption}
        components={{
          DropdownIndicator: SearchIcon,
          IndicatorSeparator: false,
          Option
        }}
      />
    );
  }
}

Autocomplete.propTypes = {
  selectedOption: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired
  ),
  isLoading: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  load: PropTypes.func.isRequired,
  noOptionsMessage: PropTypes.shape({
    none: PropTypes.string,
    tooShort: PropTypes.string,
    empty: PropTypes.string
  }),
  searchThreshold: PropTypes.number,
  placeholder: PropTypes.string
};

Autocomplete.defaultProps = {
  isLoading: false,
  searchThreshold: 2,
  noOptionsMessage: {
    none: "No results found.",
    empty: "Search term must be at least 2 characters.",
    tooShort: "Search term must be at least 2 characters."
  }
};

export default Autocomplete;

function getNoOptionsMessage(messages) {
  return ({ inputValue }) => {
    if (inputValue.length === 0) return messages.empty;
    if (inputValue.length >= 2) return messages.none;

    return messages.tooShort;
  };
}
