import { css } from "emotion";
import PropTypes from "prop-types";
import { propOr, complement, isNil } from "ramda";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Loading, Modal } from "../../../../components";
import { getters, actions } from "../../../../store";
import { formatPrice } from "../../../../utils/money";

const isNotNil = complement(isNil);

class RefundModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reason: "",
    };

    this.updateReason = (e) => this.setState({ reason: e.target.value });
  }

  componentWillUpdate(nextProps) {
    if (this.props.refundSuccess !== nextProps.refundSuccess) {
      this.props.modal.close();
      this.setState({ reason: "" });
    }
  }

  render() {
    const { reason } = this.state;
    const { refundQuote = {}, refundData, modal, selectedItems } = this.props;

    const evaluateQuote = (key) => propOr(null, key, refundQuote);
    const quote = evaluateQuote("amount");
    const quoteLoading = propOr(false, "loading", refundQuote);
    const quoteError = evaluateQuote("error");
    const hasError = isNotNil(quoteError);
    const refundQuoteMessage = hasError ? quoteError : formatPrice(quote);
    const style = getStyle(hasError);

    const requestRefund = () =>
      this.props.requestRefund({ selectedItems, reason });

    return (
      <Modal
        title={refundData.isPartialRefund ? "Partial Refund" : "Request Refund"}
        onCancel={modal.close}
        onSubmit={requestRefund}
        submitDisabled={!reason}
        close={modal.close}
        show={modal.show}
      >
        {quoteLoading && <Loading />}
        {!quoteLoading && (
          <Fragment>
            <p className={style.refundText} data-testid="documents">
              Documents: {refundData.totalDocuments}
            </p>
            <p className={style.refundText} data-testid="amount">
              Amount being refunded: <span>{refundQuoteMessage}</span>
            </p>
            <p className={style.refundReasonTitle}>Reason for Refund</p>
            <textarea
              className={style.refundReason}
              placeholder="Enter a brief description for the refund. (Required)"
              onChange={this.updateReason}
              value={reason}
              data-testid="reason"
            />
          </Fragment>
        )}
      </Modal>
    );
  }
}

RefundModal.displayName = "RefundModal";

RefundModal.propTypes = {
  refundSuccess: PropTypes.any,
  selectedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  modal: PropTypes.shape({
    close: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
  }),
  refundData: PropTypes.shape({
    totalDocuments: PropTypes.number.isRequired,
    isPartialRefund: PropTypes.bool.isRequired,
  }),
  requestRefund: PropTypes.func.isRequired,
};

RefundModal.defaultProps = {
  selectedItems: [],
};

const mapStateToProps = (state, { selectedItems }) => ({
  refundData: getters.order.refundModal(state, selectedItems),
  refundSuccess: getters.order.refundSuccess(state),
  refundQuote: getters.order.refundQuote(state),
});

const mapDispatchToProps = (dispatch) => ({
  requestRefund: ({ selectedItems, reason }) =>
    dispatch(actions.order.requestRefund({ selectedItems, reason })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundModal);

const quoteErrorStyle = `
  color: #FC0100;
  font-weight: 400;
`;

const getStyle = (quoteError = false) => ({
  refundText: css`
    color: #041d44;
    font-size: 15px;
    font-weight: 600;
    margin-top: 5px;

    & span {
      ${quoteError && quoteErrorStyle}
    }
  `,
  refundReasonTitle: css`
    color: rgba(4, 29, 68, 0.6);
    font-size: 15px;
    font-weight: 600;
    padding: 15px 0;
  `,
  refundReason: css`
    border-radius: 4px;
    border: 1px solid rgba(4, 29, 68, 0.1);
    color: #14315d;
    font-family: "Source Sans Pro";
    font-size: 15px;
    height: 150px;
    resize: none;
    padding: 15px;
    width: 100%;
  `,
});
