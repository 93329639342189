import React from "react";
import PropTypes from "prop-types";
import styled from "react-emotion";

const LIGHT_GRAY = "rgba(4,29,68,0.15)";
const GRAY = "rgb(102,102,102)";
const LEFT = "left";
const RIGHT = "right";

const Input = styled("input")`
  ${getBorder} background-color: #ffffff;
  padding: ${getPadding};
  border-radius: ${getBorderRadius};
  font-size: 15px;
  color: #14315d;

  &:disabled {
    opacity: 0.5;
  }

  &::placeholder {
    color: ${GRAY};
  }
`;

export default Object.assign(props => <Input {...props} />, {
  joins: { LEFT, RIGHT },
  displayName: "TextInput",
  propTypes: {
    className: PropTypes.string,
    join: PropTypes.oneOf([LEFT, RIGHT])
  },
  defaultProps: {
    type: "text",
    join: null
  }
});

function getBorderRadius({ join }) {
  if (join && join === LEFT) return "0 4px 4px 0";
  if (join && join === RIGHT) return "4px 0 0 4px";

  return "4px";
}

function getBorder({ join }) {
  const border = `border: 1px solid ${LIGHT_GRAY};`;

  if (join && join === LEFT) {
    return `
      ${border}
      border-left: 0;
    `;
  }

  if (join && join === RIGHT) {
    return `
      ${border}
      border-right: 0;
    `;
  }

  return border;
}

function getPadding({ padding }) {
  if (padding) return padding;

  return "8px 10px";
}
