import React from "react";
import PropTypes from "prop-types";
import { Report, GroupedTable } from "../../../components";
import connect, { tableConfig } from "./connect";

const reportType = "History by Day";
const searchTitle = "History by Day";
const searchDescription =
  "Look up past search and download history by entering a start date and end date.";

export const HistoryByDay = props => (
  <Report
    widgetTitle={searchTitle}
    searchTitle={searchTitle}
    searchDescription={searchDescription}
    Table={<GroupedTable data={props.data} config={tableConfig} />}
    reportType={reportType}
    {...props}
  />
);

HistoryByDay.displayName = "HistoryByDay";
HistoryByDay.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array)
};

export default connect(HistoryByDay);
