import * as R from "ramda";
import lens from "../lenses/configuration";

const root = R.view(lens);

export default {
  root,
  loading: R.curry((code, state) => R.view(lens.loading(code), state)),
  tenantConfiguration: R.curry((code, state) =>
    R.view(lens.tenantConfiguration(code), state)
  ),
  flips: R.curry((code, state) => {
    const flips = R.view(lens.flips(code), state);

    return flips || {};
  }),
  tenantMetadata: R.curry((code, state) =>
    R.view(lens.tenantMetadata(code), state)
  ),
  clerkName: R.curry((code, state) => {
    const tenantMetadata = R.view(lens.tenantMetadata(code), state);

    if (R.isNil(tenantMetadata)) {
      return "";
    }

    const { clerkFirstName = "", clerkLastName = "" } = tenantMetadata;

    return `${clerkFirstName} ${clerkLastName}`;
  }),
  tenantName: R.curry((code, state) => R.view(lens.tenantName(code), state)),
  tenantOfficials: R.curry((code, state) =>
    R.view(lens.tenantOfficials(code), state)
  ),
  tenantLogoUrl: R.curry((code, state) =>
    R.view(lens.tenantLogoUrl(code), state)
  ),
  accountTypesAsOptions: () => [
    {
      value: "public",
      label: "Public (Pay per Use)",
      code: 1,
    },
    {
      value: "support",
      label: "Support",
      code: 2,
    },
    {
      value: "clerk",
      label: "Admin",
      code: 3,
    },
  ],
};
