import React, { PureComponent } from "react";
import * as R from "ramda";
import PropTypes from "prop-types";
import classnames from "classnames";
import Autocomplete from "react-autocomplete";
import iconClearSearch from "../../../public/assets/img/icon_clear_search.svg";

class SuggestionsInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { value: "" };

    this.handleChange = event => {
      const currentValue = event.target.value;
      this.setState({
        value: currentValue
      });

      this.props.onChange(currentValue);
    };

    this.handleClear = () =>
      this.setState({
        value: ""
      });
  }

  render() {
    const { props, state } = this;
    const { suggestions, getItemValue, onSelect, clearIcon } = props;
    const { value } = state;
    const wrapperStyle = {};

    const getItemClassName = isHighlighted =>
      classnames("suggestions-input--item", {
        highlight: isHighlighted
      });

    const renderInput = props => (
      <div className="suggestions-input">
        <input {...props} className="suggestions-input--field" />
        {!R.isEmpty(value) && (
          <button
            className="suggestions-input--clear"
            type="button"
            onClick={this.handleClear}
          >
            <img src={clearIcon} alt="clean icon" />
          </button>
        )}
      </div>
    );

    const renderItem = (item, isHighlighted) => (
      <div key={item.id} className={getItemClassName(isHighlighted)}>
        {item.firstName} {item.lastName}
      </div>
    );

    const renderMenu = (items, value, style) => (
      <div style={{ ...style }} className="suggestions-input--menu">
        {items}
      </div>
    );

    return (
      <Autocomplete
        getItemValue={getItemValue}
        items={suggestions}
        renderItem={renderItem}
        renderInput={renderInput}
        renderMenu={renderMenu}
        value={value}
        onChange={this.handleChange}
        onSelect={onSelect}
        wrapperStyle={wrapperStyle}
      />
    );
  }
}

SuggestionsInput.displayName = "SuggestionsInput";
SuggestionsInput.propTypes = {
  suggestions: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  getItemValue: PropTypes.func,
  clearIcon: PropTypes.string
};

SuggestionsInput.defaultProps = {
  clearIcon: iconClearSearch
};

export default SuggestionsInput;
