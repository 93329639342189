import PropTypes from 'prop-types'
import { SVG, e } from '../utils'

const smallCircleR = 15

const DiptychOfCircles = ({ x, y, opacity = '1' }) =>
  e(SVG, { x, y }, [
    e('circle', {
      key: 'left',
      cx: smallCircleR,
      cy: smallCircleR,
      r: smallCircleR,
      opacity
    }),
    e('circle', {
      key: 'right',
      cx: smallCircleR * 5,
      cy: smallCircleR,
      r: smallCircleR,
      opacity
    })
  ])

const TriptychOfCircles = ({ x, y, opacity = '1' }) =>
  e(SVG, { x, y }, [
    e('circle', {
      key: 'left',
      cx: smallCircleR,
      cy: smallCircleR,
      r: smallCircleR,
      opacity
    }),
    e('circle', {
      key: 'center',
      cx: smallCircleR * 5,
      cy: smallCircleR,
      r: smallCircleR,
      opacity
    }),
    e('circle', {
      key: 'right',
      cx: smallCircleR * 9,
      cy: smallCircleR,
      r: smallCircleR,
      opacity
    })
  ])

const Cart = ({ className }) => {
  const base = 500
  const viewBox = `0 0 ${base} ${base}`
  const thickness = 45
  const rx = thickness / 2
  const opacity = '0.6'

  return e(SVG, { viewBox, className }, [
    e('rect', {
      key: 'handle',
      x: 0,
      y: 80,
      width: 120,
      height: thickness,
      rx
    }),
    e('rect', {
      key: 'back cart',
      x: 85,
      y: 80,
      width: 270,
      height: thickness,
      rx,
      transform: `rotate(70 ${85 + rx} ${80 + rx})`
    }),
    e('rect', {
      key: 'cart bottom',
      x: 166,
      y: 301,
      width: 250,
      height: thickness,
      rx
    }),
    e('rect', {
      key: 'cart right',
      x: 381,
      y: 301,
      width: 220,
      height: thickness,
      rx,
      transform: `rotate(-70 ${381 + rx} ${301 + rx})`
    }),
    e('circle', { key: 'left wheel', cx: 220, cy: 410, r: 40 }),
    e('circle', { key: 'right wheel', cx: 360, cy: 410, r: 40 }),
    e('g', { key: 'mesh', transform: `` }, [
      e(TriptychOfCircles, { key: 'top', x: 210, y: 120, opacity: '0.6' }),
      e(TriptychOfCircles, {
        key: 'upper middle',
        x: 240,
        y: 150,
        opacity
      }),
      e(TriptychOfCircles, {
        key: 'lower middle',
        x: 210,
        y: 180,
        opacity
      }),
      e(DiptychOfCircles, { key: 'bottom', x: 240, y: 210, opacity })
    ])
  ])
}

Cart.propTypes = {
  className: PropTypes.string
}

export default Cart
