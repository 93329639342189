import React from "react";
import PropTypes from "prop-types";
import { A11yTable } from "@kofile/react-a11y-table";
import { Widget, DateRange } from "../../../../../components";
import { noop } from "../../../../../utils";

const config = {
  // options for all cells
  cellOptions: {
    on: {},
    valueTransform: () => "I am a cell transformed!"
  },
  headerOptions: {
    on: {},
    valueTransform: () => "I am a header transformed!"
  },
  // options for all rows
  rowOptions: {
    on: {}
  },
  data: [
    // config per column
    {
      order: 0, // the index of this column
      name: "id"
    },
    {
      order: 1,
      name: "name",
      cellOptions: {
        on: {}
      }
    }
  ]
};

const handleRangePicked = noop;

const searchTitle = (
  <div className="users-report--results-title">
    <h2>Activity By User</h2>
    <DateRange handleRangePicked={handleRangePicked} />
  </div>
);

const SearchResults = props => {
  const { data } = props;

  return (
    <Widget title={searchTitle} className="users-report--results">
      <div className="users-report--results-content">
        <A11yTable data={data} config={config} />
      </div>
    </Widget>
  );
};

SearchResults.displayName = "SearchResults";
SearchResults.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array)
};

SearchResults.defaultProps = {
  data: [[1, 2], [1, 2]]
};

export default SearchResults;
