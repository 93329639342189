import React from "react";
import { formatPrice } from "../../../utils/money";
import { formatDate } from "../../../utils";
import { withRouter } from "react-router-dom";
import { DropdownMenu } from "../../../components";
import iconEllipsis from "../../../../public/assets/img/icon_ellipsis.svg";
import PropTypes from "prop-types";

const Results = props => {
  const { county } = props.match.params;

  const dropdownOptions = ({ id, orderId }) => [
    {
      label: "View Refund",
      onClick: props.goToRefund({ refundId: id, orderId, county })
    }
  ];

  const optionRenderer = ({ label, onClick }) => (
    <button onClick={onClick}>{label}</button>
  );

  optionRenderer.propTypes = {
    label: PropTypes.any,
    onClick: PropTypes.any
  };

  return (
    <div className={props.className}>
      <table className="table">
        <thead className="table--header">
          <tr className="table--header-row">
            <th />
            <th className="table--header-cell">Date</th>
            <th className="table--header-cell">Order ID</th>
            <th className="table--header-cell">Customer</th>
            <th className="table--header-cell"># Documents</th>
            <th className="table--header-cell">Refund Amount</th>
            <th className="table--header-cell">Status</th>
          </tr>
        </thead>
        <tbody>
          {props.records.map((record, i) => (
            <tr
              key={i}
              className="table--body-row__clickable"
              onClick={props.goToRefund({
                refundId: record.id,
                orderId: record.orderId,
                county
              })}
            >
              <td
                className="table--body-cell"
                onClick={e => e.stopPropagation()}
              >
                <DropdownMenu
                  className="table-dropdown"
                  label={<img alt="dropdown" src={iconEllipsis} />}
                  options={dropdownOptions(record)}
                  optionRenderer={optionRenderer}
                />
              </td>
              <td className="table--body-cell">
                {formatDate(record.createdOn)}
              </td>
              <td className="table--body-cell" data-testid="orderNumber">
                {record.orderNumber}
              </td>
              <td className="table--body-cell">{record.customerFullName}</td>
              <td className="table--body-cell">{record.totalDocuments}</td>
              <td className="table--body-cell">{formatPrice(record.amount)}</td>
              <td className="table--body-cell">{record.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Results.propTypes = {
  records: PropTypes.any,
  className: PropTypes.string,
  goToRefund: PropTypes.any,
  match: PropTypes.any
};

export default withRouter(Results);
