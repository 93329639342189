import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import createStore from "./store";
import { ConnectedRouter } from "connected-react-router";
import { types } from "@kofile/ko-search-action-types";
import { App } from "./App";
import ErrorBoundary from "./ErrorBoundary";

let savedJwt;

try {
  savedJwt = sessionStorage.getItem("jwt");
} catch (_) {
  // ignore
}

const { store, history } = createStore();

store.subscribe(() => {
  const { jwt } = store.getState().user;

  if (jwt) {
    try {
      sessionStorage.setItem("jwt", jwt);
    } catch (_) {
      // ignore
    }
  }
});

store.dispatch({ type: "setJwt", payload: savedJwt });
store.dispatch({ type: types.SUPPORT_NON_INTERACTIVE_SIGN_IN_0 });
store.dispatch({ type: types.FETCH_SUPPORT_TENANTS_0 });

function renderApp() {
  const Application = () => (
    <ErrorBoundary>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </ErrorBoundary>
  );

  Application.displayName = "Application";

  render(<Application />, document.getElementById("mount"));
}

renderApp();

if (module.hot) {
  module.hot.accept(renderApp);
}
