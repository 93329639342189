import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { actions, getters } from "../../../store";

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(actions.importReport.fetch()),
  initiate: () => dispatch(actions.importReport.initiate()),
});

const mapStateToProps = (state) => ({
  data: getters.importReport.data(state),
  hasInitiated: getters.importReport.hasInitiated(state),
  isLoading: getters.importReport.loading(state),
});

export default R.compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);
