import * as R from "ramda";
import lens from "../lenses/router";
import qs from "qs";

export default {
  root: R.view(lens),
  path: R.view(lens.path),
  search: R.view(lens.search),
  page: state => {
    const search = R.view(lens.search, state);
    const { page: parsedPage } = qs.parse(search.slice(1));

    if (parsedPage) return Number(parsedPage);

    return 1;
  }
};
