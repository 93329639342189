import { cx } from "emotion";
import React from "react";
import * as R from "ramda";
import PropTypes from "prop-types";
import { SearchReportsLabel, DateRange, RadioSet } from "../../../";

const Search = (props) => {
  const childProps = {
    form: {
      onSubmit: props.onSubmit,
    },
    SearchReportsLabel: {
      title: props.isLoading ? "Loading Results..." : props.searchTitle,
      description: props.searchDescription,
      isLoading: props.isLoading,
    },
    dateRange: {
      handleRangePicked: props.handleDatePicked,
    },
    submitButton: {
      disabled:
        props.isLoading ||
        (props.showDateSearch && !props.dateIsPicked) ||
        props.disabled,
    },
    radioSet: props.radioProps,
  };

  const radiosAvailable = R.not(
    R.isEmpty(R.path(["radioSet", "options"], childProps))
  );

  return (
    <form
      className={cx("users-report--search-content", props.className)}
      {...childProps.form}
    >
      <SearchReportsLabel {...childProps.SearchReportsLabel} />

      {props.showDateSearch && (
        <div className="users-report--search-date">
          <DateRange {...childProps.dateRange} />
        </div>
      )}

      {radiosAvailable && (
        <div className="users-report__radio-set">
          <RadioSet {...childProps.radioSet} />
        </div>
      )}

      <button className="users-report--search-btn" {...childProps.submitButton}>
        {props.buttonText ? props.buttonText : "Run Report"}
      </button>
    </form>
  );
};

Search.propTypes = {
  searchTitle: PropTypes.string,
  searchDescription: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  handleDatePicked: PropTypes.func.isRequired,
  dateIsPicked: PropTypes.bool,
  isLoading: PropTypes.bool,
  radioProps: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }),
};

Search.defaultProps = {
  dateIsPicked: false,
  isLoading: false,
  showDateSearch: true,
  handleDatePicked: () => {},
};

export default Search;
