import * as R from "ramda";

const root = R.lensProp("import-report");

const data = R.compose(root, R.lensProp("data"));

const hasInitiated = R.compose(root, R.lensProp("hasInitiated"));

const error = R.compose(root, R.lensProp("error"));

const loading = R.compose(root, R.lensProp("isLoading"));

export default Object.assign(root, {
  data,
  hasInitiated,
  error,
  loading,
});
