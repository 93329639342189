import { createAction } from "redux-actions";
import { types as syncTypes } from "@kofile/ko-search-action-types";

/**
 * ACTIONS
 */
export const actions = {
  fetch: params => {
    const actionCreator = createAction(syncTypes.FETCH_REFUNDS_1);

    if (!params) {
      return actionCreator({
        pagination: {
          limit: 20,
          offset: 0
        }
      });
    }

    return actionCreator(params);
  },
  cancel: createAction(syncTypes.FETCH_REFUNDS_CANCELLED_1),
  fulfilled: createAction(syncTypes.FETCH_REFUNDS_FULFILLED_1),
  rejected: createAction(syncTypes.FETCH_REFUNDS_REJECTED_1),
  update: createAction(syncTypes.UPDATE_REFUND_0),
  single: {
    fetch: createAction(syncTypes.FETCH_REFUND_0),
    cancel: createAction(syncTypes.FETCH_REFUND_CANCELLED_0),
    reject: createAction(syncTypes.FETCH_REFUND_REJECTED_0)
  }
};

export default actions;
