import React from "react";
import PropTypes from "prop-types";
import { Report } from "../../../components";
import A11yTable from "@kofile/react-a11y-table/A11yTable";
import connect, { tableConfig } from "./connect";

const reportType = "Refund";
const searchTitle = "Refund Activity";
const searchDescription =
  "Look up refund activity by entering a start date and end date.";

export const RefundsReport = props => (
  <Report
    widgetTitle={searchTitle}
    searchTitle={searchTitle}
    searchDescription={searchDescription}
    tableConfig={tableConfig(props)}
    reportType={reportType}
    Table={<A11yTable data={props.data} config={tableConfig(props)} />}
    {...props}
  />
);

RefundsReport.displayName = "RefundsReport";
RefundsReport.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array)
};

export default connect(RefundsReport);
