import { set } from "@kofile/redux-lenses";
import * as R from "ramda";
import * as utils from "../../utils";
import { newApiTypes, types } from "../actions/import-status-report";
import * as lenses from "../lenses";

const { importStatusReport } = lenses;

export default {
  [newApiTypes.fetch]: [set(importStatusReport.loading).as(true)],

  [newApiTypes.fulfilled]: [
    set(importStatusReport.data).with(R.path(["payload"])),
    set(importStatusReport.loading).as(false),
  ],

  [newApiTypes.rejectFetch]: [
    set(importStatusReport.error).with(utils.getReason),
    set(importStatusReport.loading).as(false),
  ],

  [newApiTypes.initiate]: [
    set(importStatusReport.hasInitiated).with(false),
    set(importStatusReport.loading).as(true),
  ],

  [newApiTypes.initiated]: [
    set(importStatusReport.hasInitiated).with(true),
    set(importStatusReport.loading).as(false),
  ],

  [newApiTypes.rejectInitiate]: [
    set(importStatusReport.hasInitiated).with(false),
    set(importStatusReport.error).with(utils.getReason),
    set(importStatusReport.loading).as(false),
  ],

  [types.RESET]: [set(importStatusReport).as({})],
};
