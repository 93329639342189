import { createAction } from "redux-actions";
import { types as syncTypes } from "@kofile/ko-search-action-types";

export const newApiTypes = {
  fetchRefund: "fetch-a-refund",
  refund: "refund",
  errorFetchingRefund: "error-fetching-a-refund"
};

export const actions = {
  completeRefund: createAction(syncTypes.PROCEED_REFUND_COMPLETE_0),
  rejectRefund: createAction(syncTypes.PROCEED_REFUND_REJECT_0),
  fetch: createAction(newApiTypes.fetchRefund)
};

export default actions;
