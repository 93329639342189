import React from "react";
import PropTypes from "prop-types";
import connect from "./connect";
import govOSLogo from "../../../public/assets/img/govOSDark.svg";

const CountyName = ({ name }) => `| ${name} County`;

export const Footer = ({ govOSLogo, county, currentYear }) => (
  <div className="site-footer">
    <div className="site-footer__copyright">
      © {currentYear} GovOS, inc. All Rights Reserved{" "}
      {county && CountyName(county)}
    </div>
    <div>
      Powered by
      <img src={govOSLogo} className="site-footer__icon" alt="GovOS Logo" />
    </div>
  </div>
);

Footer.displayName = "Footer";

Footer.propTypes = {
  currentYear: PropTypes.number,
  govOSLogo: PropTypes.string,
  county: PropTypes.object,
  stateName: PropTypes.string,
};

Footer.defaultProps = {
  govOSLogo: govOSLogo,
  currentYear: new Date().getFullYear(),
};

export default connect(Footer);
