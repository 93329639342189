import PropTypes from 'prop-types'
import { SVG, e } from '../utils'
import Resize from './Resize'

const Move = ({ className }) =>
  e(SVG, { className }, [
    e(Resize, { key: 'horizontal' }),
    e(Resize, { key: 'vertical', rotation: 90 })
  ])

Move.propTypes = {
  className: PropTypes.string
}

export default Move
