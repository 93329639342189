import React from "react";
import styled, { css } from "react-emotion";
import * as Widgets from "./widgets";
import Search from "./Search";

const Dashboard = styled("article")`
  padding: 20px 32px;
  display: flex;
  align-items: flex-start;
`;

const Sidebar = styled("div")`
  flex: 0 0 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const sidebarWidgetStyle = css`
  flex: 1;

  & + * {
    margin: 20px 0;
  }
`;

const searchStyle = css`
  flex: 1;
  margin-left: 20px;
  width: 100%;
`;

export default Object.assign(
  () => (
    <Dashboard>
      <Sidebar>
        <Widgets.Users className={sidebarWidgetStyle} />
        <Widgets.Refunds className={sidebarWidgetStyle} />
        <Widgets.Reports className={sidebarWidgetStyle} />
      </Sidebar>

      <Search className={searchStyle} />
    </Dashboard>
  ),
  { displayName: "Dashboard" }
);
