import React from "react";
import { BodyCell } from "../";
import PropTypes from "prop-types";

const BodyRow = ({ rowData, className }) => (
  <tr className={`grouped-table__body-row ${className}`}>
    {rowData.map((data, i) => (
      <BodyCell key={i} value={data} />
    ))}
  </tr>
);

BodyRow.displayName = "BodyRow";

BodyRow.propTypes = {
  rowData: PropTypes.any,
  className: PropTypes.string
};

export default BodyRow;
