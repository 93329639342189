import { createAction } from "redux-actions";
import { makeActionTypes } from "../../utils";
/**
 * TYPES
 */
export const types = makeActionTypes("BILLING_ACTIVITY", ["RESET"]);

export const newApiTypes = {
  fetch: "fetch-billing-report",
  reject: "error-fetching-billing-report",
  fulfilled: "billing-report",
};

/**
 * ACTIONS
 */
export const actions = {
  fetch: createAction(newApiTypes.fetch),
  reject: createAction(newApiTypes.reject),
  fulfilled: createAction(newApiTypes.fulfilled),
  reset: createAction(types.RESET),
};

export default actions;
