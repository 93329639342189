import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export const Reject = ({ submit, match, error }) => {
  const [reason, setReason] = useState("");

  const onSubmit = e => {
    e.preventDefault();

    submit(reason);
  };

  return (
    <div className="request-refund">
      <h2 className="request-refund--title">Reject Refund</h2>
      <textarea
        onChange={e => setReason(e.currentTarget.value)}
        value={reason}
        placeholder="Enter a brief description for rejection."
        maxLength="200"
      />
      {error && <p className="error">{error}</p>}
      <div className="request-refund--buttons">
        <Link to={match.url} className="request-refund--button-cancel">
          Cancel
        </Link>
        <button className="request-refund--button-submit" onClick={onSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

Reject.propTypes = {
  submit: PropTypes.func,
  match: PropTypes.any,
  error: PropTypes.any
};
