import { set } from "@kofile/redux-lenses";
import * as lenses from "../lenses";
import * as utils from "../../utils";
import { types as syncTypes } from "@kofile/ko-search-action-types";

const { refunds } = lenses;
const getRefundsIds = utils.getIDsBy("refunds");
const getRefundsData = utils.getDataBy("refunds");

export default {
  [syncTypes.FETCH_REFUNDS_1]: [
    set(refunds.error).as(null),
    set(refunds.isLoading).as(true),
    set(refunds.userNameQuery).with(({ payload }) => payload.userName),
    set(refunds.orderNumberQuery).with(({ payload }) => payload.orderNumber)
  ],

  [syncTypes.FETCH_REFUNDS_FULFILLED_1]: [
    set(refunds.error).as(null),
    set(refunds.ids).with(getRefundsIds),
    set(refunds.data).with(getRefundsData),
    set(refunds.total).with(({ payload }) => payload.refunds.length),
    set(refunds.isLoading).as(false),
    set(refunds.pagination).with(({ payload }) => {
      const { size, total } = payload.pagination;

      return {
        ...payload.pagination,
        totalPages: Math.ceil(total / size)
      };
    })
  ],

  [syncTypes.FETCH_REFUNDS_REJECTED_1]: [
    set(refunds.error).with(utils.getReason),
    set(refunds.isLoading).as(false)
  ],

  [syncTypes.FETCH_REFUNDS_CANCELLED_1]: [set(refunds.isLoading).as(false)]
};
