import React from "react";
import PropTypes from "prop-types";
import { DropdownMenu } from "../../../../components";
import Column from "./Column";
import iconEllipsis from "../../../../../public/assets/img/icon_ellipsis.svg";

const Results = props => {
  const dropdownOptions = id => [
    {
      label: "View Receipt",
      onClick: props.goToReceipt(id)
    },
    {
      label: "Print Receipt",
      onClick: () => {}
    }
  ];

  const optionRenderer = ({ label, onClick }) => (
    <button onClick={onClick}>{label}</button>
  );

  optionRenderer.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string
  };

  return (
    <section className={props.className}>
      <table className="table">
        <thead className="table--header">
          <tr className="table--header-row">
            <Column />
            <Column
              sortKey="createdOn"
              sort={props.sort}
              currentSortKey={props.currentSortKey}
            >
              Order Date
            </Column>
            <Column
              sortKey="orderNumber"
              sort={props.sort}
              currentSortKey={props.currentSortKey}
            >
              Order #
            </Column>
            <Column
              sortKey="userName"
              sort={props.sort}
              currentSortKey={props.currentSortKey}
            >
              Customer
            </Column>
            <Column
              sortKey="itemsNumber"
              sort={props.sort}
              currentSortKey={props.currentSortKey}
            >
              # Documents
            </Column>
            <Column
              sortKey="amount"
              sort={props.sort}
              currentSortKey={props.currentSortKey}
            >
              Price
            </Column>
          </tr>
        </thead>
        <tbody>
          {props.records.map(record => (
            <tr
              key={record.id}
              className="table--body-row__clickable"
              onClick={props.goToReceipt(record.id)}
            >
              <td
                className="table--body-cell"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <DropdownMenu
                  className="table-dropdown"
                  label={<img alt="dropdown" src={iconEllipsis} />}
                  options={dropdownOptions(record.id)}
                  optionRenderer={optionRenderer}
                />
              </td>
              <td className="table--body-cell" data-testid="resultOrderDate">
                {record.createdOn}
              </td>
              <td className="table--body-cell" data-testid="resultOrderNumber">
                {record.orderNumber}
              </td>
              <td className="table--body-cell" data-testid="resultCustomer">
                {record.userName}
              </td>
              <td className="table--body-cell" data-testid="resultDocsNumber">
                {record.itemsNumber}
              </td>
              <td className="table--body-cell" data-testid="resultPrice">
                {record.amount}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

Results.propTypes = {
  goToReceipt: PropTypes.func,
  className: PropTypes.string,
  currentSortKey: PropTypes.string,
  sort: PropTypes.func,
  records: PropTypes.array
};

export default Results;
