import PropTypes from 'prop-types'
import { SVG, e } from '../utils'

const Arrow = ({ rotation, className }) => {
  const base = 500
  const half = base / 2
  const viewBox = `0 0 ${base} ${base}`
  const thickness = base * 0.1
  const halfThick = thickness / 2

  return e(
    SVG,
    { viewBox, className },
    e('g', { transform: `rotate(${rotation} ${half} ${half})` }, [
      e('rect', {
        key: 'arrow',
        x: thickness,
        y: half - halfThick,
        width: base - thickness,
        height: thickness
      }),
      e(
        'g',
        { key: 'pointTransform', transform: `translate(0 ${half})` },
        e('g', { transform: 'rotate(-45)' }, [
          e('rect', {
            key: 'pointPart1',
            x: 0,
            y: 0,
            width: half + halfThick,
            height: thickness
          }),
          e('rect', {
            key: 'pointPart2',
            x: 0,
            y: 0,
            width: thickness,
            height: half + halfThick
          })
        ])
      )
    ])
  )
}

Arrow.propTypes = {
  rotation: PropTypes.oneOf([0, 180]).isRequired,
  className: PropTypes.string
}

Arrow.defaultProps = {
  rotation: 0
}

export default Arrow
