import PropTypes from 'prop-types'
import { e, toRadians, SVG, BASE } from '../utils'

const Α = toRadians(45)
const τ = 80
const height = Math.tan(Α) * BASE * (2 / 3) + τ / Math.sqrt(2)
const viewBox = `0 0 ${BASE} ${height}`

const d = [
  `M0 ${height - BASE / 3}`,
  `L${τ / Math.sqrt(2)} ${height - BASE / 3 - τ / Math.sqrt(2)}`,
  `L${BASE / 3} ${height - Math.sqrt(2) * τ}`,
  `L${BASE - τ / Math.sqrt(2)} 0`,
  `L${BASE} ${τ / Math.sqrt(2)}`,
  `L${BASE / 3} ${height}`,
  'Z'
].join(' ')

const CheckMark = ({ className, __x, __y, __width, __height, __fill }) =>
  e(
    SVG,
    { viewBox, className, x: __x, y: __y, width: __width, height: __height },
    e('path', { d, fill: __fill })
  )

CheckMark.displayName = 'CheckMark'

CheckMark.propTypes = {
  className: PropTypes.string,
  __width: PropTypes.number,
  __height: PropTypes.number,
  __x: PropTypes.number,
  __y: PropTypes.number,
  __fill: PropTypes.string
}

CheckMark.defaultProps = {
  __width: null,
  __height: null,
  __x: 0,
  __y: 0,
  __fill: null
}

export default CheckMark
