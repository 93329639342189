import React from "react";
import PropTypes from "prop-types";
import { Group, HeaderRow } from "./components";

class GroupedTable extends React.PureComponent {
  render() {
    const { data, config } = this.props;
    const { columns } = config;

    const groups = data.map((groupData, i) => (
      <Group key={i} groupData={groupData} />
    ));

    return (
      <table className="grouped-table">
        <thead className="grouped-table__thead">
          <HeaderRow columns={columns} />
        </thead>
        <tbody className="grouped-table__tbody">{groups}</tbody>
      </table>
    );
  }
}

GroupedTable.propTypes = {
  data: PropTypes.any,
  config: PropTypes.any
};

export default GroupedTable;
