import { types as syncTypes } from "@kofile/ko-search-action-types";
import uuid4 from "uuid/v4";

export const actions = {
  loading: () => ({ type: "users/loading" }),
  cancel: () => ({ type: "users/cancel" }),
  autocomplete: term => ({
    type: syncTypes.FETCH_USERS_1,
    payload: {
      userName: term,
      searchId: uuid4(),
      pageSize: 100
    }
  })
};

export default actions;
