import React, { Component } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { noop } from "../../utils";

class Date extends Component {
  render() {
    const {
      dateFormat,
      calendarClassName,
      dayClassName,
      popperPlacement
    } = this.props;

    return (
      <DatePicker
        dateFormat={dateFormat}
        selected={this.props.selected}
        onChange={this.props.handleDatePick}
        className="date"
        calendarClassName={calendarClassName}
        dayClassName={dayClassName}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        popperPlacement={popperPlacement}
      />
    );
  }
}

Date.displayName = "DatePicker";

Date.propTypes = {
  dateFormat: PropTypes.string,
  className: PropTypes.string,
  dayClassName: PropTypes.string,
  calendarClassName: PropTypes.string,
  handleDatePick: PropTypes.func,
  popperPlacement: PropTypes.any,
  selected: PropTypes.any
};

Date.defaultProps = {
  dateFormat: "MM/DD/YYYY",
  className: "",
  dayClassName: "",
  calendarClassName: "",
  handleDatePick: noop
};

export default Date;
