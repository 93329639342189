/**
 * Formats price
 *
 * @example
 *
 * 250 -> '$2.50'
 *
 * @param {Number} price - price in cents
 */

import { isNil } from "ramda";

export const formatPrice = price => {
  if (isNil(price)) return "";

  const newPrice = (price / 100).toFixed(2);

  return `$${newPrice}`;
};
