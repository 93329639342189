import React from "react";
import PropTypes from "prop-types";
import * as Icons from "@kofile/icons";

const Indicator = ({ isOpen }) => (
  <Icons.Triangle
    id={isOpen ? "triangle-icon-open" : "triangle-icon-closed"}
    className="indicator"
    rotation={isOpen ? 270 : 90}
    filled
  />
);

Indicator.propTypes = {
  isOpen: PropTypes.bool
};

const MenuControl = ({ isOpen, label }) => (
  <React.Fragment>
    {label}
    <Indicator isOpen={isOpen} />
    <div data-testid="selectionDropdownChild" />
  </React.Fragment>
);

MenuControl.displayName = "Menu Control";

MenuControl.propTypes = {
  isOpen: PropTypes.bool,
  label: PropTypes.node
};

MenuControl.defaultProps = {
  isOpen: false,
  label: ""
};

export default MenuControl;
