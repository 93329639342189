import * as R from "ramda";
import lens from "../lenses/user";

export default {
  root: R.view(lens),
  id: R.view(lens.id),
  isLoggedIn: R.view(lens.isLoggedIn),
  createdUser: R.view(lens.createdUser),
  loading: R.view(lens.loading),
  userName: R.view(lens.userName),
  updatedUser: R.view(lens.updatedUser),
  jwt: R.view(lens.jwt),
  error: R.view(lens.error)
};
