import * as R from "ramda";
import { set } from "@kofile/redux-lenses";
import * as lenses from "../lenses";
import * as utils from "../../utils";
import { types as syncTypes } from "@kofile/ko-search-action-types";

const { usersActivity } = lenses;

export default {
  [syncTypes.FETCH_USERS_ACTIVITY_0]: [set(usersActivity.loading).as(true)],

  [syncTypes.FETCH_USERS_ACTIVITY_FULFILLED_0]: [
    set(usersActivity.data).with(R.path(["payload", "data"])),
    set(usersActivity.loading).as(false)
  ],

  [syncTypes.FETCH_USERS_ACTIVITY_REJECTED_0]: [
    set(usersActivity.error).with(utils.getReason),
    set(usersActivity.loading).as(false)
  ],

  [syncTypes.FETCH_USERS_ACTIVITY_CANCELLED_0]: [
    set(usersActivity.error).with(utils.getReason),
    set(usersActivity.loading).as(false)
  ]
};
