import * as Rx from "rxjs/Rx";
import { LOCATION_CHANGE } from "connected-react-router";
import * as getters from "../../getters";
import * as actions from "../../actions";

export const tenantChange = (actionStream, store) =>
  actionStream.ofType(LOCATION_CHANGE).mergeMap(({ payload }) => {
    const state = store.getState();
    const existingCountyId = getters.county(state);

    const {
      location: { pathname },
    } = payload;

    const id = pathname.split("/")[1] || null;
    const hasTenantId = id !== null && id !== "signin";

    if (!hasTenantId) {
      return Rx.Observable.of(
        actions.county.set(null),
        actions.billingReport.reset()
      );
    } else if (hasTenantId && id !== existingCountyId) {
      return Rx.Observable.of(
        actions.county.set(id),
        actions.billingReport.reset()
      );
    }

    return Rx.Observable.empty();
  });

export default [tenantChange];
