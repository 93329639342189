import * as R from "ramda";
import { createStore, applyMiddleware, compose } from "redux";
import { createReducer } from "@kofile/redux-lenses";
import { createEpicMiddleware, combineEpics } from "redux-observable";
import { routerMiddleware, connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import epics from "./middleware/epics";
import { socketMiddleware } from "./middleware/socket";
import { errorsMiddleware, ReducerError } from "./middleware/errors";
import * as setters from "./setters";
import * as allGetters from "./getters";
import * as allActions from "./actions";
import * as initState from "./lenses/initialState";

export const actions = allActions;
export const getters = allGetters;

/**
 * The transformed updators of this project
 */
export const createActionHandlers = handlers =>
  R.values(handlers).reduce((fn, c) => {
    const actionNames = R.keys(c);

    actionNames.forEach(name => {
      if (R.has(name, fn)) {
        fn[name].push(...c[name]);
      } else {
        fn[name] = c[name];
      }
    });

    return fn;
  }, {});

const reducer = history => {
  const handlers = createActionHandlers(setters);
  const lensReducer = createReducer(handlers);
  const routerReducer = connectRouter(history);

  return (state, action) => {
    try {
      return lensReducer(
        { ...state, router: routerReducer(state.router, action) },
        action
      );
    } catch (e) {
      throw new ReducerError(e);
    }
  };
};

const initialState = {
  order: initState.orderState,
  orders: initState.ordersState,
  refunds: initState.defaultState,
  refund: initState.defaultState,
  receipts: initState.defaultState,
  socket: initState.socketState,
  users: initState.defaultState,
  errors: {},
  user: initState.userState,
  configuration: {},
  counties: initState.counties,
  county: null
};

export default state => {
  const history = createBrowserHistory();
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const flattenEpics = R.flatten(R.values(epics));
  const rootEpic = combineEpics(...flattenEpics);
  const epicMiddleware = createEpicMiddleware(rootEpic);
  const { hostname, port } = window.location;

  const store = createStore(
    reducer(history),
    Object.assign({}, initialState, state),
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        epicMiddleware,
        socketMiddleware({ socketUrl: `wss://${hostname}:${port}/ws` }),
        errorsMiddleware(window)
      )
    )
  );

  return { store, history };
};
