import PropTypes from "prop-types";
import { complement, isNil, pick, prop } from "ramda";
import React from "react";
import govOSLogo from "../../../public/assets/img/govOS.svg";
import connect from "./connect";

export const Header = ({ user, county, counties, logout, goToCounty }) => {
  const renderClerkName = user.isLoggedIn && county;
  const countyOptions = counties.map(pick(["id", "name"]));

  const clerkName = renderClerkName && (
    <InfoHeader title="Admin" name={county.clerkName} />
  );

  const authHeader = user.isLoggedIn && (
    <AuthHeader name={user.name} logout={logout} />
  );

  const createOptions = ({ name, id } = {}) => {
    const countyPresent = complement(isNil(id));
    const selected = prop("id", county) === id;

    return (
      <option
        key={`county${id}`}
        value={id}
        selected={countyPresent && selected}
      >
        {name}
      </option>
    );
  };

  return (
    <div className="header">
      <div className="header__content">
        <div className="header__left-wrap">
          <CountyLogo county={county} />
          {user.isLoggedIn && (
            <div className="header__county-wrap">
              <select onChange={(e) => goToCounty(e.target.value)}>
                {countyOptions.map(createOptions)}
              </select>
            </div>
          )}
          {clerkName}
          <InfoHeader title="Support" name="Dashboard" />
        </div>
        {authHeader}
      </div>
    </div>
  );
};

Header.displayName = "Header";

Header.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    isLoggedIn: PropTypes.bool.isRequired,
  }).isRequired,
  county: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    sealUrl: PropTypes.string,
    clerkName: PropTypes.string,
  }),
  counties: PropTypes.any,
  logout: PropTypes.any,
  goToCounty: PropTypes.any,
};

Header.defaultProps = {
  user: {
    isLoggedIn: false,
  },
};

export default connect(Header);

function CountyLogo({ county }) {
  if (county) {
    return (
      <div className="header__county-seal">
        <img
          className="header__seal-image"
          alt={`${county.name} County Seal`}
          src={county.sealUrl}
        />
      </div>
    );
  }

  return (
    <div className="header__kofile-logo">
      <img alt="GovOS Logo" src={govOSLogo} />
    </div>
  );
}

CountyLogo.propTypes = {
  county: PropTypes.any,
};

function InfoHeader({ title, name }) {
  return (
    <div className="header__county-info">
      <span className="header__title">{title}</span>
      <span className="header__name">{name}</span>
    </div>
  );
}

InfoHeader.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
};

function AuthHeader({ name, logout }) {
  return (
    <div className="header__authorization-wrap">
      <span className="header__user-name">{name}</span>
      <span className="header__login">
        <button onClick={logout} className="header__login-btn">
          Sign Out
        </button>
      </span>
    </div>
  );
}

AuthHeader.propTypes = {
  name: PropTypes.string,
  logout: PropTypes.any,
};
