import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Menu from "react-a11y-menu";
import * as Components from "./components";

const DropdownMenu = props => (
  <div className={classnames("a11y-menu", props.className)}>
    <Menu
      className="a11y-menu__control"
      renderLabel={labelProps => (
        <Components.MenuControl {...labelProps} label={props.label} />
      )}
      renderOptions={optionProps => (
        <Components.MenuOptions
          {...optionProps}
          options={props.options}
          optionRenderer={props.optionRenderer}
        />
      )}
    />
  </div>
);

DropdownMenu.displayName = "Dropdown Menu";

DropdownMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  optionRenderer: PropTypes.func,
  label: PropTypes.node,
  className: PropTypes.string
};

DropdownMenu.defaultProps = {
  options: [],
  optionRenderer: ({ label }) => label,
  label: "",
  className: ""
};

export default DropdownMenu;
