import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'

const FilterMenu = ({ className, id = '' }) => {
  const viewBox = `0 0 ${BASE} 325`
  const barHeight = BASE * 0.1
  const barWidth = BASE * 0.75
  const spacing = BASE * 0.04
  const maskPath = `
    M0 0
    L${BASE} 0
    L${BASE} ${barHeight * 1.5 - spacing}
    L170 ${barHeight * 1.5 - spacing}
    L300 185
    L300 ${BASE}
    L0 ${BASE}
    Z
  `

  return e(SVG, { className, viewBox }, [
    e(
      'defs',
      { key: 'defs' },
      e(
        'mask',
        { id: `FilterMenuMask${id}` },
        e('path', { fill: 'white', d: maskPath })
      )
    ),
    e('g', { mask: `url(#FilterMenuMask${id})`, key: 'bars' }, [
      e('rect', { x: 0, y: 0, width: barWidth, height: barHeight, key: '1' }),
      e('rect', {
        x: 0,
        y: barHeight * 2,
        width: barWidth,
        height: barHeight,
        key: '2'
      }),
      e('rect', {
        x: 0,
        y: barHeight * 4,
        width: barWidth,
        height: barHeight,
        key: '3'
      })
    ]),
    e(
      'g',
      {
        key: 'filter',
        transform: `translate(${BASE / 2 - barHeight / 2} ${barHeight * 1.5})`
      },
      [
        e('path', {
          key: 'triangle',
          d: `M0 0 L${BASE / 2} 0 L${BASE / 4} ${BASE / 4} Z`
        }),
        e('path', {
          key: 'base',
          d: `
        M${BASE / 4 + barHeight / 2} 0
        L${BASE / 4 + barHeight / 2} ${BASE * 0.2 + BASE * 0.3}
        L${BASE / 4 - barHeight / 2} ${BASE * 0.2 + BASE * 0.2}
        L${BASE / 4 - barHeight / 2} 0
        Z
      `
        })
      ]
    )
  ])
}

FilterMenu.displayName = 'FilterMenu'

FilterMenu.propTypes = {
  className: PropTypes.string
}

export default FilterMenu
