import React, { PureComponent } from "react";
import * as R from "ramda";
import classnames from "classnames";
import PropTypes from "prop-types";
import { randomString, formatDate } from "../../utils";
import { Content, Search, Info } from "./components";
import * as Components from "../";
import iconPrinter from "../../../public/assets/img/icon_printer.svg";

export class Report extends PureComponent {
  constructor(props) {
    super(props);

    const radioOptions = this.props.radioOptions ? this.props.radioOptions : [];
    const firstRadio = R.compose(
      R.propOr("", "value"),
      R.head
    )(radioOptions);

    this.state = {
      id: "",
      form: {
        date: {},
        radio: firstRadio
      },
      hasFetched: !R.isEmpty(this.props.data)
    };

    this.updateFormState = key => value => {
      this.setState({
        form: {
          ...this.state.form,
          [key]: value
        }
      });
    };

    this.setHasFetched = () => this.setState({ hasFetched: true });

    this.handleDatePicked = pickedDate => {
      const getDate = R.path(["form", "date"]);
      const changeState = R.not(R.equals(getDate(this.state), pickedDate));

      if (changeState) {
        this.updateFormState("date")(pickedDate);
      }
    };

    this.handleRadioChange = ({ target }) => {
      this.updateFormState("radio")(target.value);
    };

    this.handleSearch = ({ date, radio = "" }) => {
      const { from: fromMoment, to: toMoment } = date;
      const from = formatDate(fromMoment, "YYYY-MM-DD");
      const to = formatDate(toMoment, "YYYY-MM-DD");
      const correlationId = randomString();

      this.props.fetchData({
        from,
        to,
        groupBy: radio,
        correlationId
      });

      this.setHasFetched();
    };

    this.handleSubmit = formValues => event => {
      event.preventDefault();

      this.handleSearch(formValues);
    };

    this.handlePrint = () => window.print();
  }

  render() {
    const props = this.props;
    const state = this.state;
    const {
      data,
      isLoading,
      widgetTitle,
      searchTitle,
      searchDescription,
      iconPrinter,
      reportType,
      county,
      Table,
      radioOptions
    } = props;

    const { form, hasFetched } = state;
    const { date } = form;

    const dateIsPicked = R.not(R.isEmpty(date));
    const wrapperClassName = classnames("report-page", {
      stretch: !hasFetched
    });

    const printerClassName = classnames("report-page--print", {
      hide: !hasFetched
    });

    const { from: fromMoment, to: toMoment } = date;
    const from = formatDate(fromMoment, "M/D/YYYY");
    const to = formatDate(toMoment, "M/D/YYYY");
    const searchTerm = `${from} - ${to}`;

    const searchInfo = (
      <Info
        countyName={county.name}
        searchMetrics={reportType}
        searchTerm={searchTerm}
      />
    );

    const onHeaderRangePicked = date => {
      this.handleDatePicked(date);
      this.handleSearch({ ...this.state.form, date });
    };

    const onHeaderRadioPicked = event => {
      const value = R.path(["target", "value"], event);

      this.handleRadioChange(event);
      this.handleSearch({ ...this.state.form, radio: value });
    };

    const radioProps = {
      options: radioOptions,
      onChange: this.handleRadioChange,
      value: this.state.form.radio
    };

    const HeaderAction = () => (
      <div className="report-page__header-wrapper">
        <div className="report-page__header-radio">
          <Components.RadioSet {...radioProps} onChange={onHeaderRadioPicked} />
        </div>
        <Components.DateRange
          handleRangePicked={onHeaderRangePicked}
          from={fromMoment}
          to={toMoment}
        />
      </div>
    );

    const headerActionToRender = hasFetched && <HeaderAction />;

    const content = <Content Table={Table} data={data} isLoading={isLoading} />;

    const search = (
      <Search
        handleDatePicked={this.handleDatePicked}
        onSubmit={this.handleSubmit(this.state.form)}
        dateIsPicked={dateIsPicked}
        searchTitle={searchTitle}
        searchDescription={searchDescription}
        isLoading={isLoading}
        radioProps={radioProps}
      />
    );

    const contentToRender = hasFetched ? content : search;

    return (
      <div className={wrapperClassName}>
        {searchInfo}
        <div className="report-page--controls">
          <Components.ReturnButton className="refunds__return-button" />
          <button
            className={printerClassName}
            type="button"
            onClick={this.handlePrint}
          >
            <img src={iconPrinter} className="report-page--print-icon" />
            Printable Version
          </button>
        </div>
        <Components.Widget
          className="dashboard-search full-widget"
          title={widgetTitle}
          action={headerActionToRender}
        >
          {contentToRender}
        </Components.Widget>
      </div>
    );
  }
}

Report.displayName = "Report";
Report.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array),
  tableConfig: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    cellOptions: PropTypes.object,
    headerOptions: PropTypes.object
  }),
  iconPrinter: PropTypes.string.isRequired,
  radioOptions: PropTypes.any,
  fetchData: PropTypes.any
};

Report.defaultProps = {
  iconPrinter: iconPrinter
};

export default Report;
