import * as R from "ramda";

const root = R.lensProp("refund");

const orderId = R.compose(
  root,
  R.lensProp("orderId")
);

const customer = R.compose(
  root,
  R.lensProp("customer")
);

const refundAmount = R.compose(
  root,
  R.lensProp("refundAmount")
);

const loading = R.compose(
  root,
  R.lensProp("loading")
);

const currency = R.compose(
  root,
  R.lensProp("currency")
);

const description = R.compose(
  root,
  R.lensProp("description")
);

const items = R.compose(
  root,
  R.lensProp("items")
);

const requestedBy = R.compose(
  root,
  R.lensProp("requestedBy")
);

const error = R.compose(
  root,
  R.lensProp("error")
);

const status = R.compose(
  root,
  R.lensProp("status")
);

const data = R.compose(
  root,
  R.lensProp("data")
);

export default Object.assign(root, {
  orderId,
  customer,
  refundAmount,
  loading,
  currency,
  description,
  items,
  requestedBy,
  error,
  status,
  data
});
