import * as R from "ramda";

const root = R.lensProp("router");

export default Object.assign(root, {
  path: R.compose(
    root,
    R.lensPath(["location", "pathname"])
  ),
  search: R.compose(
    root,
    R.lensPath(["location", "search"])
  )
});
