import React from "react";
import PropTypes from "prop-types";
import styled from "react-emotion";

const Wrapper = styled("div")`
  align-items: center;
  color: #66768c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 50px 40px;
`;

const Header = styled("h2")`
  font-size: 32px;
  font-weight: 500;
`;

const Text = styled("p")`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  text-align: center;
`;

const Empty = ({ title, description }) => (
  <Wrapper>
    <Header>{title}</Header>
    <Text>{description}</Text>
  </Wrapper>
);

Empty.displayName = "Empty";
Empty.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

Empty.defaultProps = {
  title: "No Results Found",
  description:
    "Your search returned no results. Try broadening your search with fewer character"
};

export default Empty;
