import * as R from "ramda";

const root = R.lensProp("refunds");
const ids = R.compose(
  root,
  R.lensProp("ids")
);
const data = R.compose(
  root,
  R.lensProp("data")
);
const error = R.compose(
  root,
  R.lensProp("error")
);
const isLoading = R.compose(
  root,
  R.lensProp("isLoading")
);
const total = R.compose(
  root,
  R.lensProp("total")
);
const pagination = R.compose(
  root,
  R.lensProp("pagination")
);
const userNameQuery = R.compose(
  root,
  R.lensProp("userNameQuery")
);
const orderNumberQuery = R.compose(
  root,
  R.lensProp("orderNumberQuery")
);

export default Object.assign(root, {
  ids,
  data,
  error,
  isLoading,
  total,
  pagination,
  userNameQuery,
  orderNumberQuery
});
