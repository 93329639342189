import * as Rx from "rxjs/Rx";
import * as actions from "../../actions";
import { types as userTypes } from "../../actions/user";

export const mockCreate = actionStream =>
  actionStream.ofType(userTypes.CREATE_USER).mergeMap(({ payload }) =>
    Rx.Observable.of(
      actions.user.create.succeed({
        userId: 1234,
        userName: "John Snow",
        tenantId: "123-abc",
        ...payload
      })
    ).delay(1500)
  );

export const logout = actionStream =>
  actionStream.ofType(userTypes.LOGOUT).mergeMap(() => {
    sessionStorage.removeItem("jwt");

    return Rx.Observable.empty();
  });

export default [mockCreate, logout];
