import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { getters } from "../store";
import * as components from "../components";
import Dashboard from "./Dashboard";
import SelectCounty from "./SelectCounty";
import * as Reports from "./reports";
import * as Refunds from "./refunds";
import * as Receipts from "./receipts";
import NotFound from "./NotFound";

const Authenticated = ({ isLoggedIn, history, counties }) => {
  if (!isLoggedIn) {
    const { pathname: returnPath } = history.location;

    return <Redirect to={`/signin?returnPath=${returnPath}`} />;
  }

  if (counties.length === 0) {
    return <components.Spinner />;
  }

  return (
    <Switch>
      <Route exact path="/" component={SelectCounty} />
      <Route path="/:county/dashboard" component={Dashboard} />
      <Route path="/:county/receipts/:id" component={Receipts.Order} />
      <Route
        path="/:county/refunds/:orderId/:refundId"
        component={Refunds.Refund}
      />
      <Route path="/:county/refunds" component={Refunds.Refunds} />
      <Route path="/:county/reports/refunds" component={Reports.Refunds} />
      <Route path="/:county/reports/billing" component={Reports.Billing} />
      <Route
        path="/:county/reports/import-status"
        component={Reports.ImportStatus}
      />
      <Route
        path="/:county/reports/history-by-day"
        component={Reports.HistoryByDay}
      />
      <Route
        path="/:county/reports/users-activity"
        component={Reports.UsersActivity}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

Authenticated.propTypes = {
  isLoggedIn: PropTypes.bool,
  history: PropTypes.any,
  counties: PropTypes.any,
};

const mapStateToProps = (state) => ({
  isLoggedIn: getters.user.isLoggedIn(state),
  counties: getters.counties.asArray(state),
});

export default connect(mapStateToProps)(Authenticated);
