import PropTypes from 'prop-types'
import { e, SVG } from '../utils'

const Help = ({ className, id = '' }) => {
  const viewBox = '0 0 16 16'

  return e(SVG, { viewBox, className }, [
    e(
      'defs',
      { key: 'defs' },
      e('mask', { id: `HelpMask${id}` }, [
        e('circle', { cx: 8, cy: 8, r: 8, fill: 'white', key: 'circle' }),
        e('path', {
          fill: 'black',
          key: 'question mark',
          d: `
          M5.176 5.828h1.305C6.538 5.02 7.1 4.51 7.968 4.51c.864 0 1.425.504 1.425 1.197 0 .637-.271.996-1.034 1.456-.863.517-1.248 1.084-1.216 1.991l.006.48h1.305v-.379c0-.636.233-.97 1.046-1.45.832-.497 1.324-1.184 1.324-2.155 0-1.367-1.122-2.357-2.786-2.357-1.828 0-2.805 1.084-2.862 2.534zm2.592 6.502c.56 0 .938-.375.938-.935 0-.572-.378-.948-.938-.948-.56 0-.944.376-.944.948 0 .56.383.935.944.935z
        `
        })
      ])
    ),
    e('rect', {
      x: 0,
      y: 0,
      width: 16,
      height: 16,
      mask: `url(#HelpMask${id})`,
      key: 'fill'
    })
  ])
}

Help.displayName = 'Help'

Help.propTypes = {
  className: PropTypes.string
}

export default Help
