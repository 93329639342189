import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getters } from "../../store";
import County from "./County";

const Counties = ({ counties }) => {
  const countiesMapped = counties.map(county => (
    <County key={county.id} {...county} />
  ));

  return (
    <div className="counties--wrapper">
      <div className="counties">{countiesMapped}</div>
    </div>
  );
};

Counties.displayName = "Counties";

Counties.propTypes = {
  counties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      sealUrl: PropTypes.string.isRequired
    })
  )
};

const mapStateToProps = state => ({
  counties: getters.counties.asArray(state)
});

export default connect(mapStateToProps)(Counties);
