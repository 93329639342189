import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import * as utils from "../../../utils";
import * as screen from "../../../utils/screen";
import { actions, getters } from "../../../store";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import View from "./View";

const lens = {
  timeout: R.lensProp("timeout"),
  screen: Object.assign(R.lensProp("screen"), {
    state: R.lensPath(["screen", "state"]),
    title: R.lensPath(["screen", "title"])
  }),
  hasError: R.lensProp("hasError"),
  errorMessage: R.lensProp("errorMessage")
};

const update = {
  timeout: { to: R.set(lens.timeout) },
  screen: {
    to: R.set(lens.screen),
    state: { to: R.set(lens.screen.state) },
    title: { to: R.set(lens.screen.title) }
  },
  hasError: { to: R.set(lens.hasError) },
  errorMessage: { to: R.set(lens.errorMessage) }
};

class Refunds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeout: null,
      shortTimedOut: false,
      longTimedOut: false,
      screen: screen.screenFor(screen.LOADING),
      errorMessage: null,
      userName: ""
    };

    this.fetch = params => {
      if (this.timer) this.timer.next();

      this.props.fetch(params);

      this.setState(
        R.compose(
          update.timeout.to(null),
          update.screen.to(screen.screenFor(screen.LOADING))
        )
      );

      this.timer = utils.timeout({
        SHORT: () => this.setState(update.timeout.to("SHORT")),
        LONG: () => this.setState(update.timeout.to("LONG")),
        BAIL: () =>
          this.setState(
            R.compose(
              update.timeout.to(null),
              update.screen.to(screen.screenFor(screen.ERROR)),
              update.errorMessage.to(
                "The request took too long to complete. Please try again later."
              )
            )
          )
      });
    };

    this.onFindByUser = userName => {
      this.fetch({
        userName,
        pagination: {
          limit: 20,
          offset: 0
        }
      });

      this.setState(update.screen.state.to(screen.LOADING));
    };

    this.onFindByOrder = (values, form) => {
      this.fetch({
        orderNumber: values.order,
        pagination: {
          limit: 20,
          offset: 0
        }
      });

      form.reset();
      this.setState(update.screen.state.to(screen.LOADING));
    };
  }

  componentWillReceiveProps(nextProps) {
    const getCountyId = R.pathOr(null, ["county", "id"]);

    if (getCountyId(this.props) !== getCountyId(nextProps)) {
      this.fetch();
    } else {
      const nextScreen = screen.getScreen(nextProps.refunds);

      if (nextScreen.state !== this.state.screen.state) {
        this.timer.next();

        this.setState(
          R.compose(
            update.timeout.to(null),
            update.screen.to(nextScreen),
            update.hasError.to(nextScreen.state === screen.ERROR),
            update.errorMessage.to(nextProps.refunds.error)
          )
        );
      }
    }
  }

  componentDidMount() {
    this.fetch();
  }

  render() {
    const { pagination } = this.props.refunds;
    const props = {
      className: this.props.className,
      title: `${this.state.screen.title} ${
        pagination ? `(${pagination.total})` : ""
      }`,
      records: this.props.refunds.records,
      hasResults: this.state.screen.state === screen.RESULTS,
      isLoading: this.state.screen.state === screen.LOADING,
      noResults: this.state.screen.state === screen.NO_RESULTS,
      hasError: this.state.screen.state === screen.ERROR,
      errorMessage: this.state.errorMessage,
      timeout: this.state.timeout,
      countyId: this.props.county.id,
      pagination: this.props.refunds.pagination,
      goToPage: this.props.goToPage,
      userName: this.state.userName,
      onFindByUser: this.onFindByUser,
      onFindByOrder: this.onFindByOrder,
      goToRefund: this.props.goToRefund
    };

    return <View {...props} />;
  }
}

Refunds.propTypes = {
  county: PropTypes.any,
  className: PropTypes.string,
  fetch: PropTypes.any,
  refunds: PropTypes.shape({
    error: PropTypes.any,
    total: PropTypes.number,
    records: PropTypes.arrayOf(PropTypes.object.isRequired),
    isLoading: PropTypes.bool.isRequired,
    pagination: PropTypes.shape({
      page: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      size: PropTypes.number.isRequired
    })
  }).isRequired,
  goToPage: PropTypes.func,
  goToRefund: PropTypes.func
};

const mapStateToProps = state => ({
  county: getters.county(state),
  refunds: {
    total: getters.refunds.total(state),
    records: getters.refunds.tableView(state),
    isLoading: getters.refunds.isLoading(state),
    error: getters.refunds.error(state),
    pagination: getters.refunds.pagination(state)
  }
});

const mapDispatchToProps = dispatch => ({
  fetch: params => dispatch(actions.refunds.fetch(params)),
  goToPage: pageNo => dispatch(push(`?page=${pageNo}`)),
  goToRefund: ({ refundId, orderId, county }) => () =>
    dispatch(push(`/${county}/refunds/${orderId}/${refundId}`))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Refunds);
