import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../../../store";
import View from "./View";

class Order extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderId: getOrderId(),
      modalOpen: false
    };

    this.setModalOpen = modalOpen => this.setState({ modalOpen });
  }

  componentDidMount() {
    this.props.fetch(this.state.orderId);
  }

  componentWillUnmount() {
    this.props.clearSelectedItems();
  }

  render() {
    const props = {
      itemIds: this.props.itemIds,
      selectedItems: this.props.selectedItems,
      className: this.props.className,
      isLoading: !this.props.order,
      hasOrder: !!this.props.order,
      order: this.props.order,
      countyId: this.props.countyId,
      fetchRefundQuote: this.props.fetchRefundQuote,
      refundQuoteItems: this.props.refundQuoteItems,
      refund: {
        modal: {
          show: this.state.modalOpen,
          open: () => this.setModalOpen(true),
          close: () => this.setModalOpen(false)
        },
        order: this.props.order
      }
    };

    return <View {...props} />;
  }
}

const mapStateToProps = state => {
  const county = getters.county(state);
  const order = getters.order.orderReceipt(state);

  if (order) {
    return {
      countyId: county.id,
      order,
      selectedItems: getters.order.selectedItems(state),
      itemIds: getters.order.itemIds(state),
      refundQuoteItems: getters.order.refundQuoteItems(state)
    };
  }

  return {
    countyId: county.id
  };
};

const mapDispatchToProps = dispatch => ({
  fetch: id => dispatch(actions.order.fetch({ id: parseInt(id) })),
  toggleItemSelected: id => dispatch(actions.order.toggleItemSelected(id)),
  clearSelectedItems: () => dispatch(actions.order.clearSelectedItems()),
  fetchRefundQuote: payload => dispatch(actions.order.fetchRefundQuote(payload))
});

const mergeProps = (state, dispatch, own) => {
  const props = { ...state, ...dispatch, ...own };

  if (state.order) {
    const { selectedItems = [] } = state;

    const mappedLineItems = state.order.lineItems.map(lineItem => {
      const onClick = () => dispatch.toggleItemSelected(lineItem.id);
      const isChecked = R.contains(lineItem.id, selectedItems);

      const mappedLineItemData = R.set(
        R.lensProp("checkbox"),
        { onClick, isChecked },
        lineItem
      );

      return mappedLineItemData;
    });

    return {
      ...props,
      ...R.set(R.lensPath(["order", "lineItems"]), mappedLineItems, state)
    };
  }

  return props;
};

Order.propTypes = {
  fetch: PropTypes.func,
  clearSelectedItems: PropTypes.func,
  itemIds: PropTypes.array,
  selectedItems: PropTypes.array,
  className: PropTypes.string,
  order: PropTypes.object,
  countyId: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Order);

function getOrderId() {
  const match = window.location.pathname.match(/^\/.{5}\/receipts\/(\d+)/);

  if (match) {
    return match[1];
  }

  return null;
}
