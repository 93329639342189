import PropTypes from 'prop-types'
import { e, SVG } from '../utils'

const Warning = ({ className }) =>
  e(
    SVG,
    { className, viewBox: '0 0 18 16' },
    e('path', {
      d:
        'M9.03088915,12.6393713 C9.64749372,12.6275021 10.1420434,12.1216766 10.1384827,11.5066546 C10.1349219,10.887874 9.63977874,10.3913461 9.02040469,10.3856093 C8.38164429,10.3798726 7.86315838,10.9096342 7.88511639,11.5460208 C7.90628312,12.1659883 8.41487803,12.6512404 9.03088915,12.6393713 M10.1493628,7.13681342 C10.1493628,6.45690648 10.1477802,5.77680173 10.150154,5.09689479 C10.1517366,4.6432938 9.98240278,4.37801734 9.4761817,4.41679004 C9.1756933,4.43993497 8.87045722,4.44072624 8.57016664,4.41659222 C8.03882244,4.37386312 7.86592786,4.64111779 7.87008208,5.12716124 C7.87937961,6.18490414 7.87206028,7.24284487 7.87186246,8.30058778 C7.87166464,9.90193973 7.87146682,9.88809233 9.4522455,9.85386949 C9.97508344,9.84239594 10.1574734,9.60046223 10.1521322,9.12035337 C10.1450107,8.45923933 10.1503519,7.79812528 10.1493628,7.13681342 M8.9766865,15.3334802 C6.44023997,15.3334802 3.90379345,15.3352606 1.36754474,15.3326889 C0.182405828,15.331502 -0.363181407,14.3778124 0.260742501,13.356864 C2.8446658,9.12866181 5.43729317,4.90599861 8.0364486,0.687489625 C8.60419166,-0.234351025 9.42455071,-0.227625149 9.99486543,0.698567538 C12.5825473,4.90224003 15.1591513,9.11244058 17.7448549,13.3173 C18.0370349,13.7922655 18.0882703,14.2810785 17.8465344,14.7704848 C17.5917424,15.2867947 17.104512,15.3396126 16.5858283,15.3378323 C14.0495796,15.3283369 11.513133,15.3334802 8.9766865,15.3334802'
    })
  )

Warning.displayName = 'Warning'

Warning.propTypes = {
  className: PropTypes.string
}

export default Warning
