import * as R from "ramda";
import { fromObject } from "../../utils";
import lens from "../lenses/receipts";

export default {
  root: R.view(lens),
  ids: R.view(lens.ids),
  data: R.view(lens.data),
  total: R.view(lens.total),
  loading: R.view(lens.loading),
  inOrder: state => {
    if (!state) {
      throw new Error("must provide state!");
    }

    const ids = R.defaultTo([], R.view(lens.ids, state));
    const data = R.view(lens.data, state);

    return ids.map(fromObject(data));
  }
};
