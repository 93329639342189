import React from "react";
import PropTypes from "prop-types";
import styled from "react-emotion";
import iconErrorTriangle from "../../../public/assets/img/icon_error_triangle.svg";

const ErrorComponent = styled("div")`
  padding: 20px;
  color: #c4382f;
  display: flex;
  align-items: flex-start;
`;

const ErrorIcon = styled("img")`
  flex: 0 0 19px;
  padding-top: 2px;
  width: 19px;
`;

const ErrorMessage = styled("p")`
  flex: 1;
  margin-left: 10px;
`;

export default Object.assign(
  ({ message, className }) => (
    <ErrorComponent className={className}>
      <ErrorIcon src={iconErrorTriangle} alt="error" />
      <ErrorMessage>{message}</ErrorMessage>
    </ErrorComponent>
  ),
  {
    propTypes: {
      className: PropTypes.string,
      message: PropTypes.string.isRequired
    }
  }
);
