import React from "react";
import * as rc from "recompose";
import { formatDate } from "../../../../utils";

export default rc.setDisplayName("Dashboard.widgets.Refunds.Results")(props => (
  <section className={`refunds-widget ${props.className}`}>
    <table className="table">
      <thead className="table--header">
        <tr className="table--header-row refunds-widget__header-row">
          <th className="table--header-cell">Date</th>
          <th className="table--header-cell">Customer</th>
          <th className="table--header-cell">Status</th>
        </tr>
      </thead>
      <tbody>
        {props.records.map((record, i) => (
          <tr key={i} className="table--body-row refunds-widget__body-row">
            <td className="table--body-cell">{formatDate(record.createdOn)}</td>
            <td className="table--body-cell">{record.customerFullName}</td>
            <td className="table--body-cell">{record.status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </section>
));
