import React from "react";
import PropTypes from "prop-types";

export default class Spinner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rotation: 0
    };

    this.rotate = () => {
      const newRotation = this.state.rotation + 6;

      return {
        rotation: newRotation <= 360 ? newRotation : 0
      };
    };
  }

  componentDidMount() {
    this.startLoop();
  }

  componentWillUnmount() {
    this.stopLoop();
  }

  loop() {
    this.setState(this.rotate());
    this._frameId = window.requestAnimationFrame(this.loop.bind(this));
  }

  startLoop() {
    if (!this._frameId) {
      this._frameId = window.requestAnimationFrame(this.loop.bind(this));
    }
  }

  stopLoop() {
    window.cancelAnimationFrame(this._frameId);
  }

  render() {
    const { className } = this.props;

    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
      >
        <defs>
          <linearGradient
            id="a"
            x1="85.8855936%"
            x2="60.9667969%"
            y1="36.3526625%"
            y2="87.1370443%"
          >
            <stop offset="0%" stopColor="#006DD5" />
            <stop offset="100%" stopColor="#66A7E6" />
          </linearGradient>
        </defs>
        <g transform={`rotate(${this.state.rotation} 9 9)`}>
          <path
            fill="url(#a)"
            fillRule="nonzero"
            d="M9 0C4.0296 0 0 4.0302 0 9c0 4.971 4.0296 9 9 9s9-4.029 9-9c0-.0282-.0021-.0564-.0021-.0843l-1.0365 1.0359-.6423.6432-.6144-.6138-.861-.8616C14.7795 12.294 12.1905 14.85 9 14.85c-3.2307 0-5.8497-2.619-5.8497-5.85 0-3.231 2.619-5.85 5.8497-5.85 2.7783 0 5.0994 1.9392 5.6964 4.5363l1.6509 1.6512 1.5669-1.5669C17.3145 3.3819 13.5534 0 9 0"
          />
        </g>
      </svg>
    );
  }
}

Spinner.propTypes = {
  className: PropTypes.string
};
