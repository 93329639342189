import { set } from "@kofile/redux-lenses";
import * as R from "ramda";
import * as utils from "../../utils";
import { newApiTypes } from "../actions/refunds-report";
import * as lenses from "../lenses";

const { refundsReport } = lenses;

export default {
  [newApiTypes.fetchRefundActivity]: [set(refundsReport.loading).as(true)],

  [newApiTypes.refundActivity]: [
    set(refundsReport.data).with(R.path(["payload"])),
    set(refundsReport.loading).as(false),
  ],

  [newApiTypes.errorFetchingRefund]: [
    set(refundsReport.error).with(utils.getReason),
    set(refundsReport.loading).as(false),
  ],
};
