import React from "react";
import { Form as FFForm, Field } from "react-final-form";
import * as Components from "../../../components";

export default Object.assign(
  (props) => (
    <FFForm
      onSubmit={props.onFindByOrder}
      render={({ handleSubmit, pristine, invalid }) => (
        <form className="flex-container search" onSubmit={handleSubmit}>
          <Field
            name="orderId"
            render={({ input }) => (
              <Components.TextInput
                {...input}
                placeholder="Enter Order #"
                className="search__field"
                join={Components.TextInput.joins.RIGHT}
                padding="2px 8px"
                data-testid="searchByOrderNo"
              />
            )}
          />

          <button
            type="submit"
            disabled={pristine || invalid}
            className="search-icon--button"
            data-testid="searchByOrderNoButton"
            title="View Order"
          >
            View Order
          </button>
        </form>
      )}
    />
  ),
  { displayName: "Search.Results.SearchByOrder" }
);
