import { createAction } from "redux-actions";
import { makeActionTypes } from "../../utils";
import { types as syncTypes } from "@kofile/ko-search-action-types";

/**
 * TYPES
 */
export const types = makeActionTypes("USER", [
  "LOGOUT",
  "CREATE_USER",
  "CREATE_USER_SUCCEEDED",
  "CREATE_USER_REJECTED",
  "CREATE_USER_FAILED",
  "CLEAN_UPDATE",
  "CLEAN_CREATE",
  "CLEAR_ERROR"
]);

/**
 * ACTIONS
 */
export const actions = {
  login: Object.assign(createAction(syncTypes.SUPPORT_SIGN_IN_0), {
    reject: createAction(syncTypes.SUPPORT_SIGN_IN_REJECTED_0),
    succeed: createAction(syncTypes.SUPPORT_SIGN_IN_FULFILLED_0)
  }),
  logout: createAction(types.LOGOUT),
  create: Object.assign(createAction(syncTypes.ADD_NEW_USER_0), {
    reject: createAction(syncTypes.ADD_NEW_USER_REJECTED_0),
    succeed: createAction(syncTypes.ADD_NEW_USER_FULFILLED_0),
    cancel: createAction(syncTypes.ADD_NEW_USER_CANCELLED_0),
    clean: createAction(types.CLEAN_CREATE)
  }),
  update: Object.assign(createAction(syncTypes.SUPPORT_UPDATE_USER_INFO_0), {
    reject: createAction(syncTypes.SUPPORT_UPDATE_USER_INFO_REJECTED_0),
    succeed: createAction(syncTypes.SUPPORT_UPDATE_USER_INFO_FULFILLED_0),
    clean: createAction(types.CLEAN_UPDATE)
  }),
  clearError: createAction(types.CLEAR_ERROR)
};

export default actions;
