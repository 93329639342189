import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'
import Search from './Search'

const ZoomOut = ({ className }) => {
  // copied from Search; should export probably
  const r = BASE * (3 / 4) / 2
  const scalar = 0.35
  const barWidth = BASE * scalar
  const barHeight = barWidth * 0.2
  const rx = barHeight / 2
  const x = r - barWidth / 2
  const y = r - barHeight / 2

  return e(SVG, { className }, [
    e(Search, { key: 'Search' }),
    e('rect', { key: 'minus', x, y, width: barWidth, height: barHeight, rx })
  ])
}

ZoomOut.displayName = 'ZoomOut'

ZoomOut.propTypes = {
  className: PropTypes.string
}

export default ZoomOut
