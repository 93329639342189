import React from "react";
import { PropTypes } from "prop-types";
import * as R from "ramda";
import iconError from "../../public/assets/img/icon_error.svg";

const defaultMessage = "Something wrong.";
const errorMessages = {
  404: "The requested URL was not found on this server.",
  500: "There was an internal server error."
};

export const ErrorScreen = ({ code, errorIcon }) => {
  const message = R.propOr(defaultMessage, code, errorMessages);

  return (
    <div className="error-page">
      <img alt="Sad face" src={errorIcon} className="error-page__icon" />
      <h1 className="error-page__title">{code} Error</h1>
      <p className="error-page__info">
        {message}&nbsp;Please contact&nbsp;
        <a href="/" className="error-page__link">
          {" "}
          {/* provide support contact */}
          customer support
        </a>{" "}
        or{" "}
        <a href="/" className="error-page__link">
          go back to home.
        </a>
      </p>
    </div>
  );
};

ErrorScreen.displayName = "ErrorScreen";
ErrorScreen.propTypes = {
  code: PropTypes.number.isRequired,
  errorIcon: PropTypes.string
};

ErrorScreen.defaultProps = {
  errorIcon: iconError,
  code: 404
};

export default ErrorScreen;
