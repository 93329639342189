import { set } from "@kofile/redux-lenses";
import { types } from "../actions/receipts";
import * as lenses from "../lenses";
import * as utils from "../../utils";

const { receipts } = lenses;

export default {
  [types.FETCH_RECEIPTS]: [set(receipts.loading).as(true)],

  [types.FETCH_RECEIPTS_FULFILLED]: [
    set(receipts.ids).with(utils.getIDs),
    set(receipts.data).with(utils.getData),
    set(receipts.loading).as(false)
  ],

  [types.FETCH_RECEIPTS_REJECTED]: [
    set(receipts.error).with(utils.getReason),
    set(receipts.loading).as(false)
  ],

  [types.FETCH_RECEIPTS_CANCELLED]: [
    set(receipts.error).with(utils.getReason),
    set(receipts.loading).as(false)
  ]
};
