import * as R from "ramda";
import { set } from "@kofile/redux-lenses";
import { types as syncTypes } from "@kofile/ko-search-action-types";
import { configuration as root } from "../lenses";

const getTenantCode = R.path(["payload", "tenant", "code"]);

export default {
  [syncTypes.FETCH_SUPPORT_CONFIGURATION_0]: [
    set(root).with((action, state) => {
      const tenant = action.payload.tenant;
      const rootState = R.view(root, state);

      return {
        ...rootState,
        [tenant]: {
          isLoading: true
        }
      };
    })
  ],
  [syncTypes.FETCH_SUPPORT_CONFIGURATION_FULFILLED_0]: [
    set(root).with((action, state) => {
      const tenant = getTenantCode(action);
      const rootState = R.view(root, state);

      return {
        ...rootState,
        [tenant]: {
          isLoading: false,
          ...action.payload
        }
      };
    })
  ]
};
