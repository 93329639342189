import * as R from "ramda";
import lens, { linkStates } from "../lenses/order";
import { default as userLens } from "../lenses/user";
import * as getters from "../getters";

const {
  complement,
  anyPass,
  isNil,
  isEmpty,
  equals,
  propSatisfies,
  allPass,
  reject,
} = R;

const isAbsent = anyPass([isNil, isEmpty]);
const isPresent = complement(isAbsent);
const doesNotEqual = complement(equals);
const statusIsNotNone = allPass([isPresent, doesNotEqual("none")]);
const hasRefund = propSatisfies(statusIsNotNone, "refundStatus");
const withoutRefunds = reject(hasRefund);

export default {
  root: R.view(lens),
  order: R.view(lens.order),
  zipLink: R.view(lens.zipLink),
  pdfLinks: R.view(lens.pdfLinks),
  loading: R.view(lens.loading),
  error: R.view(lens.error),
  selectedItems: R.view(lens.selectedItems),
  itemIds: R.compose(R.map(R.prop("id")), R.view(lens.items)),
  refundSuccess: R.view(lens.refundSuccess),
  refundQuote: R.view(lens.refundQuote),
  refundQuoteItems: (state) => {
    const order = R.view(lens.order, state);

    if (!order) return null;

    const items = R.propOr([], "lineItems", order);

    return items.map((item) => {
      const { id, type } = item;

      return {
        id,
        type: type || "docimage",
        price: item.priceCents,
        total: item.total,
        certificationFee: item.certificationFee,
        convenienceFee: item.convenienceFee,
        instrumentNumber: item.instrumentNumber,
        docType: item.documentType,
        selectedPages: item.pagesPurchased, //is this value right for this key?
        deliveryMethod: "download",
      };
    });
  },
  refundModal: (state, selectedItems = []) => {
    const order = R.view(lens.order, state);
    const orderItems = R.propOr([], "lineItems", order);
    const totalDocuments = selectedItems.length || orderItems.length;
    const isPartialRefund = totalDocuments !== orderItems.length;

    return { totalDocuments, isPartialRefund };
  },
  orderRefund: (state, { selectedItems = [], reason = "" }) => {
    const userId = R.view(userLens.id, state);
    const order = R.view(lens.order, state);
    const orderItems = R.propOr([], "lineItems", order);
    const items = withoutRefunds(orderItems);

    const selectedItemData = selectedItems.length
      ? R.filter(({ id }) => R.contains(id, selectedItems), items)
      : items;

    return {
      orderId: order.orderId,
      description: reason,
      items: selectedItemData.map(R.prop("id")),
      requestedBy: userId,
      requesterFullName: R.view(userLens.userName, state),
    };
  },
  refundStatus: (state, refundId) => {
    const byRefundId = R.view(lens.byRefundId, state) || {};
    const refund = byRefundId[refundId];

    return refund ? refund.status : null;
  },
  refundReceipt: (state, refundId) => {
    const order = R.view(lens.order, state);
    const byRefundId = R.view(lens.byRefundId, state) || {};
    const byItemId = R.view(lens.byItemId, state) || {};
    const refund = byRefundId[refundId];
    const tenant = getters.counties.getCountyData(state);

    if (!refund) return null;
    if (!order) return null;

    const { lineItems: items = [] } = order;

    return {
      certificationFee: order.certificationFeeSubtotal,
      convenienceFee: order.convenienceFee,
      convenienceFeePerDoc: order.convenienceFeePerDoc,
      convenienceFeeType: order.convenienceFeeType,
      feesRefunded: order.feesRefunded,
      purchasedBy: order.purchasedBy,
      showRefundDescription: false,
      showRefundStatus: true,
      showSimpleRefundStatus: false,
      showOrderSummary: false,
      nonInteractive: true,
      orderDate: order.orderDate,
      orderId: order.orderId,
      orderNumber: order.orderNumber,
      reason: refund.reason,
      subtotal: order.subtotal,
      subtotalRefunded: refund.refundSubtotal,
      totalPrice: order.totalPrice,
      totalRefundAmount: order.totalRefundAmount,
      county: {
        name: tenant.name,
        sealUrl: tenant.sealUrl,
        clerkName: tenant.clerkName,
        addressLine1: tenant.addressLine1,
        addressLine2: tenant.addressLine2,
      },
      refundInfo: {
        description: refund.description,
        requestedBy: refund.requesterFullName,
        numItems: refund.items.length,
        amount: refund.refundAmount,
      },
      lineItems: items.map((item) => ({
        id: item.id,
        title: item.title,
        instrumentNumber: item.instrumentNumber,
        documentType: item.documentType,
        type: item.type || "docimage",
        pagesPurchased: item.pagesPurchased,
        totalPagesInDocument: item.totalPagesInDocument || 0,
        priceCents: item.priceCents,
        refundStatus: getRefundStatus(byItemId, item.id),
        certificationStatus: item.certificationStatus || "none",
      })),
    };
  },
  orderReceipt: (state) => {
    const order = R.view(lens.order, state);
    const tenant = getters.counties.getCountyData(state);
    const pdfLinks = R.view(lens.pdfLinks, state);

    if (!order) return null;
    const items = R.propOr([], "lineItems", order);
    const isSubscription = items[0].type === "subscription";

    const perOrder = !isSubscription
      ? {
          convenienceFee: order.convenienceFee,
          convenienceFeeType: order.convenienceFeeType,
          convenienceFeePerDoc: order.convenienceFeePerDoc,
          zipLink: getters.order.zipLink(state),
        }
      : {};

    return {
      ...perOrder,
      certificationFee: order.certificationFee,
      feesRefunded: order.feesRefunded,
      nonInteractive: true,
      orderDate: order.orderDate,
      orderId: order.orderId,
      orderNumber: order.orderNumber,
      purchasedBy: order.purchasedBy,
      showRefundDescription: true,
      showSimpleRefundStatus: false,
      showRefundStatus: false,
      subtotal: order.subtotal,
      subtotalRefunded: order.subtotal,
      totalPrice: order.totalPrice,
      totalRefundAmount: order.totalRefundAmount,
      type: isSubscription ? "subscription" : "per-order",
      county: {
        name: tenant.name,
        sealUrl: tenant.sealUrl,
        clerkName: tenant.clerkName,
        addressLine1: tenant.addressLine1,
        addressLine2: tenant.addressLine2,
      },
      lineItems: items.map((item) => {
        const { id, type } = item;

        const common = {
          id,
          type: type || "docimage",
          priceCents: item.priceCents,
          total: item.total,
          certificationFee: item.certificationFee,
          refundStatus: item.refundStatus,
          refundDescription: item.refundDescription,
          rejectionReason: item.rejectionReason || "",
        };

        if (type === "subscription") {
          return {
            ...common,
            title: item.title,
            totalPagesInDocument: 0,
            pagesPurchased: 0,
            certificationStatus: "none",
          };
        }

        return {
          ...common,
          instrumentNumber: item.instrumentNumber,
          documentType: item.documentType,
          pagesPurchased: item.pagesPurchased,
          totalPagesInDocument: item.totalPagesInDocument || 0,
          downloadLink: pdfLinks[id] || linkStates.loading,
          certificationStatus: item.certificationStatus || "none",
        };
      }),
    };
  },
};

function getRefundStatus(byItemId, itemId) {
  const itemRefund = byItemId[itemId] || {};

  return itemRefund.status || "none";
}
