import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { actions, getters } from "../../../store";

export const tableConfig = {
  cellOptions: {
    on: {}
  },
  headerOptions: {
    on: {}
  },
  rowOptions: {
    on: {}
  },
  columns: [
    {
      name: "date",
      label: "DATE"
    },
    {
      name: "department",
      label: "DEPARTMENT"
    },
    {
      name: "searches",
      label: "# SEARCHES"
    },
    {
      name: "downloads",
      label: "# DOWNLOADS"
    },
    {
      name: "documentsDownloaded",
      label: "# DOCUMENTS DOWNLOADED"
    }
  ]
};

const mapDispatchToProps = dispatch => ({
  fetchData: query => dispatch(actions.historyByDay.fetch(query))
});

const mapStateToProps = state => ({
  data: getters.historyByDay.tableView(state),
  isLoading: getters.historyByDay.loading(state),
  county: getters.county(state)
});

export default R.compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
);
