import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'
import Search from './Search'
import Plus from './Plus'

const ZoomIn = ({ className }) => {
  // copied from Search; should export probably
  const r = BASE * (3 / 4) / 2
  const plusScalar = 0.35
  const plusOffset = r - BASE * plusScalar / 2

  return e(SVG, { className }, [
    e(Search, { key: 'Search' }),
    e(Plus, {
      key: 'Plus',
      __width: BASE * plusScalar,
      __height: BASE * plusScalar,
      __x: plusOffset,
      __y: plusOffset,
      __τ: BASE * 0.2
    })
  ])
}

ZoomIn.displayName = 'ZoomIn'

ZoomIn.propTypes = {
  className: PropTypes.string
}

export default ZoomIn
