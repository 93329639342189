import * as R from "ramda";

const root = R.lensProp("users");
const loading = R.compose(
  root,
  R.lensProp("loading")
);
const data = R.compose(
  root,
  R.lensProp("data")
);
const error = R.compose(
  root,
  R.lensProp("error")
);
const searchId = R.compose(
  root,
  R.lensProp("searchId")
);

export default Object.assign(root, {
  error,
  data,
  loading,
  searchId
});
