import { createAction } from "redux-actions";
import { makeActionTypes } from "../../utils";

export const types = makeActionTypes("IMPORT_REPORT", ["RESET"]);

export const newApiTypes = {
  initiate: "initiate-import-report",
  rejectInitiate: "error-initiating-import-report",
  initiated: "import-report-initiated",
  fetch: "fetch-import-report",
  rejectFetch: "error-fetching-import-report",
  fulfilled: "import-report",
};

export const actions = {
  initiate: createAction(newApiTypes.initiate),
  rejectInitiate: createAction(newApiTypes.rejectInitiate),
  initiated: createAction(newApiTypes.initiated),
  fetch: createAction(newApiTypes.fetch),
  rejectFetch: createAction(newApiTypes.rejectFetch),
  fulfilled: createAction(newApiTypes.fulfilled),
  reset: createAction(types.RESET),
};

export default actions;
