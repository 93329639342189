import { createAction } from "redux-actions";

export const newApiTypes = {
  fetchRefundActivity: "fetch-refund-activity-for-a-tenant",
  refundActivity: "refund-activity-for-tenant",
  errorFetchingRefund: "error-fetching-refund-activity",
};

/**
 * ACTIONS
 */
export const actions = {
  fetch: createAction(newApiTypes.fetchRefundActivity),
  reject: createAction(newApiTypes.errorFetchingRefund),
  fulfilled: createAction(newApiTypes.refundActivity),
};

export default actions;
