import { view, pipe, propOr, anyPass, isNil, isEmpty } from "ramda";
import * as getters from "../getters";
import lens from "../lenses/refund";

const isAbsent = anyPass([isNil, isEmpty]);

export default {
  root: view(lens),
  orderId: view(lens.orderId),
  customer: view(lens.customer),
  refundAmount: view(lens.refundAmount),
  loading: view(lens.loading),
  currency: view(lens.currency),
  description: view(lens.description),
  items: view(lens.items),
  requestedBy: view(lens.requestedBy),
  error: view(lens.error),
  status: view(lens.status),
  refundReceipt: (state) => {
    const county = getters.counties.getCountyData(state);
    const refund = view(lens.data, state);

    return isAbsent(refund)
      ? null
      : {
          ...refund,
          showRefundDescription: false,
          showRefundStatus: true,
          showSimpleRefundStatus: false,
          showOrderSummary: false,
          nonInteractive: true,
          county,
        };
  },
  refundStatus: pipe(view(lens.data), propOr(null, "status")),
};
