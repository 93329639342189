import * as R from "ramda";
import lens from "../lenses/refunds-report";

export default {
  root: R.view(lens),
  data: R.view(lens.data),
  loading: R.view(lens.loading),
  tableView: (state) => {
    const data = R.defaultTo({ report: [] }, R.view(lens.data, state));
    const noData = R.isEmpty(data.report);

    if (noData) {
      return [];
    }

    const columnOrder = [
      "orderId",
      "amount",
      "date",
      "userName",
      "processedBy",
      "status",
      "reason",
    ];

    const pickRows = R.pick(columnOrder);
    const mapRows = R.map((row) => R.values(pickRows(row)));
    const totalPriceRow = ["TOTAL:", data.total];
    const fullRows = R.concat(mapRows(data.report), R.of(totalPriceRow));

    return fullRows;
  },
};
