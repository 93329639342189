import { createAction } from "redux-actions";
import { makeActionTypes } from "../../utils";

export const types = makeActionTypes("COUNTY", ["SET"]);

export const actions = {
  set: createAction(types.SET)
};

export default actions;
