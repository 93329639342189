import React from "react";
import Select from "react-select";
import { setDisplayName } from "recompose";

const customStyles = {
  control: (base, state) => ({
    ...base,
    borderSize: 1,
    backgroundColor: "#ffffff",
    borderColor: state.isFocused ? "#006DD5" : base.borderColor,
    boxShadow: state.isFocused
      ? `inset -1px -1px 2px 0 rgba(0,0,0,0.2),
         inset 1px 1px 2px 0 rgba(0,0,0,0.2),
         -2px -2px 4px 0 rgba(0,109,213,0.2),
         2px 2px 4px 0 rgba(0,109,213,0.2)`
      : 0,
    minHeight: "36px"
  }),
  menu: base => ({
    ...base,
    margin: 0,
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
    borderRadius: 4
  })
};

export default setDisplayName("Select")(props => (
  <Select {...props} styles={customStyles} />
));
