import * as R from "ramda";
import lens from "../lenses/users";

export default {
  root: R.view(lens),
  loading: R.view(lens.loading),
  data: R.view(lens.data),
  error: R.view(lens.error),
  searchId: R.view(lens.searchId),
  byId: (id, state) => {
    const data = R.view(lens.data, state);

    if (!data) return null;

    const user = data[id];

    if (user) return user;

    return null;
  },
  namesAsOptions: state => {
    const data = R.view(lens.data, state);

    if (!data) {
      return [];
    }

    return Object.keys(data).map(id => {
      const user = data[id];
      const fullName = `${user.firstName} ${user.lastName}`;

      return {
        label: fullName,
        value: id,
        infoLabel: user.email
      };
    });
  },
  uniqueNames: state => {
    const data = R.view(lens.data, state);

    if (!data) {
      return [];
    }

    const fullName = user => `${user.firstName} ${user.lastName}`;

    return R.uniqBy(fullName, Object.values(data)).map(user => {
      const userName = fullName(user);

      return {
        label: userName,
        value: userName
      };
    });
  }
};
