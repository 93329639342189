import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'
import Page from './Page'

const DocGroup = ({ className }) => {
  const thickness = BASE * 0.075
  const rx = thickness / 2
  const pageScalar = 0.75

  return e(SVG, { className }, [
    e(Page, {
      key: 'page',
      __x: BASE * (1 - pageScalar),
      __y: 0,
      __width: BASE * pageScalar,
      __height: BASE * pageScalar,
      filled: true
    }),
    e('g', { key: 'first layer', transform: `translate(90 62.5)` }, [
      e('rect', {
        key: 'left',
        x: 0,
        y: 0,
        width: thickness,
        height: BASE * pageScalar,
        rx
      }),
      e('rect', {
        key: 'bottom',
        x: 0,
        y: BASE * pageScalar - thickness,
        width: BASE * 0.7,
        height: thickness,
        rx
      })
    ]),
    e('g', { key: 'second layer', transform: `translate(25 125)` }, [
      e('rect', {
        key: 'left',
        x: 0,
        y: 0,
        width: thickness,
        height: BASE * pageScalar,
        rx
      }),
      e('rect', {
        key: 'bottom',
        x: 0,
        y: BASE * pageScalar - thickness,
        width: BASE * 0.7,
        height: thickness,
        rx
      })
    ])
  ])
}

DocGroup.propTypes = {
  className: PropTypes.string
}

export default DocGroup
