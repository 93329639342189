import React from "react";
import PropTypes from "prop-types";
import { Spinner, NoResults } from "../../../";

const TableWrapper = ({ children }) => (
  <div className="report-page__wrapper">{children}</div>
);

TableWrapper.propTypes = {
  children: PropTypes.any
};

const Content = props => {
  const { Table: TableComponent, isLoading, data = [] } = props;
  const hasData = data.length && data;

  const noResults = (
    <TableWrapper>
      <NoResults />
    </TableWrapper>
  );

  const loading = (
    <TableWrapper>
      <Spinner className="report-page__spinner" />
    </TableWrapper>
  );

  const Table = () => (
    <div className="report-page--search">
      {hasData ? TableComponent : noResults}
    </div>
  );

  return isLoading ? loading : <Table />;
};

Content.displayName = "Content";
Content.propTypes = {
  Table: PropTypes.any,
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.array),
  config: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    cellOptions: PropTypes.object,
    headerOptions: PropTypes.object
  })
};

export default Content;
