import { createAction } from "redux-actions";
import { types as syncTypes } from "@kofile/ko-search-action-types";

export const actions = {
  fetch: Object.assign(createAction(syncTypes.FETCH_SUPPORT_CONFIGURATION_0), {
    fulfilled: createAction(syncTypes.FETCH_SUPPORT_CONFIGURATION_FULFILLED_0),
    rejected: createAction(syncTypes.FETCH_SUPPORT_CONFIGURATION_REJECTED_0)
  })
};

export default actions;
