import * as R from "ramda";
import { set } from "@kofile/redux-lenses";
import * as lenses from "../lenses";
import * as utils from "../../utils";
import { types as syncTypes } from "@kofile/ko-search-action-types";

const { historyByDay } = lenses;

export default {
  [syncTypes.FETCH_HISTORY_BY_DAY_0]: [set(historyByDay.loading).as(true)],

  [syncTypes.FETCH_HISTORY_BY_DAY_FULFILLED_0]: [
    set(historyByDay.data).with(R.path(["payload"])),
    set(historyByDay.loading).as(false)
  ],

  [syncTypes.FETCH_HISTORY_BY_DAY_REJECTED_0]: [
    set(historyByDay.error).with(utils.getReason),
    set(historyByDay.loading).as(false)
  ],

  [syncTypes.FETCH_HISTORY_BY_DAY_CANCELLED_0]: [
    set(historyByDay.error).with(utils.getReason),
    set(historyByDay.loading).as(false)
  ]
};
