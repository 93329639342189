import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'

const Cards = ({ className }) => {
  const spacing = BASE * 0.12
  const boxWidth = (BASE - spacing) / 2
  const boxHeight = (BASE - spacing * 4) / 3
  const row1Y = spacing
  const row2Y = boxHeight + spacing * 2
  const row3Y = boxHeight * 2 + spacing * 3
  const col1X = 0
  const col2X = boxWidth + spacing
  const rx = 30

  return e(SVG, { className }, [
    e('rect', {
      x: col1X,
      y: row1Y,
      width: boxWidth,
      height: boxHeight,
      rx,
      key: '1'
    }),
    e('rect', {
      x: col2X,
      y: row1Y,
      width: boxWidth,
      height: boxHeight,
      rx,
      key: '2'
    }),
    e('rect', {
      x: col1X,
      y: row2Y,
      width: boxWidth,
      height: boxHeight,
      rx,
      key: '3'
    }),
    e('rect', {
      x: col2X,
      y: row2Y,
      width: boxWidth,
      height: boxHeight,
      rx,
      key: '4'
    }),
    e('rect', {
      x: col1X,
      y: row3Y,
      width: boxWidth,
      height: boxHeight,
      rx,
      key: '5'
    }),
    e('rect', {
      x: col2X,
      y: row3Y,
      width: boxWidth,
      height: boxHeight,
      rx,
      key: '6'
    })
  ])
}

Cards.displayName = 'Cards'

Cards.propTypes = {
  className: PropTypes.string
}

export default Cards
