import React from "react";
import PropTypes from "prop-types";

const HeaderCell = ({ value }) => (
  <th className="grouped-table__header-cell">{value}</th>
);

HeaderCell.propTypes = {
  value: PropTypes.any
};

HeaderCell.displayName = "HeaderCell";

export default HeaderCell;
