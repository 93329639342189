import React from "react";
import * as R from "ramda";
import lens from "../lenses/counties";
import getCounty from "./county";

const getters = {
  root: R.view(lens),
  data: R.view(lens.data),
};

const sortBy = R.compose(R.sortBy, R.prop);

export default {
  ...getters,
  asArray: (state) => {
    const counties = getters.data(state);
    const sortByName = sortBy("name");
    return sortByName(R.keys(counties).map((id) => ({ id, ...counties[id] })));
  },
  asOptions:
    (state) =>
    ({ OptionRenderer, goToCounty }) => {
      const counties = getters.data(state);
      const currentCountyId = getCounty(state).id;

      const onClick = (id) => (event) => {
        event.preventDefault();

        goToCounty(id);
      };

      const sortByKey = sortBy("key");

      return sortByKey(
        R.toPairs(counties).reduce((memo, [id, countyConfig]) => {
          const isSelected = currentCountyId === id;

          return [
            ...memo,
            {
              key: countyConfig.name,
              action: onClick(id),
              label: (
                <OptionRenderer
                  isSelected={isSelected}
                  value={`${countyConfig.name} County`}
                />
              ),
            },
          ];
        }, [])
      );
    },
  getCountyData: (state) => {
    const data = getters.data(state);

    return data[state.county] || {};
  },
};
