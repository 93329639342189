import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import styled, { css } from "react-emotion";
import { Form, Field } from "react-final-form";
import { getters } from "../../../../store";
import * as Components from "../../../../components";
import { withProps } from "recompose";
import { connect } from "react-redux";

const fieldSpacing = css`
  & + * {
    margin-top: 15px;
  }
`;

const Group = withProps({ role: "group" })(
  styled("div")`
    padding: 20px;
  `
);

const Footer = styled("div")`
  background-color: rgba(4, 29, 68, 0.03);
  border-top: 1px solid rgba(4, 29, 68, 0.15);
  display: flex;
  justify-content: flex-end;
  padding: 10px;

  & > * {
    flex: 0 0 60px;
  }

  & > * + * {
    margin-left: 10px;
  }
`;

const Input = styled(Components.TextInput)`
  ${fieldSpacing} flex: 1 auto;

  &:invalid {
    box-shadow: none;
  }
`;

export const UserForm = (props) => (
  <Form
    onSubmit={props.onSubmit}
    initialValues={props.initialValues}
    render={({ handleSubmit, pristine, invalid }) => (
      <form onSubmit={handleSubmit}>
        <Components.ErrorBanner error={props.error} />
        <Group
          className={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Field
            name="firstName"
            render={({ input }) => (
              <Input {...input} type="text" required placeholder="First Name" />
            )}
          />

          <Field
            name="lastName"
            render={({ input }) => (
              <Input {...input} type="text" required placeholder="Last Name" />
            )}
          />

          <Field
            name="company"
            render={({ input }) => (
              <Input {...input} type="text" placeholder="Company" />
            )}
          />

          <Field
            name="email"
            render={({ input }) => (
              <Input
                {...input}
                type="email"
                disabled={props.isEditing}
                placeholder="Email"
              />
            )}
          />

          <Field
            name="password"
            render={({ input }) => (
              <Input
                {...input}
                type="text"
                required={!props.isEditing}
                placeholder={props.isEditing ? "New Password" : "Password"}
              />
            )}
          />

          <Field
            name="accountType"
            render={({ input }) => (
              <div className={fieldSpacing}>
                <Components.Select
                  {...input}
                  isSearchable={false}
                  options={props.accountTypes}
                />
              </div>
            )}
          />

          <Field
            name="isActive"
            value="true"
            render={({ input }) => (
              <div className={fieldSpacing}>
                <Components.Checkbox
                  {...input}
                  value="true"
                  checked={R.contains("true", input.value)}
                  id="isActive"
                />
                <label
                  htmlFor="isActive"
                  className={css`
                    margin-left: 8px;
                  `}
                >
                  Active
                </label>
              </div>
            )}
          />

          <Field
            name="isVerified"
            value="true"
            render={({ input }) => (
              <div className={fieldSpacing}>
                <Components.Checkbox
                  {...input}
                  value="true"
                  checked={R.contains("true", input.value)}
                  id="isVerified"
                />
                <label
                  htmlFor="isVerified"
                  className={css`
                    margin-left: 8px;
                  `}
                >
                  Verified
                </label>
              </div>
            )}
          />
        </Group>

        <Footer>
          <Components.Button onClick={props.onCancel}>Cancel</Components.Button>
          <Components.Button
            type="submit"
            disabled={pristine || invalid}
            emphatic
          >
            {props.isEditing ? "Save" : "Create"}
          </Components.Button>
        </Footer>
      </form>
    )}
  />
);

UserForm.propTypes = {
  isEditing: PropTypes.bool,
  onCancel: PropTypes.any,
  accountTypes: PropTypes.any,
  error: PropTypes.any,
  initialValues: PropTypes.any,
  onSubmit: PropTypes.any,
};

const isNotNil = R.complement(R.isNil);

const mapStateToProps = (state, ownProps) => {
  const user = getters.users.byId(ownProps.userId, state);
  const accountTypeOptions = getters.configuration.accountTypesAsOptions(state);

  if (R.isNil(user) || !ownProps.isEditing) {
    return {
      accountTypes: accountTypeOptions,
      accountType: accountTypeOptions[0],
    };
  }

  const { id } = getters.county(state);
  const error = getters.user.error(state);
  const mapCheckboxValue = (isTrue) => (isTrue ? ["true"] : []);
  const roles = R.propOr([], "roles", user);
  const countyRoles = roles.filter((role) => role.tenant === id);
  const supportRole = roles.find((role) => role.roleId === 2);

  const generateAccountType = () => {
    if (isNotNil(supportRole)) return supportRole;

    const getDropdownInfo = (data) => {
      const byRole = (input) => R.prop("roleId", data) === input.code;
      return accountTypeOptions.find(byRole) || accountTypeOptions[0];
    };

    if (countyRoles.length === 1) return getDropdownInfo(countyRoles);

    const highestRole = countyRoles.reduce((max, current) => {
      return current.roleId > max.roleId ? current : max;
    }, accountTypeOptions[0]);

    return getDropdownInfo(highestRole);
  };

  return {
    error,
    accountTypes: accountTypeOptions,
    initialValues: {
      accountType: generateAccountType(),
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      isVerified: mapCheckboxValue(user.isVerified),
      isActive: mapCheckboxValue(user.isActive),
    },
  };
};

export default connect(mapStateToProps)(UserForm);
