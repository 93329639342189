import PropTypes from 'prop-types'
import { e, SVG } from '../utils'
import JumpTriangle from './JumpTriangle'

const JumpTriangleReverse = ({ className }) =>
  e(SVG, { className }, e(JumpTriangle, { rotation: 180 }))

JumpTriangleReverse.displayName = 'JumpTriangleReverse'

JumpTriangleReverse.propTypes = {
  className: PropTypes.string
}

export default JumpTriangleReverse
