import React from "react";
// import { Link } from 'react-router-dom'
import { ErrorBanner } from "../components";

export default Object.assign(
  ({ form, errorBanner }) => (
    <form className="sign-in" onSubmit={form.submit}>
      <h2 className="sign-in__title">Sign In</h2>

      <div className="sign-in__error">
        <ErrorBanner {...errorBanner} />
      </div>

      <label className="sign-in__email">
        <input
          required
          type="email"
          placeholder="Email"
          className="input--field"
          value={form.values.email}
          onChange={form.update.email}
          data-testid="loginEmail"
        />
      </label>

      <label className="sign-in__password">
        <input
          required
          type="password"
          placeholder="Password"
          className="input--field"
          value={form.values.password}
          onChange={form.update.password}
          data-testid="loginPassword"
        />
      </label>

      {/* <label className='sign-in__remember-me'>
      <input
        type='checkbox'
        value={form.values.rememberMe}
        onChange={form.update.rememberMe}
      />
      <span className='checkbox__label'>Remember Me</span>
    </label>

    <Link to='/forgot-password' className='sign-in__forgot-password'>
      Forgot Password?
    </Link> */}

      <button
        type="submit"
        disabled={form.isLoading}
        className="sign-in__button"
        data-testid="loginButton"
      >
        {form.isLoading ? "Loading..." : "Sign In"}
      </button>
    </form>
  ),
  { displayName: "SignIn" }
);
