import * as R from "ramda";

const root = R.lensProp("billing-report");
const data = R.compose(
  root,
  R.lensProp("data")
);
const error = R.compose(
  root,
  R.lensProp("error")
);
const loading = R.compose(
  root,
  R.lensProp("isLoading")
);

export default Object.assign(root, {
  data,
  error,
  loading
});
