import * as R from "ramda";
import * as Rx from "rxjs/Rx";
import format from "date-fns/format";

export { default as withErrorPage } from "./withErrorPage";

/**
 * A basic lens
 *
 * @typedef Lens
 * @type {function}
 */

/**
 * Our basic helpers
 *
 * @typedef {Object} SetHelper
 * @property {Function} as - Sets the value as this value
 * @property {Function} with - Sets the value using state and payload
 * @property {Function} using - Sets the value using state, payload, and prev
 */

/**
 * The Settings type for our withData function
 *
 * @typedef {Object} WithDataSettings
 * @property {Function} required -> state -> *
 * @property {Function} request - (state, dispatch) -> null
 * @property {Component} Loading - Widget to render on loading
 */

/**
 * HoF that prepends a string with the given domain
 *
 * EXAMPLE:
 *
 *    'users' => 'UPDATE' => 'users/UPDATE'
 *
 * @param {string} domain - The domain of the string
 * @returns {function} - str => str
 */
export const domainify = domain => str => [domain, str].join("/");

/**
 * Creates a hash of namespaced types
 *
 * EXAMPLE:
 *
 *    makeActionTypes('users',['UPDATE','REMOVE'])
 *    // { UPDATE: 'users/UPDATE', REMOE: 'users/REMOVE' }
 *
 * @param {string} namespace - The namespace/domain of the types
 * @param {Array<string>} types - The types to add to the domain
 * @returns {object} - { [TYPE]: <NAMESPACED_TYPE> }
 */
export const makeActionTypes = (namespace, types) => {
  const inDomain = domainify(namespace);

  return types.reduce(
    (memo, type) => ({
      ...memo,
      [type]: inDomain(type)
    }),
    {}
  );
};

/**
 * HoF that grabs a property from an object
 *
 *  EXAMPLE:
 *
 *    obj -> key -> obj[key]
 *
 * @param {object} obj - The object to grab values from
 * @returns {function} - str -> * | A function that returns the val at key
 */
export const fromObject = R.flip(R.prop);

/**
 * Helper function for setting state via reduers
 *
 *  EXAMPLE:
 *
 *    set(R.lensProp('a)).as(true)
 *    set(R.lensProp('a)).with((action, payload) => true)
 *    set(R.lensProp('a)).using((action, payload) => prevVal => true)
 *
 * @param {Lens} lens - The lens to the data to set
 * @returns {SetHelper} - Our helper functions
 */
export const set = lens => ({
  with: setter => [lens, setter, "set"],
  as: value => [lens, R.always(value), "set"],
  using: setter => [lens, setter, "over"]
});

/**
 * Specifies custom "data" property for "getIDs"
 *
 * @param {Object} prop - An object of { payload: { customData: [{}] } }
 * @return {function} - `getIDs` function
 */
export const getIDsBy = prop =>
  R.compose(
    R.map(R.prop("id")),
    R.path(["payload", prop])
  );

/**
 * Grabs the ids from the action payload
 *
 * @example
 *
 *    { payload: { data: [{ id: 1} ] } } -> [1]
 * @function
 *
 * @param {Object} action - An object of { payload: { data: [{}] } }
 * @return {Array<number|string>} - An array of ids
 */
export const getIDs = getIDsBy("data");

/**
 * Specifies custom "data" property for "getData"
 *
 * @param {Object} prop - An object of { payload: { customData: [{}] } }
 * @return {function} - `getData` function
 */
export const getDataBy = prop =>
  R.compose(
    R.reduce((f, c) => R.merge(f, { [c.id]: c }), {}),
    R.path(["payload", prop])
  );

/**
 * Grabs the data from the action payload
 *
 * @example
 *
 *    { payload: { data: [{ id: 1} ] } } -> { 1: { id: 1 } }
 * @function
 *
 * @param {Object} action - An object of { payload: { data: [{}] } }
 * @return {Array<number|string>} - An object of data keyed by id
 */
export const getData = getDataBy("data");

/**
 * Grabs the errors from the action payload
 *
 * @example
 *
 *    { payload: { reason: 'ABC' } } -> 'ABC'
 * @function
 *
 * @param {Object} action - An object of { payload: { reason: 'ABC' }  }
 * @return {Array<number|string>} - The reason of the error
 */
export const getReason = R.path(["payload", "reason"]);

/**
 * Mocks the `@kofile/redux-lenses` reducer
 */
export const mockSetterCall = (action, state) => setters =>
  setters.reduce(
    (acc, [lens, setter, method]) => R[method](lens, setter(action, acc), acc),
    state
  );

/**
 * Empty function
 */
export const noop = R.always(void 0);

/**
 * Random string
 */
export const randomString = () =>
  Math.random()
    .toString(36)
    .substring(2, 15) +
  Math.random()
    .toString(36)
    .substring(2, 15);

export const timeout = handlers => {
  const cancel = new Rx.Subject();

  Rx.Observable.merge(
    Rx.Observable.of("SHORT").delay(2000),
    Rx.Observable.of("LONG").delay(5000),
    Rx.Observable.of("BAIL").delay(10000)
  )
    .takeUntil(cancel)
    .subscribe(id => {
      const handler = handlers[id];

      handler();
    });

  return cancel;
};

/**
 * Returns true if the input is *either* empty *or* nil-like. False otherwise.
 *
 * @see http://ramdajs.com/docs/#isNil
 * @see http://ramdajs.com/docs/#isEmpty
 *
 * @param {*}
 * @returns {boolean}
 */
export const emptyOrNil = R.anyPass([R.isNil, R.isEmpty]);

/**
 * Formats date as M/D/YYYY
 *
 * @example
 *
 * formatDate('2018-10-01T21:30:16+00:00') -> '10/1/2018'
 *
 * @param {String} date - UTC date
 */
export const formatDate = (date, dateFormat) => {
  if (dateFormat) {
    return format(date, dateFormat);
  }

  return format(date, "M/D/YYYY");
};

/**
 * Gets county id from props and defaults to null
 *
 * @example
 *
 * getCountyId({ countyId: 5 }) => 5
 *
 * @param {Object}
 */

export const getCountyId = props => R.pathOr(null, ["county", "id"], props);
