import PropTypes from 'prop-types'
import { SVG, e } from '../utils'

const Quicklink = ({ className, id = '' }) => {
  const viewBox = `0 0 25 17`

  return e(SVG, { viewBox, className }, [
    e(
      'defs',
      { key: 'defs' },
      e('mask', { id: `QuicklinkMask${id}` }, [
        e('path', { key: 'fill', fill: 'white', d: 'M0 0H25V17H0Z' }),
        e('path', {
          d:
            'M2.332 16l3.96-3.961 1.617 3.233L11 6 1.728 9.09l3.234 1.616L1 14.668z',
          fill: 'black',
          stroke: 'black',
          strokeWidth: 2,
          key: 'arrow'
        }),
        e('rect', {
          key: 'bottom left',
          fill: 'black',
          x: 5.9,
          y: 7.8,
          width: 8.7,
          height: 2.6
        }),
        e('rect', {
          key: 'bottom right',
          fill: 'black',
          x: 15.5,
          y: 7.8,
          width: 8.7,
          height: 2.6
        }),
        e('rect', {
          key: 'top left',
          fill: 'black',
          x: 5.9,
          y: 4.3,
          width: 8.7,
          height: 2.6
        }),
        e('rect', {
          key: 'top right',
          fill: 'black',
          x: 15.5,
          y: 4.3,
          width: 8.7,
          height: 2.6
        })
      ])
    ),
    e('path', {
      key: 'grid',
      mask: `url(#QuicklinkMask${id})`,
      d:
        'M15.435 6.957h8.695v-2.61h-8.695v2.61zm0 3.478h8.695V7.826h-8.695v2.609zm8.695 2.102c0 .659-.645 1.376-1.304 1.376h-7.391v-2.609h8.695v1.233zM5.87 6.957h8.695v-2.61H5.87v2.61zm0 3.478h8.695V7.826H5.87v2.609zm8.695 3.478H7.174c-.66 0-1.304-.717-1.304-1.376v-1.233h8.695v2.609zM22.717 0H7.283A2.282 2.282 0 0 0 5 2.283V12.5a2.282 2.282 0 0 0 2.283 2.283h15.434A2.282 2.282 0 0 0 25 12.5V2.283A2.282 2.282 0 0 0 22.717 0z'
    }),
    e('path', {
      d:
        'M2.332 16l3.96-3.961 1.617 3.233L11 6 1.728 9.09l3.234 1.616L1 14.668z',
      key: 'arrow'
    })
  ])
}

Quicklink.propTypes = {
  className: PropTypes.string
}

export default Quicklink
