import * as R from "ramda";
import { set } from "@kofile/redux-lenses";
import { types as syncTypes } from "@kofile/ko-search-action-types";
import * as lenses from "../lenses";
import { newApiTypes } from "../actions/refund";

const root = lenses.refund;

export default {
  [syncTypes.PROCEED_REFUND_REJECT_0]: [set(root.loading).as(true)],
  [syncTypes.PROCEED_REFUND_REJECT_REJECTED_0]: onRejected(),
  [syncTypes.PROCEED_REFUND_COMPLETE_REJECTED_0]: onRejected(),
  [syncTypes.PROCEED_REFUND_REJECT_FULFILLED_0]: [
    set(root.loading).as(false),
    set(root.error).as(undefined)
  ],
  [newApiTypes.fetchRefund]: [
    set(root.loading).as(true),
    set(root.data).as(undefined)
  ],
  [newApiTypes.refund]: [
    set(root.data).with(R.path(["payload"])),
    set(root.loading).as(false)
  ],
  [newApiTypes.errorFetchingRefund]: [
    set(root.error).with(R.path(["payload", "reason", "message"])),
    set(root.loading).as(false),
    set(root.data).as(undefined)
  ]
};

function onRejected() {
  return [
    set(root.error).with(({ payload }) => R.head(payload.errors)),
    set(root.loading).as(false)
  ];
}
