import React from "react";
import PropTypes from "prop-types";

const Info = ({ countyName, searchMetrics, searchTerm }) => (
  <div className="print-info">
    <h1 className="print-info--title">{countyName} County</h1>
    <p className="print-info--metrics">
      Search Metrics by {searchMetrics}/Date | {searchTerm}
    </p>
  </div>
);

Info.propTypes = {
  countyName: PropTypes.string,
  searchMetrics: PropTypes.string,
  searchTerm: PropTypes.string
};

export default Info;
