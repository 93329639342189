import { set } from "@kofile/redux-lenses";
import { types } from "../actions/socket";
import * as lenses from "../lenses";
import * as utils from "../../utils";

const { socket } = lenses;

export default {
  [types.OPENED]: [
    set(socket.isOpened).as(true),
    set(socket.isClosed).as(false),
    set(socket.isRetrying).as(false)
  ],
  [types.CLOSED]: [
    set(socket.isOpened).as(false),
    set(socket.isClosed).as(true),
    set(socket.isRetrying).as(false)
  ],
  [types.RETRYING]: [
    set(socket.isOpened).as(false),
    set(socket.isClosed).as(false),
    set(socket.isRetrying).as(true)
  ],
  [types.ERROR]: [
    set(socket.isOpened).as(false),
    set(socket.isClosed).as(false),
    set(socket.isRetrying).as(false),
    set(socket.error).with(utils.getReason)
  ]
};
