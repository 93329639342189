import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'

const Triangle = ({ className, rotation, filled, id = '' }) => {
  const a = BASE * 0.68
  const b = BASE / 2

  return e(
    SVG,
    { className },
    e('g', { transform: `rotate(${rotation} ${BASE / 2} ${BASE / 2})` }, [
      e(
        'defs',
        { key: 'defs' },
        e('mask', { id: `TriangleMask${id}` }, [
          e('path', {
            key: 'outer',
            d: `M0 0L${a} ${b}L0 ${BASE}Z`,
            fill: 'white',
            transform: `translate(${BASE * 0.16})`
          }),
          !filled &&
            e('path', {
              key: 'inner',
              d: `M0 0L${a / 2} ${b / 2}L0 ${BASE / 2}Z`,
              fill: 'black',
              transform: `translate(140 ${b / 2})`
            })
        ])
      ),
      e('rect', {
        key: 'fill',
        x: 0,
        y: 0,
        width: BASE,
        height: BASE,
        mask: `url(#TriangleMask${id})`
      })
    ])
  )
}

Triangle.displayName = 'Triangle'

Triangle.propTypes = {
  className: PropTypes.string,
  rotation: PropTypes.oneOf([0, 90, 180, 270]),
  filled: PropTypes.bool.isRequired
}

Triangle.defaultProps = {
  rotation: 0,
  filled: false
}

export default Triangle
