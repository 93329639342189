import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'
import Close from './Close'

const Clear = ({ className, id = '' }) =>
  e(SVG, { className }, [
    e(
      'defs',
      { key: 'defs' },
      e('mask', { id: `x${id}` }, [
        e('circle', {
          cx: BASE / 2,
          cy: BASE / 2,
          r: BASE / 2,
          fill: 'white',
          key: 'circle'
        }),
        e(Close, {
          key: 'close',
          __width: BASE * 0.6,
          __x: BASE / 2 - BASE * 0.3
        })
      ])
    ),
    e('path', {
      d: `M0 0H${BASE}V${BASE}H0Z`,
      key: 'block',
      mask: `url(#x${id})`
    })
  ])

Clear.displayName = 'Clear'

Clear.propTypes = {
  className: PropTypes.string
}

export default Clear
