import { set } from "@kofile/redux-lenses";
import * as R from "ramda";
import * as utils from "../../utils";
import { newApiTypes, types } from "../actions/billing-report";
import * as lenses from "../lenses";

const { billingReport } = lenses;

export default {
  [newApiTypes.fetch]: [set(billingReport.loading).as(true)],

  [newApiTypes.fulfilled]: [
    set(billingReport.data).with(R.path(["payload"])),
    set(billingReport.loading).as(false),
  ],

  [newApiTypes.reject]: [
    set(billingReport.error).with(utils.getReason),
    set(billingReport.loading).as(false),
  ],

  [types.RESET]: [set(billingReport).as({})],
};
