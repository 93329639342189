import React from "react";
import * as R from "ramda";
import { BodyRow } from "../";
import PropTypes from "prop-types";

const Group = props => {
  const { groupData } = props;
  const [firstRow, ...rest] = groupData;
  const lastRow = R.last(rest);
  const middleRows = R.slice(0, -1, rest);
  const middleRowsView = middleRows.map((rowData, i) => (
    <BodyRow key={i} rowData={rowData} className="grouped-table__row" />
  ));

  return (
    <React.Fragment>
      <BodyRow
        rowData={firstRow}
        className="grouped-table__row grouped-table__first-row"
      />
      {R.isEmpty(middleRows) ? null : middleRowsView}
      <BodyRow
        rowData={lastRow}
        className="grouped-table__row grouped-table__last-row"
      />
    </React.Fragment>
  );
};

Group.displayName = "Group";

Group.propTypes = {
  groupData: PropTypes.any
};

export default Group;
