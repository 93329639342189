import React from "react";
import PropTypes from "prop-types";
import { Report } from "../../../components";
import A11yTable from "@kofile/react-a11y-table/A11yTable";
import connect, { tableConfig } from "./connect";

const reportType = "Billing";
const searchTitle = "Billing Activity";
const searchDescription =
  "Look up billing activity by entering a start date and end date.";

export const BillingReport = props => (
  <Report
    widgetTitle={searchTitle}
    searchTitle={searchTitle}
    searchDescription={searchDescription}
    reportType={reportType}
    Table={<A11yTable data={props.data} config={tableConfig} />}
    {...props}
  />
);

BillingReport.displayName = "BillingReport";
BillingReport.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array)
};

export default connect(BillingReport);
