import React from "react";
import styled from "react-emotion";
import { components } from "react-select";
import PropTypes from "prop-types";

const Highlight = styled("span")`
  font-weight: bold;
`;

const Wrapper = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const Info = styled("span")`
  color: rgba(4, 29, 68, 0.6);
`;

export default Object.assign(
  props => {
    const { inputValue: searchTerm } = props.selectProps;
    const { label, infoLabel = "" } = props.data;
    const { Option } = components;
    const regex = new RegExp(`(${searchTerm})`, "gi");

    const newLabel = label
      .replace(infoLabel, "")
      .trim()
      .split(regex)
      .map((part, index) => {
        const key = `${part}-${index}`;

        if (regex.test(part)) {
          return <Highlight key={key}>{part}</Highlight>;
        }

        return <span key={key}>{part}</span>;
      });

    const newInfoLabel = infoLabel.split(regex).map((part, index) => {
      const key = `${part}-${index}`;

      if (regex.test(part)) {
        return <Highlight key={key}>{part}</Highlight>;
      }

      return <span key={key}>{part}</span>;
    });

    return (
      <Option {...props}>
        <Wrapper>
          <span>{newLabel}</span>
          <Info>{newInfoLabel}</Info>
        </Wrapper>
      </Option>
    );
  },
  {
    propTypes: {
      selectProps: PropTypes.any,
      data: PropTypes.any
    }
  }
);
