import React from "react";
import * as R from "ramda";
import PropTypes from "prop-types";
import classnames from "classnames";
import { noop } from "../../utils";

const tableClassName = className => classnames("receipt-table", className);

// eslint-disable-next-line react/display-name
const TableHeaderCell = (column, i) => {
  const conditionalRender = R.cond([
    [R.has("renderHeader"), ({ renderHeader }) => renderHeader()],
    [R.T, R.always(column.name)]
  ]);

  const headerClassName = classnames(
    "receipt-table--header-column",
    column.headerClassName
  );

  return (
    <th
      key={`table-header-column-${i}`}
      className={headerClassName}
      onClick={R.isNil(column.action) ? noop : column.action}
    >
      {conditionalRender(column)}
    </th>
  );
};

const TableHead = ({ metadata }) => (
  <thead className="receipt-table--header">
    <tr className="receipt-table--header-row">
      {metadata.columns.map(TableHeaderCell)}
    </tr>
  </thead>
);

TableHead.displayName = "TableHead";
TableHead.propTypes = {
  metadata: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string
      })
    )
  })
};

const TableBodyRow = ({ metadata, entity }) => {
  const { rowProps = noop } = metadata;
  const resolvedRowProps = rowProps(entity);
  const bodyCellsMapped = metadata.columns.map((column, i) => (
    <TableBodyCell
      key={`table-body-cell-${i}`}
      column={column}
      cell={R.prop(column.key, entity)}
      entity={entity}
    />
  ));

  return <tr {...resolvedRowProps}>{bodyCellsMapped}</tr>;
};

TableBodyRow.displayName = "TableBodyRow";
TableBodyRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  entity: PropTypes.object,
  metadata: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string
      })
    )
  })
};

const TableBodyCell = ({ column, cell, entity }) => {
  const tdClass = classnames("receipt-table--body-cell", column.cellClassName);

  const conditionalRender = R.cond([
    [R.has("render"), config => config.render({ cell, entity })],
    [R.T, R.always(cell)]
  ]);

  return <td className={tdClass}>{conditionalRender(column)}</td>;
};

TableBodyCell.displayName = "TableBodyCell";
TableBodyCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  cell: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  entity: PropTypes.object,
  column: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string
  })
};

const TableBody = ({ metadata, data }) => (
  <tbody>
    {data.map((entity, i) => (
      <TableBodyRow
        key={`table-body-row-${i}`}
        metadata={metadata}
        entity={entity}
      />
    ))}
  </tbody>
);

TableBody.displayName = "TableBody";
TableBody.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  metadata: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string
      })
    )
  })
};

const Table = ({ metadata, data, className }) => (
  <table className={tableClassName(className)}>
    <TableHead metadata={metadata} />
    <TableBody metadata={metadata} data={data} />
  </table>
);

Table.displayName = "Table";
Table.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  metadata: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string
      })
    )
  })
};

export { TableHead, TableBody, TableBodyRow, TableBodyCell, TableHeaderCell };

export default Table;
