import React from "react";
import PropTypes from "prop-types";

const BodyCell = ({ value }) => (
  <td className="grouped-table__body-cell">{value}</td>
);

BodyCell.displayName = "BodyCell";

BodyCell.propTypes = {
  value: PropTypes.string
};

export default BodyCell;
