import { createAction } from "redux-actions";
import { makeActionTypes } from "../../utils";
/**
 * TYPES
 */
export const types = makeActionTypes("ERRORS", [
  "REDUCER_ERROR",
  "GLOBAL_ERROR"
]);

/**
 * ACTIONS
 */
export const actions = {
  reducer: createAction(types.REDUCER_ERROR),
  global: createAction(types.GLOBAL_ERROR)
};

export default actions;
