import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "../../../store";
import { Autocomplete } from "../../../components";

const mapStateToProps = (state) => ({
  matches: getters.users.uniqueNames(state),
  isLoading: getters.users.loading(state),
});

const mapDispatchToProps = (dispatch) => ({
  search: (term) => dispatch(actions.users.autocomplete(term)),
  cancel: () => dispatch(actions.users.cancel()),
  load: () => dispatch(actions.users.loading()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => (
  <div className="search">
    <Autocomplete
      select={props.handleSelect}
      load={props.load}
      cancel={props.cancel}
      search={props.search}
      isLoading={props.isLoading}
      noOptionsMessage={{ none: "No users found." }}
      options={props.matches}
      placeholder="Search by Name"
      selectedOption={props.userName}
    />
  </div>
));
