import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'
import collapseTransforms from '../collapseTransforms'

const Bar = props => e('rect', props)

const barMaker = preloadProps => props =>
  e(Bar, Object.assign({}, preloadProps, props))

const Close = ({ className, __width, __height, __x, __y }) => {
  const thickness = BASE * 0.2
  const rx = thickness / 2
  const length = Math.sqrt(2) * (BASE - thickness)
  const makeBar = barMaker({
    x: 0,
    y: 0,
    width: length,
    height: thickness,
    rx
  })

  const bar1Transform = collapseTransforms([
    `translate(${BASE - rx} 0)`,
    `rotate(135 0 ${rx})`
  ])

  const bar2Transform = collapseTransforms([
    `translate(${rx} 0)`,
    `rotate(45 0 ${rx})`
  ])

  const bar1 = makeBar({ key: 'bar1', transform: bar1Transform })
  const bar2 = makeBar({ key: 'bar2', transform: bar2Transform })

  return e(
    SVG,
    { className, width: __width, height: __height, x: __x, y: __y },
    [bar1, bar2]
  )
}

Close.displayName = 'Close'

Close.propTypes = {
  className: PropTypes.string,
  __width: PropTypes.number,
  __height: PropTypes.number,
  __x: PropTypes.number,
  __y: PropTypes.number
}

Close.defaultProps = {
  __width: null,
  __height: null,
  __x: null,
  __y: null
}

export default Close
