import { types as syncTypes } from "@kofile/ko-search-action-types";
import { set } from "@kofile/redux-lenses";
import { format } from "date-fns";
import * as R from "ramda";
import { getReason } from "../../utils";
import { formatPrice } from "../../utils/money";
import { types } from "../actions/orders";
import * as lenses from "../lenses";

const root = lenses.orders;

const getIDs = R.compose(R.map(R.prop("id")), R.path(["payload", "orders"]));

export default {
  [syncTypes.FETCH_ORDERS_0]: [
    set(root.loading).as(true),
    set(root.userTerm).with(R.view(R.lensPath(["payload", "userName"]))),
    set(root.orderTerm).with(R.view(R.lensPath(["payload", "orderNumber"]))),
    set(root.error).with(R.always(null)),
  ],
  [syncTypes.FETCH_ORDERS_FULFILLED_1]: [
    set(root.data).with(({ payload }) =>
      payload.orders.map((order) => {
        return Object.assign(order, {
          createdOn: format(order.createdOn, "MM/DD/YYYY"),
          amount: formatPrice(order.amount),
        });
      })
    ),
    set(root.ids).with(getIDs),
    set(root.loading).as(false),
    set(root.error).with(R.always(null)),
    set(root.pagination).with(({ payload }) => {
      const { size, total } = payload.pagination;

      return {
        ...payload.pagination,
        totalPages: Math.ceil(total / size),
      };
    }),
  ],
  [syncTypes.FETCH_ORDERS_REJECTED_0]: [
    set(root.error).with(getReason),
    set(root.loading).as(false),
  ],
  [syncTypes.FETCH_ORDERS_CANCELLED_0]: [
    set(root.error).with(getReason),
    set(root.loading).as(false),
  ],
  [types.RESET]: [set(root.data).as([])],
  [types.SET_SORT]: [set(root.sortBy).with(({ payload }) => payload)],
};
