import React from "react";
import { Widget } from "../../../components";
import styled from "react-emotion";
import { Link as RRLink } from "react-router-dom";

const Links = styled("ul")`
  list-style: none;
  padding: 10px 20px;
  line-height: 35px;
`;

const Link = styled(RRLink)`
  color: #006dd5;
  text-decoration: none;
`;

export default Object.assign(
  (props) => (
    <Widget className={props.className} title="Reports">
      <nav>
        <Links>
          {/* <li>
          <Link to='reports/users-activity'>Search Metrics by User/Date</Link>
        </li>
        <li><Link to='reports/history-by-day'>History By Day</Link></li>
        <li><Link to='reports/subscriptions'>Subscription History</Link></li> */}
          <li>
            <Link to="reports/billing">Billing Activity</Link>
          </li>
          <li>
            <Link to="reports/refunds">Refund Activity</Link>
          </li>
          {props.displayRsIdSearch && (
            <li>
              <Link to="reports/import-status">Check Data Import by RsId</Link>
            </li>
          )}
        </Links>
      </nav>
    </Widget>
  ),
  {
    displayName: "WidgetLinks",
  }
);
