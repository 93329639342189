import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters } from "../../store";

const makeClassNames = status =>
  classnames("online-status", {
    "online-status--is-connected": status.isOpened,
    "online-status--is-disconnected": status.isClosed,
    "online-status--is-connecting": status.isRetrying
  });

const makeMessage = R.cond([
  [R.prop("isOpened"), R.always("Connected!")],
  [R.prop("isClosed"), R.always("You are offline!")],
  [
    R.prop("isRetrying"),
    R.always(
      <div className="online-status__ellipsis">Attempting to reconnect</div>
    )
  ]
]);

export const OnlineStatus = props => (
  <div className={makeClassNames(props)}>{makeMessage(props)}</div>
);

OnlineStatus.displayName = "OnlineStatus";
OnlineStatus.propTypes = {
  isOpened: PropTypes.bool,
  isClosed: PropTypes.bool,
  isRetrying: PropTypes.bool
};

const mapStateToProps = state => ({
  isOpened: getters.socket.isOpened(state),
  isClosed: getters.socket.isClosed(state),
  isRetrying: getters.socket.isRetrying(state)
});

export default connect(mapStateToProps)(OnlineStatus);
