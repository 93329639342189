import * as R from "ramda";

const root = R.lensProp("socket");
const isOpened = R.compose(
  root,
  R.lensProp("isOpened")
);
const isRetrying = R.compose(
  root,
  R.lensProp("isRetrying")
);
const isClosed = R.compose(
  root,
  R.lensProp("isClosed")
);
const error = R.compose(
  root,
  R.lensProp("error")
);

export default Object.assign(root, {
  isOpened,
  isRetrying,
  isClosed,
  error
});
