import React from "react";
import PropTypes from "prop-types";
import dialogClose from "../../../public/assets/img/icon_dialog_close.svg";

const Title = ({ title }) => {
  if (!title) {
    return null;
  }

  return <div className="modal__title">{title}</div>;
};

Title.propTypes = {
  title: PropTypes.string
};

const Cancel = ({ onCancel }) => {
  if (!onCancel) {
    return null;
  }

  return (
    <button className="modal__cancel" onClick={onCancel}>
      Cancel
    </button>
  );
};

Cancel.propTypes = {
  onCancel: PropTypes.string
};

const Submit = ({ onSubmit, disabled = false }) => {
  if (!onSubmit) {
    return null;
  }

  return (
    <button
      disabled={disabled}
      className="modal__submit"
      onClick={onSubmit}
      data-testid="submitButton"
    >
      Submit
    </button>
  );
};

Submit.propTypes = {
  onSubmit: PropTypes.any,
  disabled: PropTypes.bool
};

const Footer = ({ onSubmit, onCancel, submitDisabled }) => {
  if (!onSubmit && !onCancel) {
    return null;
  }

  return (
    <div className="modal__footer">
      <Cancel onCancel={onCancel} />
      <Submit onSubmit={onSubmit} disabled={submitDisabled} />
    </div>
  );
};

Footer.propTypes = {
  onSubmit: PropTypes.any,
  onCancel: PropTypes.any,
  submitDisabled: PropTypes.bool
};

class Modal extends React.Component {
  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <div className="modal">
        <div className="modal__shell">
          <button className="modal__close" onClick={this.props.close}>
            <img src={dialogClose} alt="close modal" />
          </button>
          <Title {...this.props} />
          <div className="modal__body">{this.props.children}</div>
          <Footer {...this.props} />
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  show: PropTypes.bool.isRequired,
  close: PropTypes.func,
  children: PropTypes.any
};

export default Modal;
