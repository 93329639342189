import React from "react";
import { cx } from "emotion";
import PropTypes from "prop-types";
import iconErrorTraingle from "../../../public/assets/img/icon_error_triangle.svg";

const View = props => (
  <div className={cx(props.className, "error-banner")}>
    <img
      className="error-banner__warning-icon"
      alt="warning"
      src={props.warningIcon}
    />
    {props.error}
  </div>
);

View.propTypes = {
  error: PropTypes.any,
  warningIcon: PropTypes.any,
  className: PropTypes.string
};

const ErrorBanner = props => (props.error ? <View {...props} /> : null);

ErrorBanner.displayName = "ErrorBanner";

ErrorBanner.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string
};

ErrorBanner.defaultProps = {
  error: "",
  warningIcon: iconErrorTraingle
};

export default ErrorBanner;
