import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { actions, getters } from "../../../store";

export const tableConfig = {
  cellOptions: {
    on: {}
  },
  headerOptions: {
    on: {}
  },
  rowOptions: {
    on: {}
  },
  columns: [
    {
      name: "userName",
      label: "USERNAME"
    },
    {
      name: "userId",
      label: "USER ID"
    },

    {
      name: "date",
      label: "Date"
    },
    {
      name: "searches",
      label: "# SEARCHES"
    },
    {
      name: "orders",
      label: "# ORDERS"
    }
  ]
};

const mapDispatchToProps = dispatch => ({
  fetchData: searchData => dispatch(actions.usersActivity.fetch(searchData))
});

const mapStateToProps = state => ({
  data: getters.usersActivity.tableView(state),
  isLoading: getters.usersActivity.loading(state),
  county: getters.county(state)
});

export default R.compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
);
