import PropTypes from 'prop-types'
import { SVG, e, BASE, toRadians } from '../utils'

const EndTriangle = ({ rotation, size, x, y, o }) => {
  const viewBox = `0 0 ${size} ${size}`

  return e(
    SVG,
    { viewBox, width: size, height: size, x, y },
    e('path', {
      d: `M0 0 V${size} L${o} ${size / 2}Z`,
      transform: `rotate(${rotation} ${o / 2} ${size / 2})`
    })
  )
}

EndTriangle.propTypes = {
  rotation: PropTypes.oneOf([0, 180]).isRequired,
  size: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired
}

EndTriangle.defaultProps = {
  x: 0,
  y: 0,
  rotation: 0
}

const Resize = ({ rotation, className }) => {
  const half = BASE / 2
  const thickness = BASE * 0.08
  const halfThick = thickness / 2
  const triangleSize = 160
  const theta = toRadians(50)
  const h = triangleSize / 2 / Math.cos(theta)
  const o = Math.sin(theta) * h

  return e(
    SVG,
    { className },
    e('g', { transform: `rotate(${rotation} ${BASE / 2} ${BASE / 2})` }, [
      e('rect', {
        key: 'arrow',
        x: o,
        y: half - halfThick,
        width: BASE - o * 2,
        height: thickness
      }),
      e(EndTriangle, {
        key: 'right',
        size: triangleSize,
        o,
        x: BASE - o,
        y: BASE / 2 - triangleSize / 2
      }),
      e(EndTriangle, {
        key: 'left',
        size: triangleSize,
        o,
        x: 0,
        y: BASE / 2 - triangleSize / 2,
        rotation: 180
      })
    ])
  )
}

Resize.propTypes = {
  rotation: PropTypes.oneOf([0, 90]).isRequired,
  className: PropTypes.string
}

Resize.defaultProps = {
  rotation: 0
}

export default Resize
