import React from "react";
import { contains, complement, isNil } from "ramda";
import { css } from "emotion";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ErrorBanner, Receipt as OrderReceipt } from "../../../components";

const styles = {
  errorBanner: css`
    border-radius: 4px;
    margin-bottom: 10px;
  `,
  receiptBottomMargin: css`
    dl:last-of-type {
      margin-bottom: 16px;
    }
  `,
};

const isNotNil = complement(isNil);

const shouldShowButton = (status, hasRefund) => (buttonType) =>
  contains(status, ["approved", buttonType]) && hasRefund;

const Refund = ({ county, error, complete, status, match, refund }) => {
  const hasRefund = isNotNil(refund);
  const showButton = shouldShowButton(status, hasRefund);

  return (
    <section className="refund">
      <Link to={`/${county}/refunds`} className="order-receipt-header--title">
        ← Back to Refunds
      </Link>
      <div className="refund__receipt-wrapper">
        <ErrorBanner className={styles.errorBanner} error={error} />
        {hasRefund && (
          <OrderReceipt className={styles.receiptBottomMargin} {...refund} />
        )}
      </div>
      <div className="refund--buttons">
        {showButton("rejected") && (
          <button
            className="refund--button blue"
            data-testid="completeRefund"
            onClick={complete}
          >
            Complete {status === "rejected" ? "Rejection" : "Refund"}
          </button>
        )}
        {showButton("submitted") && (
          <Link
            to={`${match.url}/reject`}
            data-testid="rejectRefund"
            className="refund--button white"
          >
            Reject Refund
          </Link>
        )}
      </div>
    </section>
  );
};

Refund.propTypes = {
  county: PropTypes.any,
  error: PropTypes.any,
  complete: PropTypes.any,
  reject: PropTypes.any,
  status: PropTypes.string,
  match: PropTypes.any,
  refund: PropTypes.any,
};

const MemoizedRefund = React.memo(Refund);

export { MemoizedRefund as Refund };
