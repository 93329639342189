import PropTypes from 'prop-types'
import { e, SVG, BASE } from '../utils'
import Rotate from './Rotate'

const HistoryIcon = ({ className }) => {
  const handLength = 155
  const littleHandLength = handLength * (1 / 2)
  const thickness = handLength * 0.25

  return e(SVG, { className }, [
    e(Rotate, { key: 'Rotate' }),
    e('rect', {
      key: 'little hand',
      x: BASE / 2 - littleHandLength,
      y: BASE / 2,
      width: littleHandLength,
      height: thickness
    }),
    e('rect', {
      key: 'big hand',
      x: BASE / 2,
      y: BASE / 2 - handLength + thickness,
      width: thickness,
      height: handLength
    })
  ])
}

HistoryIcon.displayName = 'History'

HistoryIcon.propTypes = {
  className: PropTypes.string
}

export default HistoryIcon
