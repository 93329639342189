import { isNil, isEmpty, anyPass, complement, prop, path } from "ramda";
import { css } from "emotion";
import React, { useEffect } from "react";
import connect from "./connect";
import { Search } from "../../../components/Report/components";

const searchDescription = "Generate a report of successfully imported RsIds.";

const style = {
  searchLabel: css`
    .search-label--description {
      font-size: 1.5em;
      padding: 10px;
    }
  `,
  reportStatus: css`
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
    line-height: 1.5;
    background-color: #fff;
    padding: 30px 40px;
    border: 1px solid #ccc;
    max-width: 500px;
    border-radius 4px;
    margin: 50px auto 0;

    .blurb {
      padding-bottom: 10px;
    }

    .message {
      font-weight: 600;
      padding: 10px 0 20px 0;
    }

    a {
      background-color: #fff;
      border-radius: 4px;
      padding: 10px 20px;
      border: 1px solid #006cd0;
      color: #006cd0;
      margin-top: 20px;
      width: 231px
    }
  `,
};
const isAbsent = anyPass([isNil, isEmpty]);
const isPresent = complement(isAbsent);

export const ImportStatus = (props) => {
  const { fetch, initiate, data, hasInitiated, isLoading } = props;

  const requestReport = (e) => {
    e.preventDefault();
    initiate();
  };

  useEffect(() => {
    if (isAbsent(props.data)) fetch();
  }, []);

  useEffect(() => {
    if (hasInitiated) {
      fetch();
    }
  }, [hasInitiated]);

  const isInData = (pathArray) => isPresent(path(pathArray, data));
  const getReportData = (key) => path(["report", key], data);

  const getButtonText = () => {
    if (isAbsent(data)) return "Run Report";
    return prop("generating", data) ? "Generating..." : "Run Report";
  };

  const getSearchTitle = () => {
    if (isAbsent(data)) return "Getting most recent report...";

    return "RsId Import Status";
  };

  return (
    <>
      <Search
        className={style.searchLabel}
        onSubmit={requestReport}
        searchTitle={getSearchTitle()}
        searchDescription={searchDescription}
        isLoading={isPresent(data) && isLoading}
        showDateSearch={false}
        disabled={isAbsent(data) || prop("generating", data)}
        buttonText={getButtonText()}
      />
      {!isLoading && (
        <div className={style.reportStatus}>
          <p className="blurb">
            Reports may take over an hour to generate.{" "}
            {prop("generating", data) &&
              "Please reload this page to check to see if the report has finished generating."}
          </p>
          {isInData(["message"]) && prop("generating", data) && (
            <p className="message">{prop("message", data)}</p>
          )}
          {isInData(["report", "url"]) && (
            <>
              <p>
                Most recent report was created by {getReportData("createdBy")}{" "}
                on {getReportData("createdAt")}.
              </p>
              <a href={getReportData("url")}>Download Most Recent Report</a>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default connect(ImportStatus);
