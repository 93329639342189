import React from "react";
import PropTypes from "prop-types";
import * as rc from "recompose";
import * as Components from "../../../components";
import Pagination from "@kofile/react-components-pagination";
import { css } from "emotion";
import Results from "./Results";
import Initial from "./Initial";
import SearchByUser from "./SearchByUser";
import SearchByOrder from "./SearchByOrder";

const Action = props => (
  <div className="flex-container">
    <SearchByUser handleSelect={props.onFindByUser} userName={props.userName} />
    <span className="divider">OR</span>
    <SearchByOrder onFindByOrder={props.onFindByOrder} />
  </div>
);

Action.propTypes = {
  onFindByUser: PropTypes.func,
  onFindByOrder: PropTypes.func,
  userName: PropTypes.string
};

export default rc.setDisplayName("Dashboard.Search.View")(props => (
  <div className={props.className}>
    <Components.Widget
      title="Order History"
      action={<Action {...props} />}
      status={getStatus(props)}
      className={css`
        margin-bottom: 10px;
      `}
    >
      {props.screen.loading && (
        <div className="order-history__spinner-wrap">
          <Components.Spinner className="order-history__spinner" />
        </div>
      )}
      {props.screen.noResults && <Components.Widget.Empty />}
      {props.screen.results && (
        <Results
          className="order-history-table"
          records={props.records}
          goToReceipt={props.goToReceipt}
          sort={props.sort}
          currentSortKey={props.currentSortKey}
        />
      )}
      {props.screen.initial && <Initial />}
    </Components.Widget>
    {props.screen.results && (
      <Pagination
        currentPage={props.pagination.page}
        totalPages={props.pagination.totalPages}
        handlePageChange={props.goToPage}
        className={css`
          justify-content: flex-end;
        `}
      />
    )}
  </div>
));

function getStatus(props) {
  if (props.hasError) return Components.Widget.status.ERROR;
  if (props.noResults) return Components.Widget.status.EMPTY;

  return Components.Widget.status.NORMAL;
}
