import { createAction } from "redux-actions";
import { makeActionTypes } from "../../utils";
import { types as syncTypes } from "@kofile/ko-search-action-types";

export const types = makeActionTypes("ORDER", [
  "TOGGLE_ITEM_SELECTED",
  "CLEAR_SELECTED_ITEMS",
  "REQUEST_REFUND",
  "RESET"
]);

export const newApiTypes = {
  fetchOrder: "fetch-order-for-a-user",
  order:
    'I want to name this "order" but cannot because of the ncourt handler; ' +
    "https://github.com/kofile/ko-search-api/issues/1332",
  errorFetchingOrder: "error-fetching-order-for-a-user",
  pdfPath: "pdf-path",
  pdfPathError: "error-fetching-pdf-path",
  zipPath: "zip-path",
  zipPathError: "error-fetching-zip-path"
};

/**
 * ACTIONS
 */
export const actions = {
  fetch: createAction(newApiTypes.fetchOrder),
  fulfilled: createAction(newApiTypes.order),
  rejected: createAction(newApiTypes.errorFetchingOrder),
  toggleItemSelected: createAction(types.TOGGLE_ITEM_SELECTED),
  clearSelectedItems: createAction(types.CLEAR_SELECTED_ITEMS),
  requestRefund: createAction(types.REQUEST_REFUND),
  fetchRefundQuote: createAction(syncTypes.FETCH_REFUND_QUOTE_0),
  reset: createAction(types.RESET)
};

export default actions;
