import * as R from "ramda";
import { set } from "@kofile/redux-lenses";
import * as lenses from "../lenses";
import * as getters from "../getters";
import * as utils from "../../utils";
import { types as syncTypes } from "@kofile/ko-search-action-types";

const { users } = lenses;

export default {
  [syncTypes.FETCH_USERS_1]: [
    set(users.loading).as(true),
    set(users.searchId).with(R.path(["payload", "searchId"]))
  ],

  [syncTypes.FETCH_USERS_FULFILLED_1]: [
    set(users).using(({ payload }, state) => oldValue => {
      const searchId = getters.users.searchId(state);

      if (payload.searchId === searchId) {
        const data = payload.users.reduce(
          (memo, user) => ({ ...memo, [user.id]: user }),
          {}
        );

        return {
          ...oldValue,
          data,
          loading: false
        };
      }

      return oldValue;
    })
  ],

  [syncTypes.UPDATE_USER_INFO_FULFILLED_0]: [
    set(users).using(({ payload }, state) => () => {
      const userLens = R.lensPath(["users", "data", payload.id]);

      return R.set(userLens, payload, state);
    })
  ],

  [syncTypes.FETCH_USERS_REJECTED_0]: [
    set(users.error).with(utils.getReason),
    set(users.loading).as(false)
  ],

  [syncTypes.FETCH_USERS_CANCELLED_0]: [
    set(users.error).with(utils.getReason),
    set(users.loading).as(false)
  ],

  "users/cancel": [set(users.loading).as(false), set(users.searchId).as(null)],
  "users/loading": [set(users.loading).as(true)]
};
