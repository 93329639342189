import validate from "validate.js";
import * as R from "ramda";

const constraints = {
  email: {
    presence: true
  },
  password: {
    presence: true
  }
};

export default R.flip(validate)(constraints);
