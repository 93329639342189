import * as R from "ramda";

const root = R.lensProp("configuration");

const tenantConfiguration = (code) => R.compose(root, R.lensProp(code));

const flips = (code) =>
  R.compose(tenantConfiguration(code), R.lensProp("featureFlips"));

const loading = (code) =>
  R.compose(tenantConfiguration(code), R.lensProp("isLoading"));

const uiConfig = (code) =>
  R.compose(tenantConfiguration(code), R.lensProp("ui"));

const tenantConfig = (code) =>
  R.compose(tenantConfiguration(code), R.lensProp("tenant"));

const tenantMetadata = (code) =>
  R.compose(tenantConfiguration(code), R.lensProp("tenantMetadata"));

const tenantName = (code) => R.compose(tenantConfig(code), R.lensProp("name"));

const tenantOfficials = (code) =>
  R.compose(tenantConfig(code), R.lensProp("officials"));

const tenantLogoUrl = (code) =>
  R.compose(tenantMetadata(code), R.lensProp("tenantLogoUrl"));

const accountTypes = R.compose(root, R.lensProp("accountTypes"));

export default Object.assign(root, {
  tenantConfiguration,
  loading,
  uiConfig,
  tenantConfig,
  tenantMetadata,
  tenantName,
  tenantOfficials,
  tenantLogoUrl,
  accountTypes,
  flips,
});
