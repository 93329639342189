import * as R from "ramda";
import { fromObject } from "../../utils";
import lens from "../lenses/orders";

export default {
  root: R.view(lens),
  ids: R.view(lens.ids),
  data: R.view(lens.data),
  error: R.view(lens.error),
  loading: R.view(lens.loading),
  pagination: R.view(lens.pagination),
  userTerm: R.view(lens.userTerm),
  orderTerm: R.view(lens.orderTerm),
  inOrder: state => {
    if (!state) {
      throw new Error("must provide state!");
    }

    const ids = R.defaultTo([], R.view(lens.ids, state));
    const data = R.view(lens.data, state);

    return ids.map(fromObject(data));
  },
  sorted: state => {
    const sort = R.view(lens.sortBy, state);
    const data = R.view(lens.data, state);
    if (!sort) {
      return data;
    }

    const comp =
      sort.order === "asc"
        ? R.ascend(R.prop(sort.key))
        : R.descend(R.prop(sort.key));

    return R.sort(comp, data);
  },
  sortKey: state => {
    const sort = R.view(lens.sortBy, state);
    if (!sort) {
      return "";
    }

    return sort.key;
  }
};
