import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import * as utils from "../../../../utils";
import * as screenUtils from "../../../../utils/screen";
import { actions, getters } from "../../../../store";
import { connect } from "react-redux";
import View from "./View";

const lens = {
  timeout: R.lensProp("timeout"),
  screen: Object.assign(R.lensProp("screen"), {
    state: R.lensPath(["screen", "state"]),
    title: R.lensPath(["screen", "title"])
  }),
  hasError: R.lensProp("hasError"),
  errorMessage: R.lensProp("errorMessage")
};

const update = {
  timeout: { to: R.set(lens.timeout) },
  screen: {
    to: R.set(lens.screen),
    state: { to: R.set(lens.screen.state) },
    title: { to: R.set(lens.screen.title) }
  },
  hasError: { to: R.set(lens.hasError) },
  errorMessage: { to: R.set(lens.errorMessage) }
};

class Refunds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeout: null,
      shortTimedOut: false,
      longTimedOut: false,
      screen: screenUtils.screenFor(screenUtils.LOADING),
      errorMessage: null
    };

    this.fetch = () => {
      if (this.timer) this.timer.next();

      this.props.fetch();

      this.setState(
        R.compose(
          update.timeout.to(null),
          update.screen.to(screenUtils.screenFor(screenUtils.LOADING))
        )
      );

      this.timer = utils.timeout({
        SHORT: () => this.setState(update.timeout.to("SHORT")),
        LONG: () => this.setState(update.timeout.to("LONG")),
        BAIL: () =>
          this.setState(
            R.compose(
              update.timeout.to(null),
              update.screen.to(screenUtils.screenFor(screenUtils.ERROR)),
              update.errorMessage.to(
                "The request took too long to complete. Please try again later."
              )
            )
          )
      });
    };
  }

  componentWillReceiveProps(nextProps) {
    const getCountyId = R.pathOr(null, ["county", "id"]);
    const nextCountyId = getCountyId(nextProps);

    if (nextCountyId === null) {
      return;
    }

    if (getCountyId(this.props) !== nextCountyId) {
      this.fetch();
    } else {
      const screen = screenUtils.getScreen(nextProps.refunds);

      if (screen.state !== this.state.screen.state) {
        this.timer.next();

        this.setState(
          R.compose(
            update.timeout.to(null),
            update.screen.to(screen),
            update.hasError.to(screen.state === screenUtils.ERROR),
            update.errorMessage.to(nextProps.refunds.error)
          )
        );
      }
    }
  }

  componentDidMount() {
    this.fetch();
  }

  render() {
    const props = {
      className: this.props.className,
      title: this.state.screen.title,
      records: this.props.refunds.records,
      hasResults: this.state.screen.state === screenUtils.RESULTS,
      isLoading: this.state.screen.state === screenUtils.LOADING,
      noResults: this.state.screen.state === screenUtils.NO_RESULTS,
      hasError: this.state.screen.state === screenUtils.ERROR,
      errorMessage: this.state.errorMessage,
      timeout: this.state.timeout,
      countyId: utils.getCountyId(this.props)
    };

    return <View {...props} />;
  }
}

Refunds.propTypes = {
  refunds: PropTypes.shape({
    total: PropTypes.number,
    records: PropTypes.arrayOf(PropTypes.object.isRequired),
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string
  }).isRequired,
  className: PropTypes.string,
  fetch: PropTypes.func
};

const mapStateToProps = state => ({
  county: getters.county(state),
  refunds: {
    total: getters.refunds.total(state),
    records: getters.refunds.tableView(state),
    isLoading: getters.refunds.isLoading(state),
    error: getters.refunds.error(state)
  }
});

const mapDispatchToProps = dispatch => ({
  fetch: () =>
    dispatch(actions.refunds.fetch({ pagination: { limit: 20, offset: 0 } }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Refunds);
