import React from "react";
import {
  Widget,
  SearchReportsLabel,
  DateRange
} from "../../../../../components";
import { noop } from "../../../../../utils";

const searchTitle = "Search Metrics by User/Date";
const searchDescription =
  "Look up past user activity by entering a start date and end date.";

const handleRangePicked = noop;

const Search = () => (
  <Widget title={searchTitle} className="users-report--search">
    <div className="users-report--search-content">
      <SearchReportsLabel title={searchTitle} description={searchDescription} />
      <div className="users-report--search-date">
        <DateRange handleRangePicked={handleRangePicked} />
      </div>
    </div>
  </Widget>
);

Search.displayName = "Search";

export default Search;
