import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "../";

const Loading = ({ timeout }) => (
  <div className="loading">
    <div className="loading__spinner">
      <Spinner />
    </div>

    {timeout === "SHORT" && <p className="loading__message">Hang tight...</p>}

    {timeout === "LONG" && (
      <p className="loading__message">
        Hmm, this request seems to be taking longer than usual...
      </p>
    )}
  </div>
);

Loading.displayName = "Loading";
Loading.propTypes = {
  timeout: PropTypes.oneOf(["SHORT", "LONG"])
};

export default Loading;
