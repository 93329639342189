import React from 'react'

export const BASE = 500
export const RX = BASE * 0.1

export const e = React.createElement

export const toRadians = degrees => degrees * Math.PI / 180

export const SVG = props => {
  const defaults = {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: `0 0 ${BASE} ${BASE}`
  }
  const svgProps = Object.assign({}, defaults, props)

  return e('svg', svgProps)
}
