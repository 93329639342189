import Rx from "rxjs";
import randomCorrelationId from "uuid/v4";
import { isUniversal } from "@kofile/ko-search-action-types";
import * as actions from "../../actions";
import * as getters from "../../getters";
import { noop } from "../../../utils";

/**
 * Redux Middelware for connecting a WS socket and our Store
 *
 * @example
 *
 *
 *    socketMiddleware('ws://')(store)(next)(action)
 *
 *
 * @return {Function} - store -> next -> action -> void
 */

const includeList = [
  "fetch-a-refund",
  "fetch-billing-report",
  "fetch-order-for-a-user",
  "fetch-refund-activity-for-a-tenant",
  "fetch-import-report",
  "initiate-import-report",
];

const isIncluded = (action) =>
  includeList.includes(action.type) || isUniversal(action);

export const socketMiddleware =
  ({ socketUrl, onError = noop, onDone = noop }) =>
  (store) => {
    // Gain a reference to the `dispatch` value
    // so we can tell our Redux store about sockets
    const { dispatch } = store;

    // The actual web socket observable
    const openSubject = new Rx.Subject();
    const closeSubject = new Rx.Subject();
    const socketStream = Rx.Observable.webSocket({
      url: socketUrl,
      openObserver: openSubject,
      closeObserver: closeSubject,
    });

    // We do not hold a reference to the subscriptions
    // since we want this subscription to be there
    // for as long as our store is there

    socketStream
      .retryWhen((errors) =>
        Rx.Observable.range(1, 15)
          .zip(errors, (attempts) => attempts)
          .mergeMap((attempts) => {
            return Rx.Observable.timer(attempts * 1000);
          })
      )
      .merge(openSubject.mapTo(actions.socket.opened()))
      .merge(closeSubject.mapTo(actions.socket.retrying()))
      .subscribe(dispatch, onError, onDone);

    const pingInterval = 30000;

    Rx.Observable.interval(pingInterval)
      .delay(pingInterval)
      .subscribe(() => {
        socketStream.next("PING");
      });

    return (next) => (action) => {
      // We always want to tell the socket about
      // our action
      if (isIncluded(action)) {
        const state = store.getState();
        const jwt = getters.user.jwt(state);
        const countyConfig = getters.county(state) || {};
        const { id: tenantId = null } = countyConfig;

        socketStream.next(
          JSON.stringify(
            Object.assign({}, action, {
              sync: true,
              authToken: jwt,
              tenantId,
              correlationId: randomCorrelationId(),
            })
          )
        );
      }

      // and we want to tell our system about this action
      return next(action);
    };
  };

export default socketMiddleware;
